import React, { useEffect, useState } from "react";
import defaultPhoto from "../../../assets/img/project-default.jpg";
import AttachmentService from "../../services/AttachmentService";

const NotificationProjectAttachement = (props: any)  => {

const { data } = props;
const [photoUrl, setPhotoUrl] = useState();

useEffect(() => {
    if (data.photoFile) {
     getPhoto(data).then((response: any) =>
     setPhotoUrl(response),
     );
    }
}, [data]);
const getPhoto = async (data: any) => {
  const response = await AttachmentService.getAttachment(data.contract.id, data.id, data.photoFile.id);
  const windowUrl = window.URL;
  return windowUrl.createObjectURL(new Blob([response.data]));
};

return (
  <div className="project-image p-2">
    {data.photoFile ? (
      <img
        src={photoUrl}
        className="detail-photo"
        alt={data.photoFile.originalFileName}
        title={data.photoFile.originalFileName}
      />
    ) : (
      <img src={defaultPhoto} className="detail-photo" alt="default" title="default" />
    )}
  </div>
);
};

export default NotificationProjectAttachement;
