import { Constants } from "../constants";
export default {
  setPotentialProjectsList(payload: any) {
    return {
      payload,
      type: Constants.SET_LIST_POTENTIAL_PROJECTS,
    };
  }, setDataSourcesList(payload: any) {
    return {
      payload,
      type: Constants.SET_LIST_DATA_SOURCES,
    };
  },
};
