import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getContractId} from "../../main/utils";
import MapProjectsManager from "../../map/manager/MapProjectsManager";
import { mapAction } from "../../map/store/actions";
import ProjectHeader from "../components/project/ProjectHeader";
import ProjectForm from "../forms/ProjectForm";
import ProjectService from "../services/ProjectService";
import {projectAction, projectReferentialAction} from "../store/actions/index";
import "../style/index.scss";
import AbstractProjectManager from "./AbstractProjectManager";
import ListProjectManager from "./ListProjectManager";

class EditProjectManager extends AbstractProjectManager {

    public componentDidMount(): void {
        this.init();
    }

    public componentDidUpdate(prevProps: any): void {
        super.componentDidUpdate(prevProps);
        if ((!this.state.contractId) || prevProps.idProject !== this.props.idProject) {
            this.init();
        }
    }

    public render(): any {
        const {t, projectReferential, setProjectsMap, setProjectsList, detailProject} = this.props;
        return (
            <>
                { detailProject && (
                    <>
                        <div className="project-form p-0 m-0  col-4">
                            <ProjectHeader cancelUrl = {`/view/project/${detailProject.id}`} title={t("forms:project.edit_project_title")}/>
                            <ProjectForm
                                pinProjectLocation={this.state.pinProjectLocation}
                                setDuplicatedProjects = {this.setDuplicatedProjects.bind(this)}
                                duplicatedProjects = {this.props.duplicatedProjects}
                                contractId={this.state.contractId}
                                projectReferential={projectReferential}
                                setProjectsMap={setProjectsMap}
                                setProjectsList={setProjectsList}
                                detailProject={this.detailProjectToForm()}
                                synchroPin={this.state.synchroPin}
                                changeSynchroPin={this.changeSynchroPin.bind(this)}
                                showPin={this.state.showPin}
                                changeShowPin={this.changeShowPin.bind(this)}
                                setMapCenterHandle={this.setMapCenterHandle.bind(this)}
                            ></ProjectForm>

                        </div>
                        {this.mapListRender()}
                    </>
                )}
            </>
        );
    }

    public mapListRender() {
        const { history, displayMap, detailProject} = this.props;

        return (<div className="col-8 no-padding map">
            {displayMap ? <MapProjectsManager
                setIsAutoCompleteAction={this.setIsAutoCompleteAction.bind(this)}
                isAutoCompleteAction={this.state.isAutoCompleteAction}
                eventPinHandle = {this.eventPinHandle.bind(this)}
                showPin={this.state.showPin}
                synchroPin={this.state.synchroPin}
                isEditEnabled={true}
            ></MapProjectsManager> : <ListProjectManager currentProjectId={detailProject.id} history={history} />}
        </div>);
    }

    private init() {
        const { idProject, user, setProject } = this.props;
        const contractId = getContractId(user);
        if (contractId) {

            ProjectService.getProject(contractId, idProject).then((response) => {
                const project = response.data;
                this.setDuplicatedProjects(contractId, project.latitude, project.longitude, idProject);

                setProject({...project, photoUrl: ""});
                const center = (project && project.latitude && project.longitude) ? [project.latitude, project.longitude] : null;
                if (center) {
                    this.props.setMapInfo({center, zoom: 19, isAutoZoomActivated: false});
                }
            });
        }
    }

    private detailProjectToForm() {
        const {detailProject, t} = this.props;
        const {
          name,
          address,
          city,
          street,
          latitude,
          longitude,
          url,
          hold,
          pcNumber,
          deliveryDate,
          pcDate,
          pcDeposit,
          stoppedFile,
          authorizationFile,
          photoFile,
          otherAttachments,
          id,
          mainContact,
          contacts,
          dateSetUpPermitZac,
        } = detailProject;

        const defaultValues = {
          name,
          address,
          city,
          street,
          number: detailProject.number || "",
          latitude,
          longitude,
          url: url || "",
          pcDate,
          pcDeposit,
          deliveryDate,
          hold,
          pcNumber: pcNumber || "",
          stoppedFile,
          authorizationFile,
          photoFile,
          otherAttachments,
          id,
          dateSetUpPermitZac,
          isZac: detailProject.zac ? true : false,
          zac: detailProject.zac ? detailProject.zac : "",
          comment: detailProject.comment ? detailProject.comment : "",
        };
        const status = {value: detailProject.status.id, label: t(`forms:project.${detailProject.status.code}`)};
        const type = {value: detailProject.type.id, label: t(`forms:project.${detailProject.type.code}`)};
        const progress = detailProject.progress ? {
            value: detailProject.progress.id,
            label: t(`forms:project.${detailProject.progress.code}`),
        } : "";

        return { ...defaultValues, status, type, progress, mainContact, contacts };
    }
}

const mapDispatchToProps = {
  setProject: projectAction.setProject,
  setProjectTypes: projectReferentialAction.setProjectTypes,
  setProjectStatus: projectReferentialAction.setProjectStatus,
  setProjectProgress: projectReferentialAction.setProjectProgress,
  setContactTypes: projectReferentialAction.setContactTypes,
  setProjectsMap: projectAction.setProjectsMap,
  setProjectsList: projectAction.setProjectsList,
  setDuplicatedProjects: projectAction.duplicatedProjects,
  setMapInfo: mapAction.setMapInfo,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        displayMap: state.project.displayMap,
        detailProject: state.project.detailProject,
        projectReferential: state.projectReferential,
        duplicatedProjects: state.project.duplicatedProjects,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(EditProjectManager));
