import classnames from "classnames";
import React from "react";
import { useDropzone } from "react-dropzone";

export const SzDropZone = (props: any) => {
    const { placeHolder, multiple, dropHandle, className, defaultFiles = [], accept = [], deleteHandle } = props;
    const onDrop = (files: any) => {
        dropHandle(files);
    };
    const { getRootProps, getInputProps } = useDropzone({ multiple, onDrop, accept: accept.toString(",") });
    const deleteFileHandle = (file: any) => {
          deleteHandle(file);
    };

    const filesToShow = defaultFiles;
    const files = filesToShow.map((file: any, index: number) => {
        const delLink =  <span className="delete-file" onClick={() => deleteFileHandle(file)}> X</span>;
        return (<li key={index}>
            {file.originalFileName} {delLink}
        </li >);
    });

    const classNames = classnames("dropzone", className);
    return (
      <>
        <div {...getRootProps({ className: classNames })}>
          <input {...getInputProps()} />
          <p>{placeHolder}</p>
        </div>
        {files && <ul className=" file-list">{files}</ul>}
      </>
    );
};
