import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { SzButton, SzIcon } from "@suezenv/react-theme-components";
import DataSourcesListManager from "../../../projectManagement/manager/Robot/DataSourcesListManager";
import SourcesListManager from "../../../projectManagement/manager/Robot/SourcesListManager";
import CreateDataSourceSideLayer from "../../components/CreateDataSourceSideLayer";
import PotentialProjectDetailsSideLayer from "../../components/PotentialProjectDetailsSideLayer";
import PotentialProjectSideLayer from "../../components/PotentialProjectSideLayer";
import { potentialProjectAction } from "../../store/actions";
import "./robot.scss";
import robotServiceInstance from "../../../projectManagement/services/RobotService";
import { potentialProjectStatus } from "../../../projectManagement/store/constants";
import {getContractId} from "../../utils";

interface ISortDataInterface {
    field: string,
    direction: string
}
interface IRobot {
    user: any,
    setPotentialProjectsList: (data: any) => void,
}
const Robot = (props: IRobot) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [isActionHidden, setIsActionHidden] = useState(true);
    const [isSetting, setIsSetting] = useState(false);
    const [isCreateDataSource, setIsCreateDataSource] = useState(false);
    const [isDetailsHidden, setIsDetailsHidden] = useState(true);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [potentialProjectSort, setPotentialProjectSort] = useState<ISortDataInterface>({
        field: "url",
        direction: "asc"
    });
    const rowClick = (data: any) => {
        setSelectedRowData(data);
        setIsDetailsHidden(false);
        setIsActionHidden(true);
    };
    const setAlreadyExistProject = (projectId: string) => {
        const contractId: string = getContractId(props.user);
        return robotServiceInstance.updateProjectStatus(contractId, projectId, potentialProjectStatus.alreadyExist)
    }
    const rejectProject = (projectId: string) => {
        const contractId: string = getContractId(props.user);
        return robotServiceInstance.updateProjectStatus(contractId, projectId, potentialProjectStatus.rejected)
    }
    const getData = () => {
        const contractId: string = getContractId(props.user);
        if (contractId) {
            const sortField = potentialProjectSort.field === "url" ? "dataSourceLabel" : potentialProjectSort.field;
            robotServiceInstance.getPotentailProjects(contractId, sortField, potentialProjectSort.direction).then((data: any) => {
                props.setPotentialProjectsList(data);
            });
        }
    }
    return (
        <div className="robot-container row">
            {
                !isSetting ? <div className={"details " + (isDetailsHidden ? "no-padding hidden" : "no-padding col-6 h-100")}>
                    <PotentialProjectDetailsSideLayer
                        closeSideLayer={() => {
                            setIsDetailsHidden(true);
                        }}
                        data={selectedRowData}
                        getPotentialProjectList={getData}
                        rejectProject={rejectProject}
                        setAlreadyExistProject={setAlreadyExistProject}
                    />
                </div> :
                    <div className={"details " + (!isCreateDataSource ? "no-padding hidden" : "no-padding col-4 h-100")} >
                        < CreateDataSourceSideLayer cancel={() => setIsCreateDataSource(false)} ></CreateDataSourceSideLayer>
                    </div>
            }
            <div className={`potential-project-container w-100 overflow-auto no-padding my-3 ${isActionHidden ? `w-98` : `col-9`}`}>
                {
                    isSetting
                        ?
                        <>
                            <div className="row">
                                <div className="col-auto">
                                    <SzButton className="back-btn border-0" variant="secondary" onClick={() => setIsSetting(false)}>
                                        <SzIcon icon="arrow-left-1" variant="bold"></SzIcon>
                                        <span>{t("setting.go-back")}</span>
                                    </SzButton>
                                </div>
                            </div>
                            <DataSourcesListManager history={history} onRowClick={null}></DataSourcesListManager>
                        </>
                        :
                        <SourcesListManager
                            history={history}
                            onRowClick={rowClick}
                            setSorData={setPotentialProjectSort}
                            sortData={potentialProjectSort}
                            getPotentialProjectList={getData} />
                }
            </div>
            {
                isActionHidden && <div className="cursor-pointer open-pane no-padding" onClick={() => {
                    setIsActionHidden(false);
                    setIsDetailsHidden(true);
                }}>
                    <div>{"<"}</div>
                </div>
            }
            <div className={isActionHidden ? "no-padding hidden" : "no-padding col-3 "}>
                <span onClick={() => setIsActionHidden(true)} className="close-pane cursor-pointer">&gt;</span>
                <PotentialProjectSideLayer
                    isSetting={isSetting}
                    goSetting={() => setIsSetting(true)}
                    createDataSource={() => setIsCreateDataSource(true)} />
            </div>
        </div >
    );
};
const mapDispatchToProps = {
    setPotentialProjectsList: potentialProjectAction.setPotentialProjectsList,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Robot));
