import { Constants } from "../constants";

const initialState: any = {
  types: [],
  status: [],
  progress: [],
  contactTypes: [],
};

const projectReferentialReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.SET_PROJECT_TYPE:
      return { ...state, types: action.payload };
    case Constants.SET_PROJECT_STATUS:
      return { ...state, status: action.payload };
    case Constants.SET_PROJECT_PROGRESS:
      return { ...state, progress: action.payload };
    case Constants.SET_CONTACT_TYPES:
      return { ...state, contactTypes: action.payload };
    default:
      return state;
  }
};

export default projectReferentialReducer;
