// action strings
export enum Constants {
  LOG_IN = "APP_LOGIN",
  LOG_OUT = "APP_LOGOUT",
  SET_USER_INFO = "SET_USER_INFO",
  SET_LOADING= "SET_LOADING",
}

export enum ERROR {
  SET = "ERROR.SET",
  RESET = "ERROR.RESET",
}
