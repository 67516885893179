import {SzBox} from "@suezenv/react-theme-components";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getContractId} from "../../main/utils";
import {servicesCodes} from "../../user/store/constants";
import ContactForm from "../forms/ContactForm";
import ReferentialService from "../services/ReferentialService";
import {projectReferentialAction} from "../store/actions";

class CreateContactManager extends React.Component<any> {

    public setReferential() {
        const contractId = getContractId(this.props.user);
        this.setState({contractId});
        // If the contract does not have service project management
        if ("" === contractId) {
            return this.props.history.push("/");
        }

        this.initProjectContactTypes(contractId);
    }

    public componentDidUpdate(prevProps: any): void {
        const {user} = this.props;

        const contractId = getContractId(user);
        if (!contractId) {
            this.setReferential();
        }
    }

    public componentDidMount(): void {
        if (this.props.user.services[servicesCodes.projectManagement] && this.props.user.services[servicesCodes.projectManagement].length) {
            this.setReferential();
        }
    }

    public initProjectContactTypes(contractId: string) {
        if (this.props.projectReferential.contactTypes.length === 0) {
            ReferentialService.getContactTypes(contractId).then((data) => {
                this.props.setContactTypes(data);
            });
        }
    }

    public render(): any {
        const {t, projectReferential, user} = this.props;
        const contractId = getContractId(user);

        return (
            <>
                {contractId && (
                    <>
                        <div className="m-2 bg-white w-100 new-contact-title">
                            <div
                                className="sz-card-collapse__header sz-left-border position-relative align-items-center m-0 row">
                                <div className="w-100 col-md-6 col-sm-12">
                                    <SzBox className="justify-content-around pb-0">
                                        <label className="mb-0 font-weight-bold">{t("contact.new_contact_title")}</label>
                                    </SzBox>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form p-3 w-100 bg-white justify-center">
                            <ContactForm
                                contractId={contractId}
                                contactTypes={projectReferential.contactTypes}
                                saveAction={(payload: any) => {}}
                                redirect={true}
                            />
                        </div>

                    </>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    setContactTypes: projectReferentialAction.setContactTypes,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        projectReferential: state.projectReferential,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(CreateContactManager));
