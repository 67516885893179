
import React from "react";
import {useHistory} from "react-router";
import "./index.scss";
import EditContactManager from "../../../projectManagement/manager/EditContactManager";

const EditContact = (props:any) => {
    const history = useHistory();
    const { id } = props.match.params;
    return (
        <div className="contact-container w-100 p-3">
            <EditContactManager idContact={id} history={history}></EditContactManager>
        </div>
    );
};

export default EditContact;
