import { Constants } from "../constants";

const contractConfiguration: any = {
};

const contractConfigurationReducer = (state = contractConfiguration, action: any): any => {
  switch (action.type) {
    case Constants.SET_CONTRACT_CONFIGURATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default contractConfigurationReducer;
