import React from "react";

interface IMapComponent {
  zacAutoComplete: any;
  zacAutoCompleteClickHandle: (
    name: string,
    area: string,
    id: string,
    contact: any,
  ) => void;
}
const ZacAutoCompleteItemsComponent = (props: IMapComponent) => {
  const { zacAutoComplete, zacAutoCompleteClickHandle } = props;
  return (
    <>
      <div className="position-absolute list-group overflow-auto address-auto-complete">
        {zacAutoComplete.items.map((item: any, index: any) => {
          const { name, area, id, contact } = item;
          return name ? (
            <button
              key={index}
              type="button"
              className="list-group-item list-group-item-action"
              onClick={() => {
                zacAutoCompleteClickHandle(id, name, area, contact);
              }}
            >
              {name}
            </button>
          ) : null;
        })}
      </div>
    </>
  );
};

export default ZacAutoCompleteItemsComponent;
