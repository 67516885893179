import { SzButton, SzIcon, SzModal, SzTable, SzTypographie } from "@suezenv/react-theme-components";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CreateProjectFromPotentialManager from "../../projectManagement/manager/Robot/CreateProjectFromPotentialManager";
import "../styles/sideLayer.scss";
import IFrame from "./Utils/IFrame/IFrame";

interface IPotentialProjectDetailsSideLayer {
    closeSideLayer: () => void;
    data: any;
    getPotentialProjectList: () => void;
    rejectProject: (projectId: string) => Promise<AxiosResponse>;
    setAlreadyExistProject: (projectId: string) => Promise<AxiosResponse>;
}

const PotentialProjectDetailsSideLayer = (props: IPotentialProjectDetailsSideLayer) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isCreateProject, setIsCreateProject] = useState(false);
    const [showRejectProject, setShowRejectProject] = useState(false);
    const [showAlreadyExistProject, setShowAlreadyExistProject] = useState(false);
    const columns = [
        {
            classes: "tag",
            dataField: "tag",
            text: t("potentialProject.details.table.tags"),
        }, {
            classes: "keyword",
            dataField: "keyword",
            text: t("potentialProject.details.table.keywords"),
            formatter: multiValueFormatter,
        },
    ];
    const formatData = (data: any) => {
        if (data.entities === undefined || data.entities === null) {
            return [];
        }
        const tags = data.entities.map((entity: any) => {
            return entity.label;
        }).filter((value: string, index: number, self: any) => {
            return self.indexOf(value) === index;
        });
        const formattedData: any[] = [];
        tags.map((tag: string) => {
            formattedData.push({
                tag,
                keyword: data.entities.filter((entity: any) => {
                    return entity.label === tag;
                }).map((entity: any) => {
                    return { text: entity.text, transformedData: entity.transformData };
                }),
            });
            return false;
        });
        return formattedData;
    };

    const rejectProject = () => {
        setShowRejectProject(false);
        props.rejectProject(props.data.projectId).then(() => {
            props.closeSideLayer();
            props.getPotentialProjectList();
        });
    };

    const alreadyExistProject = () => {
        setShowAlreadyExistProject(false);
        props.setAlreadyExistProject(props.data.projectId).then(() => {
            props.closeSideLayer();
            props.getPotentialProjectList();
        });
    };

    const sideMenuHeader = (title: string) => {
        return (<div className="row border-bottom">
            <div className="col">
                <h6 className="list-state-title">{title}</h6>
            </div>
            {isCreateProject && <div className="col-auto pr-0 ">
                <div className="mt-2 p-2 pr-3  h-100 cursor-pointer"
                    onClick={() => {
                        setIsCreateProject(false);
                    }}>
                    <SzIcon icon="arrow-left" variant="bold" />
                </div>
            </div>}
            <div className="col-auto pr-0 ">
                <div className="mt-2 p-2 pr-3  h-100 cursor-pointer"
                    onClick={() => {
                        props.closeSideLayer();
                        setIsCreateProject(false);
                    }}>
                    <SzIcon variant="line" icon="close" />
                </div>
            </div>
        </div>);
    };
    return (
        <>
            <SzModal
                title={t("forms:project.confirmation_title")}
                show={showRejectProject}
                handleClose={() => setShowRejectProject(false)}
                size={"lg"}
            >
                <SzTypographie className="text-center">{t("forms:project.confirmation_text")}</SzTypographie>
                <div className="text-center form-actions w-100">
                        <SzButton variant="secondary"  className="mr-3" onClick={() => setShowRejectProject(false)}>
                            <span>{t("forms:project.no")}</span>
                        </SzButton>
                        <SzButton className=""
                            onClick={() => rejectProject()}>
                            <span>{t("forms:project.yes")}</span>
                        </SzButton>
                </div>
            </SzModal>

            <SzModal
                title={t("forms:project.confirmation_title_exist")}
                show={showAlreadyExistProject}
                handleClose={() => setShowAlreadyExistProject(false)}
                size={"lg"}
            >
                <SzTypographie className="text-center">{t("forms:project.confirmation_text_exist")}</SzTypographie>
                <div className="text-center form-actions w-100 ">
                        <SzButton variant="secondary" className="mr-3"  onClick={() => setShowAlreadyExistProject(false)}>
                            <span>{t("forms:project.no")}</span>
                        </SzButton>
                        <SzButton className=""
                                  onClick={() => alreadyExistProject()}>
                            <span>{t("forms:project.yes")}</span>
                        </SzButton>
                    </div>
            </SzModal>

            <div className="potential-project-side-layer row">
                <div className="h-100 col-12 form-actions side-layer-form">
                    {!isCreateProject ?
                        <>
                            {sideMenuHeader(props.data.name)}
                            <div className="row justify-content-end">
                                <div className="col-2">
                                    <span className="text-white font-weight-bold mt-2 circle">
                                        {props.data.probability}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-5 mx-2 h-50 w-100">
                                <IFrame link={props.data.url} width="100%" heigth="100%"></IFrame>
                            </div>
                            <div className="row mt-3 mx-2">
                                <div className="col-auto pl-0">
                                    <SzTypographie className="m-auto"
                                        weight="bold">{t("potentialProject.details.link")}</SzTypographie>
                                </div>
                                <div className="col-auto pl-0">
                                    <a className="mt-2 p-2 pr-3  h-100 cursor-pointer" href={props.data.url}
                                        target="_blank"  rel="noopener noreferrer">
                                        {props.data.url}
                                    </a>
                                </div>
                            </div>
                            <div className="mt-3 mx-2">
                                <SzTypographie weight="bold">{t("potentialProject.details.table.title")}</SzTypographie>
                                {(props.data.entities && props.data.entities.length > 0) ? < SzTable
                                    columns={columns}
                                    data={formatData(props.data)}
                                    keyField="id"
                                /> : <div>
                                        <SzTypographie align="center"
                                            weight="light">{t("potentialProject.details.table.empty")}</SzTypographie>
                                    </div>
                                }
                            </div>
                            <div className="row mt-3 mx-2 mb-5">
                                <div className="col">
                                    <SzButton variant="secondary" onClick={() => setShowRejectProject(true)}>
                                        <span>{t("potentialProject.details.buttons.not_project")}</span>
                                    </SzButton>
                                </div>
                                <div className="col">
                                    <SzButton variant="secondary" onClick={() => setShowAlreadyExistProject(true)}>
                                        <span>{t("potentialProject.details.buttons.already_exist_project")}</span>
                                    </SzButton>
                                </div>
                                <div className="col">
                                    <SzButton className="" onClick={() => {
                                        setIsCreateProject(true);
                                    }}>
                                        <span>{t("potentialProject.details.buttons.project")}</span>
                                    </SzButton>
                                </div>

                            </div>
                        </>
                        :
                        <div>
                            {sideMenuHeader(t("potentialProject.details.create-project.title"))}
                            <div className="row my-2">
                                <div className="col">
                                    <label
                                        className="text-primary">{t("potentialProject.details.create-project.sub-title")}</label>
                                </div>
                            </div>
                            <CreateProjectFromPotentialManager
                                potentialProjectId={props.data.projectId}
                                extractedLabel={formatData(props.data)}
                                url={props.data.url}
                                history={history}
                                cancel={() => {
                                    props.closeSideLayer();
                                    setIsCreateProject(false);
                                }}>
                            </CreateProjectFromPotentialManager>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

const multiValueFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
    return (<div>
        {cell && Object.values(cell)
            .map((keyword: any) => (
                <p className="m-0">{keyword.text}</p>
            ))}
    </div>);
};

export default PotentialProjectDetailsSideLayer;
