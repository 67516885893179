import { Constants } from "../constants";
export default {
  setZacList(payload: any) {
    return {
      payload,
      type: Constants.SET_ZAC_LIST,
    };
  },
  setZacDetail(payload: any) {
    return {
      payload,
      type: Constants.SET_ZAC_DETAIL,
    };
  },
};
