import cube from "../assets/img/icons/3D.svg";
import doc from "../assets/img/icons/doc.svg";
import pdf from "../assets/img/icons/pdf.svg";
import target from "../assets/img/icons/target.svg";
import ThemeService from "../main/services/ThemeService";

declare global {
    interface Window {
      REACT_APP_API_HOST: string;
      REACT_APP_ANALYTICS_KEY: string;
      REACT_APP_FRESHDESK: number;
      REACT_APP_MAP_PORTAL_URL: string;
      REACT_APP_MAP_SERVICE_URL: string;
      REACT_APP_MAP_WEBMAP_ID: string;
      REACT_APP_MAP_WEBSCENE_ID: string;
      REACT_APP_SDS_THEME: any;
      REACT_APP_CENTER_LAT: number;
      REACT_APP_CENTER_LNG: number;
      REACT_APP_CLIENT: string;
      fwSettings: {
        widget_id: number;
      };
      REACT_APP_USER_ADMIN: string;
      REACT_APP_USER_MANUAL: string;
    }
}

export const routes = {
    errorConnection: '/error/connection',
    home: '/',
    newProject: '/new/project',
    viewProject: '/view/project/:id',
    editProject:'/edit/project/:id',
    mergeProject:'/merge/project/:id/:id2',
    notification: '/notification',
    robot: '/robot',
    contact: '/actor',
    zac: '/zac',
    newContact: '/new/actor',
    editContact: '/edit/actor/:id',
    newZac: '/new/zac',
    editZac: '/edit/zac/:id',
};

// use process.env in dev instead window
export const config = {
    analyticsKey: window.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY,
    apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
    freshdeskId: Number(window.REACT_APP_FRESHDESK || process.env.REACT_APP_FRESHDESK),
    host: window.location.origin,
    client: window.REACT_APP_CLIENT || process.env.REACT_APP_CLIENT || 'Argus',
    userAdminUrl:  window.REACT_APP_USER_ADMIN || process.env.REACT_APP_USER_ADMIN,
    userManualUrl:  window.REACT_APP_USER_MANUAL || process.env.REACT_APP_USER_MANUAL
};

export const mapConfig = {
    PORTAL_URL: window.REACT_APP_MAP_PORTAL_URL,
    SERVICE_URL: window.REACT_APP_MAP_SERVICE_URL,
    WEBMAP_ID: window.REACT_APP_MAP_WEBMAP_ID, // 2d
    WEBSCENE_ID: window.REACT_APP_MAP_WEBSCENE_ID,
    CENTER_LAT: window.REACT_APP_CENTER_LAT || process.env.REACT_APP_CENTER_LAT || "",
    CENTER_LNG: window.REACT_APP_CENTER_LNG || process.env.REACT_APP_CENTER_LNG || "",
    // If api theme return null  or does not exist get value from env var)
    SDS_THEME: ThemeService.getTheme() || window.REACT_APP_SDS_THEME || process.env.REACT_APP_SDS_THEME || "SEVE", // 3d
};

export const icons: any = {
    "icon-3D": cube,
    "icon-doc": doc,
    "icon-pdf": pdf,
    "icon-target": target,
};
