import {
  SzAlert,
  SzBox,
  SzButton,
  SzDatePicker,
  SzIcon,
  SzInput,
  SzModal,
  SzSelect,
  SzTextArea,
  SzTypographie,
} from "@suezenv/react-theme-components";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import synchroIcon from "../../../assets/img/icons/synchro.svg";
import AddressAutoCompleteItemsComponent from "../../../main/components/Utils/addressAutoCompleteItemsComponent";
import { SzDropZone } from "../../../main/components/Utils/SzDropZone";
import { checkIsDateValid, contactLabel, getContractId, stringToDate, zacLabel } from "../../../main/utils";
import AttachmentService from "../../services/AttachmentService";
import { MapGeoCodeService } from "../../services/MapGeoCodeService";
import ProjectService from "../../services/ProjectService";
import robotService from "../../services/RobotService";
import { autocompleteAction, potentialProjectAction, projectAction } from "../../store/actions";
import { acceptExtDropZone, acceptExtPhoto, LABELS, potentialProjectStatus } from "../../store/constants";
import ContactForm from "../ContactForm";
import { getFormatedForm } from "../models";
import ProjectModel from "../models/ProjectModel";
import ZacForm from "../ZacForm";
import EditProjectContacts from "./EditProjectContacts";
import EditProjectZac from "./EditProjectZac";
import "./projectForm.scss";
import { IAttachment, IContact, IProjectForm, IStateProjectForm, IZac } from "./types";
import { RouterPrompt } from "../../../main/components/RouterPrompt";

const ProjectForm = (props: IProjectForm) => {
  const NBR_CHAR_START_AUTO_COMPLETE = 3;
  const isMerge = props.projectToMerge1 !== undefined && props.projectToMerge2 !== undefined;

  let initialState: IStateProjectForm = {
    name: "",
    status: "",
    type: "",
    address: "",
    city: "",
    street: "",
    number: "",
    latitude: "",
    longitude: "",
    mainContact: null,
    contacts: [],
    hold: 0,
    zac: {},
    url: "",
    deliveryDate: "",
    pcDate: "",
    pcNumber: "",
    pcDeposit: "",
    isValid: false,
    otherAttachments: [],
    showAddressAutoCompleteItems: true,
    addressAutoComplete: { items: [] },
    photoFile: null,
    stoppedFile: null,
    showAddContact: false,
    errors: false,
    attachmentsToDelete: [],
    fileErrors: { photoFile: false, stoppedFile: false, otherAttachments: false },
    editProjectContacts: false,
    addContacts: false,
    refreshListContact: false,
    cachedEditProjectContact: [],
    showAddZac: false,
    editProjectZac: false,
    dateSetUpPermitZac: "",
    holdGeoCoordinates: [],
    comment: "",
    shouldBlockNavigation: false,
  };

  const {
    projectReferential,
    contractId,
    setProjectsMap,
    setProjectsList,
    detailProject,
    filters,
    addressAutocomplete,
    setAddressAutocomplete,
    search,
    mapDraw,
    setProject,
    pinProjectLocation,
    user,
  } = props;
  if (detailProject) {
    initialState = {
      ...initialState,
      ...detailProject,
      deliveryDate: detailProject.deliveryDate || "",
      pcDate: detailProject.pcDate || "",
      pcDeposit: detailProject.pcDeposit || "",
      dateSetUpPermitZac: detailProject.dateSetUpPermitZac || "",
    };
  }
  const [state, setState] = useState(initialState);

  const { t } = useTranslation();
  const history = useHistory();
  const [schema] = getFormatedForm(ProjectModel);

  useEffect(() => {
    if (props.url) {
      setState({ ...state, url: props.url });
    }
  }, [props.url]);

  useEffect(() => {
    if (props.pinProjectLocation) {
      const { longitude, latitude } = pinProjectLocation;
      if (props.showPin && props.synchroPin) {
        setState({ ...state, ...pinProjectLocation });
      } else {
        setState({ ...state, latitude, longitude });
      }
      const id = detailProject ? detailProject.id : null;
      props.setDuplicatedProjects(contractId, latitude, longitude, id);
    }
  }, [props.pinProjectLocation]);

  useEffect(() => {
    if (props.showPin && props.synchroPin) {
      if (props.pinProjectLocation) {
        setState({ ...state, ...pinProjectLocation });
        const id = detailProject ? detailProject.id : null;
        const { longitude, latitude } = pinProjectLocation;
        props.setDuplicatedProjects(contractId, latitude, longitude, id);
      }
    }
  }, [props.showPin, props.synchroPin]);

  const alertDataChangeRender = () => {
    if (state.shouldBlockNavigation && detailProject) {
      return <RouterPrompt when={true} title={t("forms:project.warrning")} onOK={() => true} onCancel={() => false} />;
    }
  };

  const editProjectContactsRender = () => {
    return (
      <SzModal
        size="xl"
        title={t("forms:project.edit_project_contacts")}
        show={state.editProjectContacts}
        handleClose={() => setState({ ...state, editProjectContacts: false, cachedEditProjectContact: [] })}
      >
        <span onClick={() => setState({ ...state, addContacts: true })} className="cursor-pointer float-right pr-5">
          <SzIcon icon="add-circle" className="m-2" variant="line" />
          <span>{t("contact.create_contact")}</span>
        </span>
        <EditProjectContacts
          defaultContacts={
            state.mainContact
              ? [
                  {
                    ...state.mainContact,
                    isMain: true,
                  },
                  ...state.contacts,
                ]
              : [...state.contacts]
          }
          refreshListContact={state.refreshListContact}
          cachedEditProjectContact={state.cachedEditProjectContact}
          setProjectContactsHandle={setProjectContactsHandle}
          contractId={contractId}
          showAddContact={(cachedEditProjectContact: IContact[]) =>
            setState({
              ...state,
              showAddContact: true,
              editProjectContacts: false,
              cachedEditProjectContact,
            })
          }
        />
      </SzModal>
    );
  };

  const addContactsRender = () => {
    const contractId = getContractId(user);

    return (
      <SzModal
        size="xl"
        title={t("forms:project.add_contact")}
        show={state.addContacts}
        handleClose={() => setState({ ...state, addContacts: false })}
      >
        <ContactForm
          contractId={contractId}
          contactTypes={projectReferential.contactTypes}
          saveAction={saveContactAndSelect}
          redirect={false}
          cancel={() => setState({ ...state, addContacts: false })}
        />
      </SzModal>
    );
  };

  const editProjectZacRender = () => {
    return (
      <SzModal
        size="xl"
        title={t("forms:project.edit_project_zac")}
        show={state.editProjectZac}
        handleClose={() => setState({ ...state, editProjectZac: false })}
      >
        <span onClick={() => setState({ ...state, showAddZac: true })} className="cursor-pointer float-right pr-5">
          <SzIcon icon="add-circle" className="m-2" variant="line" />
          <span>{t("zac.create_zac")}</span>
        </span>
        <EditProjectZac
          zac={state.zac}
          contractId={contractId}
          closeHandle={() => setState({ ...state })}
          setProjectZacHandle={setProjectZacHandle}
        />
      </SzModal>
    );
  };

  const addZacRender = () => {
    const contractId = getContractId(user);
    return (
      <SzModal
        size="xl"
        title={t("forms:project.add_zac")}
        show={state.showAddZac}
        handleClose={() => setState({ ...state, showAddZac: false })}
      >
        <ZacForm
          contractId={contractId}
          geoJson={null}
          saveAction={saveZacAndSelect}
          redirect={false}
          cancel={() => setState({ ...state, showAddZac: false })}
        />
      </SzModal>
    );
  };

  const saveZacAndSelect = (payload: IZac) => {
    setState({
      ...state,
      zac: payload,
      showAddZac: false,
      editProjectZac: false,
    });
  };

  const saveContactAndSelect = (payload: IContact) => {
    payload.isMain = true;
    const { contacts, mainContact } = state;
    if (null !== mainContact) {
      mainContact.isMain = false;
      contacts.push(mainContact);
    }
    setState({
      ...state,
      mainContact: payload,
      addContacts: false,
      contacts,
      refreshListContact: true,
    });
  };

  const setProjectContactsHandle = (listContacts: any) => {
    let mainContact = null;
    const contacts: any = [];
    listContacts.forEach((contact: any) => {
      if (contact.isMain === true) {
        mainContact = contact;
      } else {
        contacts.push(contact);
      }
    });

    setState({ ...state, mainContact, contacts });
  };

  const setProjectZacHandle = (payload: any) => {
    setState({ ...state, zac: payload, editProjectZac: false });
  };

  const getTypeOption = () => {
    return Object.values(projectReferential.types).map((item: any) => {
      return { value: item.id, label: t(`forms:project.${item.code}`) };
    });
  };
  const getStatusOption = () => {
    return Object.values(projectReferential.status).map((item: any) => {
      return { value: item.id, label: t(`forms:project.${item.code}`) };
    });
  };
  const {
    name,
    status,
    type,
    address,
    mainContact,
    hold,
    deliveryDate,
    zac,
    url,
    pcDate,
    pcNumber,
    dateSetUpPermitZac,
    pcDeposit,
    comment,
  }: any = ProjectModel;

  const saveProject = () => {
    let valuesState = state;
    const id = detailProject ? detailProject.id : null;
    if (props.mapDraw) {
      valuesState = { ...state, holdGeoCoordinates: mapDraw.features };
    }

    if (isMerge) {
      ProjectService.mergeProject(contractId, valuesState, props.projectToMerge1.id, props.projectToMerge2.id).then(
        (response: any) => {
          postSave(response);
        },
      );
    } else {
      ProjectService.saveProject(contractId, valuesState, id).then((response: any) => {
        postSave(response);
      });
    }
  };

  const postSave = (response: any) => {
    if (response.data.errors) {
      setState({ ...state, errors: true });
    } else {
      if (props.potentialProjectId !== undefined && props.potentialProjectId !== null) {
        robotService
          .updateProjectStatus(contractId, props.potentialProjectId, potentialProjectStatus.validated)
          .then(() => {
            robotService.getPotentailProjects(contractId).then((data: any) => {
              props.setPotentialProjectsList(data);
            });
          });
      }
      ProjectService.getAllProjects(contractId, filters, search).then((response) => {
        setProjectsMap(response);
        setProject(null);
      });
      ProjectService.getProjects(contractId, filters, search).then((response) => {
        setProjectsList(response);
        history.push("/");
      });
    }
  };

  const deleteAttachmentHandle = (file: any, name: string) => {
    // if file already saved
    const attachmentsToDelete = state.attachmentsToDelete;
    if (!file.tmpDir) {
      attachmentsToDelete.push(file);
    }
    setState({ ...state, [name]: null, attachmentsToDelete });
  };

  const deleteDateHandle = (name: string) => {
    setState({ ...state, [name]: "" });
  };

  const dropHandle = (files: any) => {
    if (files.length === 0) {
      const fileErrors = { ...initialState.fileErrors, otherAttachments: true };
      setState({ ...state, fileErrors });

      return false;
    }
    files.forEach((file: File) => {
      AttachmentService.postAttachments(file).then((response: any) => {
        const uploadedFile = response.data;
        const otherAttachments: any = state.otherAttachments;
        const fileErrors = initialState.fileErrors;
        otherAttachments.push(uploadedFile);
        setState({ ...state, otherAttachments, fileErrors });
      });
    });
  };

  const fileChangeHandle = (event: any) => {
    const file = event.target.files[0];
    const { name, accept } = event.target;
    if (accept.includes(file.type) && file.type) {
      AttachmentService.postAttachments(file).then((response: any) => {
        const uploadedFile = response.data;
        const fileErrors = initialState.fileErrors;
        setState({ ...state, [name]: uploadedFile, fileErrors });
      });
    } else {
      const fileErrors = { ...initialState.fileErrors, [name]: true };
      setState({ ...state, fileErrors });
    }
  };

  const deleteOtherFileHandle = (file: any) => {
    const { otherAttachments } = state;
    const attachments: IAttachment[] = [];
    otherAttachments.forEach((item: IAttachment) => {
      if (item.id !== file.id) {
        attachments.push(item);
      }
    });
    const { attachmentsToDelete } = state;
    if (!file.tmpDir) {
      attachmentsToDelete.push(file);
    }
    setState({ ...state, otherAttachments: [...attachments], attachmentsToDelete });
  };

  const isLabelExist = () => {
    return !(
      props.extractedLabels === undefined ||
      props.extractedLabels === null ||
      props.extractedLabels.length === 0
    );
  };

  const labelSelect = (title: string, label: string, required = false) => {
    if (
      props.extractedLabels === undefined ||
      props.extractedLabels === null ||
      props.extractedLabels.length === 0 ||
      isMerge
    ) {
      return;
    }
    if (!LABELS.includes(label)) {
      return;
    }
    const tag: string = label.includes("DATE") ? "DATE" : label;
    const values = props.extractedLabels.filter((item: any) => {
      return item.tag === tag;
    });
    const choices = values.length > 0 ? values[0].keyword : [];
    return (
      <div className="col-6 m-auto">
        <label className={`sz-label-input position-relative d-block ${required && "required"}`}>{title}</label>
        {choices.map((item: { text: string; transformedData: string }, index: number) => {
          return (
            <div className="row mb-1" key={item.text + index}>
              <div className={`col bg-light ml-3 ${tag !== "DATE" && "px-1"}`}>
                {tag === "DATE" ? (
                  <div>
                    <div className="row">
                      <div className="col px-1">{item.text}</div>
                    </div>
                    {item.transformedData && (
                      <div className="row">
                        <div className="col-auto px-1">
                          {t("potentialProject.details.create-project.transormedDate")}
                        </div>
                        <div className="col px-1">{item.transformedData}</div>
                      </div>
                    )}
                  </div>
                ) : (
                  item.text
                )}
              </div>
              <div
                className="col-auto m-auto"
                onClick={() => {
                  switch (label) {
                    case "PROJECT_NAME":
                      setState({ ...state, name: item.text });
                      break;
                    case "LOCATION":
                      const value = item.text;
                      setState({
                        ...state,
                        address: value,
                        city: "",
                        street: "",
                        latitude: "",
                        longitude: "",
                        number: "",
                      });
                      if (value.length >= NBR_CHAR_START_AUTO_COMPLETE) {
                        MapGeoCodeService.autoCompleteAddress(value).then((data) => {
                          setAddressAutocomplete(data);
                        });
                      } else {
                        setAddressAutocomplete([]);
                      }
                      break;
                    case "SURFACE":
                      const matches = item.text.match(/(\d+)/);
                      if (matches) {
                        setState({ ...state, hold: Number(matches[0]) });
                      }
                      break;
                    case "DATE_PREV":
                      const result = checkIsDateValid(item.transformedData ? item.transformedData : item.text);
                      if (result.isValid) {
                        setState({ ...state, deliveryDate: result.date });
                      }
                      break;
                    case "DATE_PERMIS":
                      const output = checkIsDateValid(item.transformedData ? item.transformedData : item.text);
                      if (output.isValid) {
                        setState({ ...state, pcDate: output.date });
                      }
                      break;
                    default:
                      break;
                  }
                }}
              >
                {((tag === "DATE" && item.transformedData) || tag !== "DATE") && (
                  <SzIcon icon="arrow-button-right-2" variant="bold" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const selectMerge = (title: string, label: string, required = false, className = "col-6") => {
    if (
      props.extractedLabels === undefined ||
      props.extractedLabels === null ||
      props.extractedLabels.length === 0 ||
      !isMerge
    ) {
      return;
    }
    const values = props.extractedLabels.filter((item: any) => {
      return item.tag === label;
    });
    const choices = values.length > 0 ? values[0].keyword : [];
    return (
      <div className={`${className} m-auto`}>
        {label !== "zac" ? (
          <label className={`sz-label-input position-relative d-block ${required && "required"}`}>{title}</label>
        ) : (
          ""
        )}
        {choices.map(
          (item: { text: any; transformedData: string; translate?: string; isDate?: boolean }, index: number) => {
            return (
              <div className="row mb-1" key={item.text + index}>
                <div className={`col bg-light ml-3 px-1`}>
                  {label === "mainContact" && contactLabel(item.text)}
                  {label !== "mainContact" && (item.translate ? item.translate : item.text.name || item.text)}
                </div>
                <div
                  className="col-auto m-auto"
                  onClick={() => {
                    if (label === "address") {
                      const value = item.text;
                      setState({
                        ...state,
                        address: value,
                        city: "",
                        street: "",
                        latitude: "",
                        longitude: "",
                        number: "",
                      });
                      MapGeoCodeService.autoCompleteAddress(value).then((data) => {
                        setAddressAutocomplete(data);
                      });
                    } else if (item.translate) {
                      setState({
                        ...state,
                        [label]:
                          label === "isZac"
                            ? item.text === "true"
                            : {
                                value: item.text,
                                label: item.translate,
                              },
                      });
                    } else if (item.isDate) {
                      setState({ ...state, [label]: stringToDate(item.text) });
                    } else {
                      setState({ ...state, [label]: item.text });
                    }
                  }}
                >
                  <SzIcon icon="arrow-button-right-2" variant="bold" />
                </div>
              </div>
            );
          },
        )}
      </div>
    );
  };

  const attachmentMerge = (title: string, label: string) => {
    if (
      props.extractedLabels === undefined ||
      props.extractedLabels === null ||
      props.extractedLabels.length === 0 ||
      !isMerge
    ) {
      return;
    }
    const values = props.extractedLabels.filter((item: any) => {
      return item.tag === label;
    });
    const choices = values.length > 0 ? values[0].files : [];
    return (
      <div className="col-12 m-auto">
        <label className="sz-label-input position-relative d-block">{title}</label>
        {choices.map((item: { file: any; projectName: any }, index: number) => {
          return (
            <div className="row mb-1" key={item.file.id + index}>
              <div className={`col bg-light ml-3 px-1`}>{`${item.projectName}:${item.file.originalFileName}`}</div>
              <div
                className="col-auto m-auto"
                onClick={() => {
                  setState({ ...state, [label]: item.file });
                }}
              >
                <SzIcon icon="arrow-button-right-2" variant="bold" />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const multiAttachmentMerge = () => {
    if (
      props.extractedLabels === undefined ||
      props.extractedLabels === null ||
      props.extractedLabels.length === 0 ||
      !isMerge
    ) {
      return;
    }

    const values = props.extractedLabels.filter((item: any) => {
      return item.tag === "otherAttachments";
    });
    const choices = values.length > 0 ? values[0].files : [];
    const inList = (id: any) => {
      let isInList = false;
      state.otherAttachments.forEach((file: any) => {
        if (file.id === id) {
          isInList = true;
        }
      });

      return isInList;
    };
    return (
      <div className="col-12 m-auto">
        {choices.map((item: { file: any; projectName: any }, index: number) => {
          return (
            <div className="row mb-1" key={item.file.id + index}>
              <div className={`col bg-light ml-3 px-1`}>{`${item.projectName}:${item.file.originalFileName}`}</div>
              <div
                className={`col-auto m-auto ${inList(item.file.id) ? "text-disabled" : ""}`}
                onClick={() => {
                  if (!inList(item.file.id)) {
                    const attachments = state.otherAttachments;
                    attachments.push(item.file);
                    setState({ ...state, otherAttachments: [...attachments] });
                  }
                }}
              >
                <SzIcon icon="arrow-button-right-2" variant="bold" />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {state.errors && <SzAlert variant="danger">{t("forms:An_error_has_occurred")}</SzAlert>}
      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        initialValues={state}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, handleChange, handleBlur, values, errors, setFieldError, setErrors }) => {
          schema.isValid(state).then((isValidForm: any) => {
            if (isValidForm !== state.isValid) {
              setErrors({});
              setState({ ...state, isValid: isValidForm });
            }
          });
          const changeHandle = (e: any) => {
            setState({ ...state, [e.target.name]: e.target.value, shouldBlockNavigation: true });
          };

          const numberChangeHandle = (e: any) => {
            setState({ ...state, [e.target.name]: e.target.value, shouldBlockNavigation: true });
          };

          const dateChangeHandle = (name: string, value: Date) => {
            if(value instanceof Date)
            {
                setState({ ...state, [name]: value, shouldBlockNavigation: true });
            }
          };

          const selectChangeHandle = (option: any, event: any) => {
            setState({ ...state, [event.name]: option, shouldBlockNavigation: true });
          };

          const addressAutoCompleteHandle = (e: any) => {
            const value = e.target.value;
            setState({
              ...state,
              address: value,
              city: "",
              street: "",
              latitude: "",
              longitude: "",
              number: "",
              shouldBlockNavigation: true,
            });
            if (e.target.value.length >= NBR_CHAR_START_AUTO_COMPLETE) {
              MapGeoCodeService.autoCompleteAddress(value).then((data) => {
                setAddressAutocomplete(data);
              });
            } else {
              setAddressAutocomplete([]);
            }
          };
          const addressAutoCompleteClickHandle = (
            latitude: any,
            longitude: any,
            street: any,
            numberStreet: any,
            postcode: any,
            city: any,
            address: any,
          ) => {
            setState({
              ...state,
              address,
              latitude,
              longitude,
              city,
              street,
              number: numberStreet,
              shouldBlockNavigation: true,
            });
            setAddressAutocomplete([]);
            const id = detailProject ? detailProject.id : null;
            props.setDuplicatedProjects(contractId, latitude, longitude, id);
            props.setMapCenterHandle(latitude, longitude, true);
          };

          const customHandleBlur = (e: any) => {
            validateTarget(e.target.name, e.target.value);
            const fileErrors = initialState.fileErrors;
            setState({ ...state, fileErrors });
          };

          const validateTarget = (targetName: string, value: string) => {
            schema
              .validateAt(targetName, { ...state, [targetName]: value })
              .then(() => {
                const newErrors = { ...errors };
                deleteFromObject(targetName, newErrors);
                setErrors(newErrors);
              })
              .catch((error: any) => {
                const { path, message } = error;
                setFieldError(path, message);
              });
          };

          const deleteFromObject = (toDeleteKey: string, object: any) => {
            for (const index in object) {
              if (index === toDeleteKey) {
                delete object[index];
              }
            }
          };

          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!state.isValid) {
                  submitForm();
                } else {
                  setState({
                    ...state,
                    shouldBlockNavigation: false,
                  });
                  saveProject();
                }
              }}
            >
              <SzBox className="row mt-4 m-0 box" tag="div">
                <div className="col">
                  <div className="row">
                    {isMerge
                      ? selectMerge(t(name.label), "name", true)
                      : labelSelect(t(name.label), "PROJECT_NAME", true)}
                    <div className={`col pb-0 m-auto ${name.required ? "required-div" : ""}`}>
                      <SzInput
                        className="mb-0"
                        onChange={changeHandle}
                        label={!isLabelExist() ? t(name.label) : ""}
                        name={name.name}
                        onBlur={customHandleBlur}
                        required={name.required}
                        placeholder={t(name.placeholder)}
                        type={name.type}
                        value={state.name}
                      />
                      {errors.name && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.name}`)}</>
                        </SzAlert>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2 ">
                    {attachmentMerge(t("forms:project.photo"), "photoFile")}
                    <div className="col-4">
                      {!isMerge && (
                        <SzTypographie color="inactive" className="sz-label-input" variant="text">
                          {t("forms:project.photo")}
                        </SzTypographie>
                      )}
                    </div>
                    <div className="col-4">
                      {state.photoFile === null && (
                        <div className="upload-zone mt-2">
                          <label htmlFor="photo-file">
                            <SzIcon icon="attachment" variant="bold"></SzIcon>
                            <input
                              type="file"
                              onChange={fileChangeHandle}
                              id="photo-file"
                              name="photoFile"
                              className="form-control-file"
                              accept={acceptExtPhoto}
                            />
                          </label>
                        </div>
                      )}

                      {state.photoFile !== null && (
                        <>
                          {state.photoFile.originalFileName}
                          <span
                            className="delete-file"
                            onClick={() => deleteAttachmentHandle(state.photoFile, "photoFile")}
                          >
                            {" "}
                            X
                          </span>
                        </>
                      )}
                    </div>
                    {state.fileErrors.photoFile && (
                      <SzAlert variant="danger">
                        <>{t("forms:project.errors.photo_file")}</>
                      </SzAlert>
                    )}
                  </div>
                  <div className="row ">
                    {selectMerge(t(name.label), "status", true)}

                    <div className="pb-0 input_group col-6">
                      {!isMerge && (
                        <label className="text-inactive sz-label-input position-relative d-block required">
                          {t(status.label)}
                        </label>
                      )}
                      <SzSelect
                        className="mb-1"
                        onChange={selectChangeHandle}
                        name={status.name}
                        required={status.required}
                        placeholder={t(status.placeholder)}
                        options={getStatusOption()}
                        value={state.status}
                      />
                      {errors.status && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.status}`)}</>
                        </SzAlert>
                      )}
                    </div>
                    {selectMerge(t(type.label), "type", true)}
                    <div className="pb-0 input_group col-6">
                      {!isMerge && (
                        <label className=" text-inactive sz-label-input position-relative d-block required">
                          {t(type.label)}
                        </label>
                      )}
                      <SzSelect
                        className="mb-1"
                        onChange={selectChangeHandle}
                        name={type.name}
                        required={type.required}
                        placeholder={t(type.placeholder)}
                        options={getTypeOption()}
                        value={state.type}
                      />
                      {errors.type && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.type}`)}</>
                        </SzAlert>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {isMerge
                      ? selectMerge(t(address.label), "address", true)
                      : labelSelect(t(address.label), "LOCATION", true)}
                    <div className={`col-9 p-0 ${isMerge ? "mt-3" : ""}`}>
                      <div className={`pb-0 col m-auto ${address.required ? "required-div" : ""}`}>
                        <SzInput
                          className="mb-0"
                          onChange={addressAutoCompleteHandle}
                          onBlur={customHandleBlur}
                          label={!isLabelExist() ? t(address.label) : ""}
                          name={address.name}
                          required={address.required}
                          placeholder={t(address.placeholder)}
                          type={address.type}
                          icon="search"
                          value={state.address}
                        />
                        {addressAutocomplete && (
                          <AddressAutoCompleteItemsComponent
                            addressAutoComplete={{ items: addressAutocomplete }}
                            addressAutoCompleteClickHandle={addressAutoCompleteClickHandle}
                          />
                        )}
                        {errors.address && (
                          <SzAlert variant="danger">
                            <>{t(`forms:${errors.address}`)}</>
                          </SzAlert>
                        )}
                        {!errors.address && (errors.city || errors.street) && (
                          <SzAlert variant="danger">
                            <>{t(`forms:invalid_field`)}</>
                          </SzAlert>
                        )}
                      </div>
                    </div>

                    {!props.potentialProjectId && (
                      <div className={`col-3 row p-0 m-0 ${isMerge ? "" : "mt-3"}`}>
                        <div className="col-6 p-0" title={t("forms:project.show_pin")}>
                          <SzButton
                            className={`mt-3 ${!props.showPin ? "disabled text-disabled" : ""}`}
                            icon="pin-location-1"
                            onClick={props.changeShowPin}
                          />
                        </div>
                        {props.showPin && (
                          <div className="col-6 p-0" title={t("forms:project.pin_location_synchronisation")}>
                            <SzButton
                              className={`mt-3 ${!props.synchroPin ? "disabled text-disabled" : ""}`}
                              onClick={props.changeSynchroPin}
                            >
                              <img
                                className="img-icon-btn"
                                alt={t("forms:project.pin_location_synchronisation")}
                                src={synchroIcon}
                              />
                            </SzButton>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <SzInput
                        className="mb-0"
                        label={t("forms:project.latitude")}
                        name={"latitude"}
                        placeholder={t("forms:project.latitude")}
                        type={"text"}
                        value={state.latitude}
                        disabled
                      />
                    </div>

                    <div className="col-6">
                      <SzInput
                        className="mb-0"
                        label={t("forms:project.longitude")}
                        name="longitude"
                        placeholder={t("forms:project.longitude")}
                        type={"text"}
                        value={state.longitude}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row">
                    {selectMerge(t(mainContact.label), "mainContact", false)}

                    <div className="pb-0 input_group col ">
                      <div className="row">
                        <div
                          className="pb-0 pr-0 input_group col-11 input-group-contact input-text-pencil cursor-pointer"
                          onClick={() =>
                            setState({
                              ...state,
                              editProjectContacts: true,
                              shouldBlockNavigation: true,
                            })
                          }
                        >
                          <SzIcon icon="pencil-write" className="pb-2" variant="line" />
                          <SzInput
                            className="mb-0 cursor-pointer"
                            readOnly={true}
                            autocomplete={false}
                            label={!isMerge ? t(mainContact.label) : ""}
                            name={mainContact.name}
                            required={mainContact.required}
                            placeholder={t(mainContact.placeholder)}
                            type={mainContact.type}
                            onChange={() => {}}
                            value={contactLabel(state.mainContact)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {isMerge
                      ? selectMerge(t(hold.label), "hold", false)
                      : labelSelect(t(address.label), "SURFACE", false)}
                    <div className="pb-0 col-6 m-auto">
                      <SzInput
                        className="mb-0"
                        onChange={numberChangeHandle}
                        onBlur={customHandleBlur}
                        label={!isLabelExist() ? t(hold.label) : ""}
                        name={hold.name}
                        required={hold.required}
                        placeholder={t(hold.placeholder)}
                        type={hold.type}
                        value={state.hold + ""}
                      />
                      {errors.hold && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.hold}`)}</>
                        </SzAlert>
                      )}
                    </div>
                    {isMerge
                      ? selectMerge(t(deliveryDate.label), "deliveryDate")
                      : labelSelect(t(deliveryDate.label), "DATE_PREV")}
                    <div className="pb-0 input_group col-5 m-auto">
                      {!isLabelExist() && (
                        <label className={`text-inactive sz-label-input position-relative d-block `}>
                          {t(deliveryDate.label)}
                        </label>
                      )}
                      <SzDatePicker
                        endDate={undefined}
                        endMinDate={undefined}
                        onChangeEndDate={function noRefCheck() {}}
                        onChangeStartDate={dateChangeHandle.bind(null, deliveryDate.name)}
                        startDate={state.deliveryDate ? new Date(state.deliveryDate) : undefined}
                        startMinDate={undefined}
                        format="dd/MM/yyyy"
                      />
                      {errors.deliveryDate && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.deliveryDate}`)}</>
                        </SzAlert>
                      )}
                    </div>
                    <div className="col-1 p-0 delete-button">
                      <SzButton
                        icon={"bin-2-alternate"}
                        onClick={() => deleteDateHandle(deliveryDate.name)}
                        type="reset"
                        variant="secondary"
                      />
                    </div>
                  </div>
                  <div className="row">
                    {selectMerge(t(zac.label), "zac", true)}
                    <div
                      className="pb-0 pr-0 input_group col-11 input-group-zac input-text-pencil cursor-pointer"
                      onClick={() =>
                        setState({
                          ...state,
                          editProjectZac: true,
                          shouldBlockNavigation: true,
                        })
                      }
                    >
                      <SzIcon icon="pencil-write" className="pb-2" variant="line" />
                      <SzInput
                        className="mb-0 cursor-pointer"
                        readOnly={true}
                        autocomplete={false}
                        label={!isMerge ? t(zac.label) : ""}
                        name={zac.name}
                        required={zac.required}
                        placeholder={t(zac.placeholder)}
                        type={zac.type}
                        onChange={() => {}}
                        value={zacLabel(state.zac)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {selectMerge(t(url.label), "url")}
                    <div className="pb-0 col-6">
                      <SzInput
                        className="mb-0"
                        onChange={changeHandle}
                        onBlur={customHandleBlur}
                        label={!isMerge ? t(url.label) : ""}
                        name={url.name}
                        required={url.required}
                        placeholder={t(url.placeholder)}
                        type={url.type}
                        value={state.url}
                      />
                      {errors.url && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.url}`)}</>
                        </SzAlert>
                      )}
                    </div>
                  </div>
                  <SzTypographie variant="text" className="mt-3 sz-label-input" color="dark">
                    {t("forms:project.pc_title")}
                  </SzTypographie>
                  <div className="row">
                    {selectMerge(t(pcNumber.label), "pcNumber")}
                    <div className="pb-0 col-6">
                      <SzInput
                        className="mb-0"
                        onChange={changeHandle}
                        onBlur={customHandleBlur}
                        label={!isMerge ? t(pcNumber.label) : ""}
                        name={pcNumber.name}
                        required={pcNumber.required}
                        placeholder={t(pcNumber.placeholder)}
                        type={pcNumber.type}
                        value={state.pcNumber + ""}
                      />
                      {errors.pcNumber && (
                        <SzAlert variant="danger">
                          <>{t(errors.pcNumber)}</>
                        </SzAlert>
                      )}
                    </div>
                    {isMerge
                      ? selectMerge(t(pcDeposit.label), "pcDeposit")
                      : labelSelect(t(pcDeposit.label), "DATE_DEPOSIT")}
                    <div className="pb-0 input_group col-5 m-auto">
                      {!isLabelExist() && (
                        <label className={`text-inactive sz-label-input position-relative d-block `}>
                          {t(pcDeposit.label)}
                        </label>
                      )}
                      <SzDatePicker
                        endDate={undefined}
                        endMinDate={undefined}
                        onChangeStartDate={dateChangeHandle.bind(null, pcDeposit.name)}
                        startDate={state.pcDeposit ? new Date(state.pcDeposit) : undefined}
                        startMinDate={undefined}
                        format="dd/MM/yyyy"
                      />
                      {errors.pcDeposit && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.pcDeposit}`)}</>
                        </SzAlert>
                      )}
                    </div>
                    <div className="col-1 p-0 delete-button">
                      <SzButton
                        icon={"bin-2-alternate"}
                        onClick={() => deleteDateHandle(pcDeposit.name)}
                        type="reset"
                        variant="secondary"
                      />
                    </div>
                  </div>
                  <div className="row ">
                    {isMerge ? selectMerge(t(pcDate.label), "pcDate") : labelSelect(t(pcDate.label), "DATE_PERMIS")}
                    <div className="pb-0 input_group col-5 m-auto">
                      {!isLabelExist() && (
                        <label className={`text-inactive sz-label-input position-relative d-block `}>
                          {t(pcDate.label)}
                        </label>
                      )}
                      <SzDatePicker
                        endDate={undefined}
                        endMinDate={undefined}
                        onChangeStartDate={dateChangeHandle.bind(null, pcDate.name)}
                        startDate={state.pcDate ? new Date(state.pcDate) : undefined}
                        startMinDate={undefined}
                        format="dd/MM/yyyy"
                      />
                      {errors.pcDate && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.pcDate}`)}</>
                        </SzAlert>
                      )}
                    </div>
                    <div className="col-1 p-0 delete-button">
                      <SzButton
                        icon={"bin-2-alternate"}
                        onClick={() => deleteDateHandle(pcDate.name)}
                        type="reset"
                        variant="secondary"
                      />
                    </div>
                    <div className={`${isMerge ? "col-12" : "col-6"} mt-4`}>
                      <div className="row">
                        {attachmentMerge(t("forms:project.pc_stopped"), "stoppedFile")}

                        {!isMerge && (
                          <div className="col-6">
                            <SzTypographie color="inactive" className="sz-label-input" variant="text">
                              {t("forms:project.pc_stopped")}
                            </SzTypographie>
                          </div>
                        )}
                        <div className="col-6">
                          {state.stoppedFile === null && (
                            <div className="upload-zone mt-3">
                              <label htmlFor="stopped-file">
                                <SzIcon icon="attachment" variant="bold"></SzIcon>
                                <input
                                  type="file"
                                  onChange={fileChangeHandle}
                                  id="stopped-file"
                                  name="stoppedFile"
                                  className="form-control-file"
                                  accept={acceptExtDropZone}
                                />
                              </label>
                            </div>
                          )}
                          {state.stoppedFile !== null && (
                            <div className="row p-0 m-0">
                              <div
                                className="col-10 p-0 m-0 argus-text-truncate"
                                title={state.stoppedFile.originalFileName}
                              >
                                {state.stoppedFile.originalFileName}
                              </div>
                              <div className="col-2 m-0 p-0 ">
                                <span
                                  className="delete-file"
                                  onClick={() => deleteAttachmentHandle(state.stoppedFile, "stoppedFile")}
                                >
                                  {" "}
                                  X
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {state.fileErrors.stoppedFile && (
                          <SzAlert variant="danger">
                            <>{t("forms:project.errors.stopped_file")}</>
                          </SzAlert>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {selectMerge(t(dateSetUpPermitZac.label), "dateSetUpPermitZac")}
                    <div className="pb-0 input_group col-5">
                      {!isMerge && (
                        <label className="text-inactive sz-label-input position-relative d-block">
                          {t(dateSetUpPermitZac.label)}
                        </label>
                      )}
                      <SzDatePicker
                        endDate={undefined}
                        endMinDate={undefined}
                        onChangeStartDate={dateChangeHandle.bind(null, dateSetUpPermitZac.name)}
                        startDate={state.dateSetUpPermitZac ? new Date(state.dateSetUpPermitZac) : undefined}
                        startMinDate={undefined}
                        format="dd/MM/yyyy"
                      />
                      {errors.dateSetUpPermitZac && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.dateSetUpPermitZac}`)}</>
                        </SzAlert>
                      )}
                    </div>
                    <div className="col-1 p-0 delete-button">
                      <SzButton
                        icon={"bin-2-alternate"}
                        onClick={() => deleteDateHandle(dateSetUpPermitZac.name)}
                        type="reset"
                        variant="secondary"
                      />
                    </div>
                  </div>
                  <SzTypographie variant="text" className="sz-label-input mt-5" color="dark">
                    {t("forms:project.attachments_title")}
                  </SzTypographie>
                  <SzBox className={`${isMerge ? "col-12" : "col-10"}  offset-1`} tag="div">
                    {multiAttachmentMerge()}
                    <SzDropZone
                      accept={acceptExtDropZone}
                      defaultFiles={state.otherAttachments}
                      dropHandle={dropHandle}
                      multiple={true}
                      showNotSavedFile={false}
                      deleteHandle={deleteOtherFileHandle}
                      placeHolder={<SzIcon icon="attachment" variant="bold"></SzIcon>}
                    />
                    <div className="col-12">
                      {state.fileErrors.otherAttachments && (
                        <SzAlert variant="danger">
                          <>{t("forms:project.errors.other_attachments")}</>
                        </SzAlert>
                      )}
                    </div>
                  </SzBox>
                  <SzTypographie variant="text" weight="light" className="mb-3">
                    {t("forms:project.attachments_label")}
                  </SzTypographie>

                  <div className="row">
                    {selectMerge(t(comment.label), "comment", false, "col-12")}
                    <div className="pb-0 input_group col-12">
                      <SzTextArea
                        label={!isMerge ? t(comment.label) : ""}
                        name={comment.name}
                        value={state.comment}
                        onChange={changeHandle}
                      />
                    </div>
                  </div>
                </div>
              </SzBox>

              <div className="required_errors ml-3">
                {!state.name && (
                  <SzTypographie className="error mb-1">* {t(`forms:project.name_is_required`)}</SzTypographie>
                )}
                {!state.type && (
                  <SzTypographie className="error mb-1">* {t(`forms:project.type_is_required`)}</SzTypographie>
                )}
                {!state.status && (
                  <SzTypographie className="error mb-1">* {t(`forms:project.status_is_required`)}</SzTypographie>
                )}
                {!state.address && (
                  <SzTypographie className="error mb-1">* {t(`forms:project.address_is_required`)}</SzTypographie>
                )}
                {state.address && !state.city && !state.street && (
                  <SzTypographie className="error mb-1">* {t(`forms:project.address_is_not_valid`)}</SzTypographie>
                )}
              </div>
              {props.duplicatedProjects && props.duplicatedProjects.length > 0 && (
                <SzTypographie variant="text" weight="light" className="mb-3">
                  {t("forms:project.duplicated_projects")}
                </SzTypographie>
              )}
              <div className="m-0 duplicated-project-list m-1">
                <table className="w-100">
                  {Object.values(props.duplicatedProjects).map((duplicateProject: any) => (
                    <tr>
                      <td className="name">{duplicateProject.name}</td>
                      <td className="address">{duplicateProject.address}</td>
                      <td className="link pt-2">
                        <a href={`/view/project/${duplicateProject.id}`} target="_blank">
                          <span className=" sz-aside__item--icon material-icons">receipt</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              <div className="row m-0 form-actions m-5">
                <div className="col-4">
                  <SzButton
                    variant="secondary"
                    onClick={() => {
                      if (typeof props.cancel !== "undefined") {
                        props.cancel();
                      } else {
                        history.push(detailProject ? `/view/project/${detailProject.id}` : "/");
                      }
                    }}
                    className="cancel-project"
                  >
                    {t("forms:project.cancel")}
                  </SzButton>
                </div>
                <div className="col-4 offset-4">
                  <SzButton type="submit" className="add-project" isDisabled={!state.isValid}>
                    {t(detailProject ? "forms:project.edit" : "forms:project.add")}
                  </SzButton>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      {editProjectContactsRender()}
      {addContactsRender()}
      {editProjectZacRender()}
      {addZacRender()}
      {alertDataChangeRender()}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    filters: state.project.filters,
    search: state.project.searchText,
    zacAutocomplete: state.autocomplete.zac,
    addressAutocomplete: state.autocomplete.address,
    mapDraw: state.mapDraw,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setPotentialProjectsList: potentialProjectAction.setPotentialProjectsList,
  setZacAutocomplete: autocompleteAction.setZacAutocomplete,
  setAddressAutocomplete: autocompleteAction.setAddressAutocomplete,
  setProject: projectAction.setProject,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
