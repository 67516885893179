import React from "react";
import { AppState } from "../../store";
import "./loading.scss";

class Loading extends React.Component<AppState> {

    public render() {
        return (
            <>
                <div className=" background-loading" style={{backgroundImage: `url(/argus/img/bg-loading.png)`}}>
                </div>
            </>
        );
    }
}

export default Loading;
