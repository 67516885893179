
import React from "react";
import {useHistory} from "react-router";
import "./index.scss";
import CreateContactManager from "../../../projectManagement/manager/CreateContactManager";

const CreateContact = () => {
    const history = useHistory();
    return (
        <div className="contact-container w-100 p-3">
            <CreateContactManager history={history}></CreateContactManager>
        </div>
    );
};

export default CreateContact;
