import { Constants } from "../constants";

const initialState = {
    listPotentialProjects: [],
    listDataSources: [],
};
const projectPotentialReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case Constants.SET_LIST_POTENTIAL_PROJECTS:
            return {
                ...state, listPotentialProjects: action.payload,
            };
        case Constants.SET_LIST_DATA_SOURCES:
            return {
                ...state, listDataSources: action.payload,
            };
        default:
            return state;
    }
};
export default projectPotentialReducer;
