
import React from "react";
interface IIFrame {
    link: string;
    width: string;
    heigth: string;
}
const IFrame = (props: IIFrame) => {
    const iframe = `<iframe src=${props.link} frameborder="0" width=${props.width} height=${props.heigth}></iframe>`;
    return (<div className="w-100" dangerouslySetInnerHTML={{ __html: iframe }} />);
};
export default IFrame;

