import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SzBox, SzIcon, SzTypographie } from "@suezenv/react-theme-components";

interface INotificationHeader {
  creatProjectHandle: () => void;
  readNotificationHandle: () => void;
  cancelUrl?: string;
}
const NotificationHeader = (props: INotificationHeader) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className="notification-header mb-2 mt-2">
        <div className="sz-card-collapse__header sz-left-border position-relative align-items-center m-0 row">
          <div className="w-100 col-md-6 col-sm-12">
            <SzBox className="justify-content-around pb-0">
              <label className="mb-0 font-weight-bold">{t("notification_list.title")}</label>
              <SzTypographie className="mb-0" variant="text">
                {t("notification_list.subtitle")}
              </SzTypographie>
            </SzBox>
          </div>
          <div className="col-md-6 col-sm-12 p-0 row align-items-center m-0">
            <div className="col-10">
              <div className="row project-menu">
                <div className="open">
                  <span onClick={props.creatProjectHandle} className="cursor-pointer">
                    <SzIcon icon="add-circle" className="m-2" variant="line" />
                    <span>{t("notification_list.create_project")}</span>
                  </span>
                  <span onClick={props.readNotificationHandle} className="text-decoration-underline cursor-pointer m-4">
                    {t("notification_list.read_all")}
                  </span>
                </div>
              </div>
            </div>
            <div className="go-home  border-left cursor-pointer col-2 p-0 pt-2">
              <SzIcon variant="line" onClick={() => history.push(props.cancelUrl || "/")} icon="close" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationHeader;
