import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";
import {
    SzButton, SzIcon,
    SzInput,
} from "@suezenv/react-theme-components";

const SearchComponent = (props: any) => {

    const ENTER_KEY = 13;
    const {search, searchHandle} = props;
    const [state, setState] = useState({search});
    const { t } = useTranslation();
    const changeHandle = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    /**
     * Lancement de la recherche en appuyant sur entrée.
     *
     * @param event
     */
    const onKeyUp = (event: any) => {
        switch (event.keyCode) {
            case ENTER_KEY: {
                searchHandle(state.search);
                break;
            }
            default: {
                break;
            }
        }
    };
    const resetHandle = ()=>{
        searchHandle('');
        setState({search:''});
    }

    return (
        <>
            <div className="row m-0 p-0 search-box form-actions w-100">
                <div className="col-8 p-2">
                    <SzInput
                        className="mb-0"
                        onChange={changeHandle}
                        onKeyUp={onKeyUp}
                        icon="search"
                        name="search"
                        type="text"
                        value={state.search}
                        placeholder=""
                    />
                    {state.search &&
                    <span
                        className="reset-search sz-form-group__input-group-icon position-absolute d-flex align-items-center justify-content-center cursor-pointer"
                        title={t("reset_search")}>
                        <SzIcon icon="remove" onClick={resetHandle} variant="line"/>
                        </span>
                    }
                </div>
                <div className="pt-2 p-2">
                <SzButton
                    className="mb-0"
                    onClick={()=>searchHandle(state.search)}
                ><SzIcon icon="search" variant="line" /></SzButton>
                </div>
            </div>
        </>
    );

};

export default connect(() => {
}, {})(SearchComponent);
