
import React from "react";
import {useHistory} from "react-router";
import CreateProjectManager from "../../../projectManagement/manager/CreateProjectManager";
import "./index.scss";

const CreateProject = () => {
    const history = useHistory();
    return (
        <div className="project-container container-margin-top row">
            <CreateProjectManager history={history}></CreateProjectManager>
        </div>
    );
};

export default CreateProject;
