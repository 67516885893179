import { Constants } from "../constants";

export default {
  setContractConfiguration(contractConfiguration:any):any {
    return {
      payload: {...contractConfiguration},
      type: Constants.SET_CONTRACT_CONFIGURATION,
    };
  }
};
