import React, {ReactElement} from "react";
import {SzIcon} from "@suezenv/react-theme-components";
import "./index.scss";

interface ISwitch {
    onChangeHandle: () => void;
    onFirstIconClickHandle: () => void;
    onSecondIconClickHandle: () => void;
    checked: boolean;
    firstIcon: string;
    secondIcon: string;
    firstIconClass?: string;
    secondIconClass?: string;
    secondIconTitle?: string;
    firstIconTitle?: string;
}

function Switch(props: ISwitch): ReactElement {
    return (
        <>
            <div title={props.firstIconTitle || ""} className={`mt-2 ${props.firstIconClass || ""}`}>
                <SzIcon icon={props.firstIcon} className="mt-0 cursor-pointer" onClick={props.onFirstIconClickHandle} variant="line"/>
            </div>
            <div className="mt-2 pl-2">
                <label className="switch">
                    <input type="checkbox" onChange={props.onChangeHandle} checked={props.checked}/>
                    <span className="slider"></span>
                </label>
            </div>
            <div title={props.secondIconTitle || ""} className={`p-2 px-2 ${props.secondIconClass || ""}`}>
                <SzIcon icon={props.secondIcon} className="mt-0 cursor-pointer" onClick={props.onSecondIconClickHandle} variant="line"/>
            </div>

        </>
    );
}

export default Switch;
