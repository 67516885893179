
import React from "react";
import {useHistory} from "react-router";
import "./index.scss";
import EditZacManager from "../../../projectManagement/manager/editZacManager";

const EditZac = (props:any) => {
    const history = useHistory();
    const { id } = props.match.params;
    return (
        <div className="zac-container row p-3">
            <EditZacManager idZac={id} history={history}></EditZacManager>
        </div>
    );
};

export default EditZac;
