import {config} from "../../config";
import instanceRequest, {Request} from "../../main/services/Request";

class AttachmentService {

    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public postAttachments(attachment: File) {
        const url = `/api/attachment`;
        const data = new FormData();
        data.append("attachment", attachment);
        return this.axiosService.post(url, data).then((response) => {
            return response;
        });
    }
    public getAttachment(contractId: string, projectId: string, id: string) {
      const url = `${config.apiUrl}/api/${contractId}/project/${projectId}/attachment/${id}`;
      return this.axiosService.getBlob(url, false, false);
    }
}

export default new AttachmentService(instanceRequest);
