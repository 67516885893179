import classnames from "classnames";
import React from "react";
import ReactGA from "react-ga";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { SzIcon, SzSideMenu, Theme } from "@suezenv/react-theme-components";
import SearchProjectComponent from "../../../projectManagement/components/searchProjectComponent";
import ErrorAuthenticationMessage from "../../../user/components/messages/ErrorAuthenticationMessage";
import { servicesCodes } from "../../../user/store/constants";
import { mapConfig, config } from "../../../config";
import { hasService } from "../../utils";
import Header from "../Header";
import Loading from "../Utils/loading";
import ErrorMessage from "../Utils/messages/errorMessage";
import "./container.scss";

export enum menuItems {
  home = "home",
  projects = "projects",
  notification = "notification",
  robot = "robot",
  zac = "zac",
  actor = "actor",
  userAdmin = "userAdmin",
  userManual = "userManual",
}

class Container extends React.Component<any> {
  public state = {
    currentTab: menuItems.home,
    isOpen: false,
  };
  public componentDidMount(): void {
    let currentTab: string = menuItems.home;
    switch (this.props.location.pathname) {
      case "/notification":
        currentTab = menuItems.notification;
        break;
      default:
        currentTab = menuItems.home;
        break;
    }
    this.setState({ currentTab });
  }

  public componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged();
    }
  }

  public onRouteChanged() {
    // send event of page view
    ReactGA.pageview(this.props.location.pathname);
  }

  public render() {
    const { children, connected, error, errorUser, loading, t, user } = this.props;
    const asideClasses = classnames(
      "argus-aside float-left",
      this.state.isOpen ? "sz-aside.sz-aside-enter-done" : "sz-aside-exit-done",
      connected,
    );
    const containerClasses = classnames("argus-container", {
      connected,
      isOpen: connected && this.state.isOpen,
    });

    const menu = [];
    const hasProjectService = hasService(servicesCodes.projectManagement, user);
    const hasNotificationService = hasService(servicesCodes.notification, user);
    const hasRobotService = hasService(servicesCodes.robot, user);
    menu.push(
      hasProjectService
        ? {
            className: "material-icons",
            icon: "dashboard",
            link: "/",
            name: menuItems.projects,
            title: t(menuItems.projects),
          }
        : {
            className: "material-icons",
            icon: "home",
            link: "/",
            name: menuItems.home,
            title: t(menuItems.home),
          },
    );
    if (hasNotificationService) {
      menu.push({
        className: "material-icons",
        icon: "notifications_none",
        link: "/notification",
        name: menuItems.notification,
        title: t(menuItems.notification),
      });
    }
    if (hasRobotService) {
      menu.push({
        className: "material-icons",
        icon: "find_in_page",
        link: "/robot",
        name: menuItems.robot,
        title: t(menuItems.robot),
      });
    }

    const menuAdmin = [];
    if (hasProjectService) {
      menuAdmin.push(
        {
          className: "material-icons",
          icon: <SzIcon variant="line" icon="maps" />,
          link: "/zac",
          name: menuItems.zac,
          title: t("zac_item"),
        },
        {
          className: "material-icons",
          icon: <SzIcon variant="line" icon="professions-man-construction-1" />,
          link: "/actor",
          name: menuItems.actor,
          title: t(menuItems.actor),
        },
      );
    }

    return (
      <>
        <Theme.Provider brand={mapConfig.SDS_THEME}>
          {error.message && !error.jwtCheck && <ErrorMessage show={true} />}
          {errorUser.message && errorUser.jwtCheck && <ErrorAuthenticationMessage show={true} />}
          <div className="argus-header-container">
            <Header
              handleOnClick={(e) => this.setState({ isOpen: !this.state.isOpen })}
              menuIsOpen={this.state.isOpen}
              currentTab={this.state.currentTab}
            />
          </div>
          {loading && <Loading />}
          {connected && (
            <>
              <SzSideMenu className={asideClasses} isOpen={this.state.isOpen}>
                <ul className="sz-aside__list list-unstyled m-0">
                  {menu.map((item, key) => (
                    <li
                      key={key}
                      className="d-flex align-items-center"
                      onClick={() => this.setState({ currentTab: item.name })}
                    >
                      <NavLink
                        activeClassName="active"
                        className="sz-aside__item d-flex align-items-center
                    font-weight-bold text-uppercase text-decoration-none py-2"
                        exact={true}
                        to={item.link}
                      >
                        <i className={`sz-aside__item--icon ${item.className}`}>{item.icon}</i>
                        <span className={"sz-aside__item--title"}>{item.title}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>

                {hasProjectService && (
                  <ul className="argus_admin  list-unstyled m-0 w-100">
                    {menuAdmin.map((item, key) => (
                      <li
                        key={key}
                        className="d-flex align-items-center"
                        onClick={() => this.setState({ currentTab: item.name })}
                      >
                        <NavLink
                          activeClassName="active"
                          className="sz-aside__item argus_admin__item d-flex align-items-center
                              font-weight-bold text-uppercase text-decoration-none"
                          exact={true}
                          to={item.link}
                        >
                          <i className={`sz-aside__item--icon ${item.className}`}>{item.icon}</i>
                          <span className={"sz-aside__item--title"}>{item.title}</span>
                        </NavLink>
                      </li>
                    ))}

                    <li className="d-flex align-items-center">
                      <a
                        className={
                          "sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2"
                        }
                        href={config.userAdminUrl}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <span className={"sz-icon-line cog"} />
                        <span className={"sz-aside__item--title"}>{t("menuItems.userAdmin")}</span>
                      </a>
                    </li>
                    {config.userManualUrl && (
                      <li className="d-flex align-items-center">
                        <a
                          className={
                            "sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2"
                          }
                          href={config.userManualUrl}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          <span className={"sz-icon-line common-file-text"} />
                          <span className={"sz-aside__item--title"}>{t("menuItems.userManual")}</span>
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </SzSideMenu>
            </>
          )}
          <div className="argus-main-container">
            <div className="no-padding container-content">
              {connected && window.location.pathname === "/" && <SearchProjectComponent />}
              <div className={containerClasses}>{children}</div>
            </div>
          </div>
        </Theme.Provider>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.logged,
    error: state.error,
    user: state.user,
    errorUser: state.errorUser || null,
    loading: state.loading,
    showResultDetail: false,
    showSearchResult: false
  };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(Container)));
