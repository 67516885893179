import React from "react";
import { useHistory, withRouter } from "react-router";
import "./zac.scss";
import ListZacManager from "../../../projectManagement/manager/ListZacManager";

const Zac = () => {
  const history = useHistory();
  return (
    <div className="zac-container row p-3">
      <ListZacManager history={history} />
    </div>
  );
};

export default withRouter(Zac);
