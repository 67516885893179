import { SzBox } from "@suezenv/react-theme-components";
import React from "react";

interface IRobotHeader {
    title: () => void;
    countProjects ?: number;
}
const RobotHeader = (props: IRobotHeader) => {
    return (
        <>
            <div className="m-2 bg-white sz-robot-title">
                <div className="sz-card-collapse__header sz-left-border position-relative align-items-center m-0 row">
                    <div className="w-100 col-md-6 col-sm-12">
                        <SzBox className="justify-content-around pb-0">
                            <label className="mb-0 font-weight-bold">{props.title}</label>
                        </SzBox>
                        {(props.countProjects !== undefined && props.countProjects > 0) && (
                            <span
                                className="sz-robot-number  text-center position-absolute text-white bg-danger rounded-circle p-0">{props.countProjects}
                            </span>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default RobotHeader;
