import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import Container from "./main/components/Container";
import CreateProject from "./main/pages/CreateProject";
import EditProject from "./main/pages/EditProject";
import Home from "./main/pages/Home";
import Notification from "./main/pages/Notification";
import Contact from "./main/pages/Contact";
import Zac from "./main/pages/Zac";
import Robot from "./main/pages/Robot";
import ViewProject from "./main/pages/ViewProject";
import { store } from "./main/store";
import { Authenticate as AuthenticateService } from "./user/services/Authenticate";
import { FreshDesk } from "./main/services/freshDesk";
import { config, routes } from "./config";
import ErrorConnection from "./main/pages/ErrorConnection";
import MergeProject from "./main/pages/MergeProject";
import GA4React from "ga-4-react";
import CreateContact from "./main/pages/CreateContact";
import EditContact from "./main/pages/EditContact";
import CreateZac from "./main/pages/CreateZac";
import EditZac from "./main/pages/EditZac";

interface IAppProps {
  history: History;
}

const AppRouter = ({ history }: IAppProps) => {
  useEffect(() => {
    document.title = config.client + " Urba";
    const pathName = history.location.pathname;
    if (pathName !== routes.errorConnection) {
      AuthenticateService.intialize();
      FreshDesk.setup();
      // init the GA
      if (config.analyticsKey) {
        const ga4react = new GA4React(config.analyticsKey);
        ga4react.initialize();
      }
    }
  }, [history]);
  return (
    <>
      <Provider store={store}>
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Container>
            <Switch>
              <Route exact={true} path={routes.home} component={Home} />
              <Route exact={true} path={routes.newProject} component={CreateProject} />
              <Route exact={true} path={routes.viewProject} component={ViewProject} />
              <Route exact={true} path={routes.editProject} component={EditProject} />
              <Route exact={true} path={routes.mergeProject} component={MergeProject} />
              <Route exact={true} path={routes.notification} component={Notification} />
              <Route exact={true} path={routes.robot} component={Robot} />
              <Route exact={true} path={routes.contact} component={Contact} />
              <Route exact={true} path={routes.zac} component={Zac} />
              <Route exact={true} path={routes.newContact} component={CreateContact} />
              <Route exact={true} path={routes.newZac} component={CreateZac} />
              <Route exact={true} path={routes.editZac} component={EditZac} />
              <Route exact={true} path={routes.editContact} component={EditContact} />
              <Route exact={true} path={routes.errorConnection} component={ErrorConnection} />
            </Switch>
          </Container>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default AppRouter;
