import * as Yup from "yup";

export function getFormatedForm(model: any, defaultValues = []) {
    const schema = Yup.object().shape(
        ObjectFromEntries(
            Object.entries(model).map((value: any) => {
                return [value[0], value[1].schema];
            }),
        ),
    );
    return [schema];
}

const ObjectFromEntries = (entries: any): any => {
    const ret = [];
    for (let i = 0; i < entries.length; ++i) {
        ret[entries[i][0]] = entries[i][1];
    }
    return { ...ret };
};

export { default as createProjectModel } from "./ProjectModel";
export { default as contactModel } from "./contactModel";
export { default as createZacModel } from "./ZacModel";
