import React from "react";
import { connect } from "react-redux";
import {mapAction} from "../../../map/store/actions";
import ProjectForm from "../../forms/ProjectForm";
import { projectAction, projectReferentialAction } from "../../store/actions";
import AbstractProjectManager from "../AbstractProjectManager";

class CreateProjectFromPotentialManager extends AbstractProjectManager {
    public render(): any {
        const { projectReferential, setProjectsMap, setProjectsList, cancel, url, extractedLabel, potentialProjectId } = this.props;
        return (
            <>
                {this.state.contractId && (
                    <>
                        <div className="project-form p-0 m-0  col-12">
                            <ProjectForm
                                setDuplicatedProjects = {this.setDuplicatedProjects.bind(this)}
                                duplicatedProjects = {this.props.duplicatedProjects}
                                contractId={this.state.contractId}
                                potentialProjectId={potentialProjectId}
                                projectReferential={projectReferential}
                                extractedLabels={extractedLabel}
                                url={url}
                                cancel={cancel}
                                setProjectsMap={setProjectsMap}
                                setProjectsList={setProjectsList}
                                synchroPin={this.state.synchroPin}
                                changeSynchroPin={this.changeSynchroPin.bind(this)}
                                showPin={this.state.showPin}
                                changeShowPin={this.changeShowPin.bind(this)}
                                pinProjectLocation={this.state.pinProjectLocation}
                                setMapCenterHandle={this.setMapCenterHandle.bind(this)}
                            ></ProjectForm>

                        </div>
                    </>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    setProjectTypes: projectReferentialAction.setProjectTypes,
    setProjectStatus: projectReferentialAction.setProjectStatus,
    setProjectProgress: projectReferentialAction.setProjectProgress,
    setContactTypes: projectReferentialAction.setContactTypes,
    setProjectsMap: projectAction.setProjectsMap,
    setProjectsList: projectAction.setProjectsList,
    setDuplicatedProjects: projectAction.duplicatedProjects,
    setMapInfo: mapAction.setMapInfo,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        projectReferential: state.projectReferential,
        displayMap: state.project.displayMap,
        duplicatedProjects: state.project.duplicatedProjects,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateProjectFromPotentialManager);
