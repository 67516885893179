import React from "react";
import { useHistory, withRouter } from "react-router";
import ListNotificationManager from "../../../projectManagement/manager/Notification/ListNotificationManager";
import "./notification.scss";

const Notification = () => {
  const history = useHistory();
  return (
    <div className="project-container row">
      <ListNotificationManager history={history} />
    </div>
  );
};

export default withRouter(Notification);
