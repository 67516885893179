import { Constants } from "../constants";

const initialState: any = {
  displayMap : true,
  displaySubscriptionProjects: false,
  listProjects: {
    pagination: [],
    data: [],
  },
  mapProjects: [],
  duplicatedProjects: [],
  filters: {
    status: {
      confirmed: true,
      possible: true,
      construction: true,
    },
    type: {
      "to-follow": true,
      "to-not-follow": true,
      "to-follow-not-connectable": true,
      "toDefine" : true
    },
    showSubscriptionProjects: false

  },
  detailProject: null,
  subscriptionProject: null,
  searchText: "",
};

const projectReferentialReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.TOGGLE_DISPLAY_MAP:
      return { ...state, displayMap: !state.displayMap };
    case Constants.SET_DISPLAY_MAP:
      return { ...state, displayMap: action.payload };
    case Constants.SET_LIST_PROJECT_SUBSCRIPTION:
      return { ...state, displaySubscriptionProjects: action.payload };
    case Constants.SET_LIST_PROJECTS:
      return {
        ...state,
        listProjects: { pagination: { ...action.payload.pagination }, data: { ...action.payload.data } },
      };
    case Constants.SET_LIST_DUPLICATE_PROJECTS:
      return {
        ...state,
        duplicatedProjects: action.payload,
      };
    case Constants.SET_MAP_PROJECTS:
      return {
        ...state,
        mapProjects: action.payload.data ,
      };
    case Constants.SET_PROJECT:
      return {
        ...state,
        detailProject: action.payload ,
      };
    case Constants.SET_PROJECT_SUBSCRIPTION:
      return {
        ...state,
        subscriptionProject: action.payload ,
      };
    case Constants.FILER_STATUS:
      return {
        ...state,
        filters: {...state.filters, status: {...state.filters.status , [action.payload]: !state.filters.status[action.payload]}},
      };
    case Constants.FILER_TYPE:
      return {
        ...state,
        filters: {...state.filters, type: {...state.filters.type , [action.payload]: !state.filters.type[action.payload]}},
      };
    case Constants.FILER_SUBSCRIPTION:
      return {
        ...state,
        filters: {...state.filters, showSubscriptionProjects : action.payload},
      };
    case Constants.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    default:
      return state;
  }
};

export default projectReferentialReducer;
