import {Formik} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SzBox, SzInput, SzSpinner} from "@suezenv/react-theme-components";
import {contactLabel} from "../../../main/utils";
import ContactAutoCompleteItemsComponent from "../../components/contactAutoCompleteItemsComponent";
import ContactService from "../../services/ContactService";
import {autocompleteAction} from "../../store/actions";
import {getFormatedForm} from "../models";
import ProjectModel from "../models/ProjectModel";
import {IContact, IEditProjectContacts, IStateEditProjectContacts} from "./types";

const EditProjectContacts = (props: IEditProjectContacts) => {
    const NBR_CHAR_START_AUTO_COMPLETE = 3;
    const initialState: IStateEditProjectContacts = {
        searchContact: "",
        isValid: false,
        contacts: [],
    };

    const [showSearchSpinner, setShowSearchSpinner] = useState(false);
    const {setProjectContactsHandle, contractId, defaultContacts,
        cachedEditProjectContact, setContactAutocomplete, contactAutocomplete, refreshListContact} = props;
    const initialContacts = (cachedEditProjectContact.length > 0) ? cachedEditProjectContact : defaultContacts;
    const [state, setState] = useState({
        ...initialState,
        contacts: initialContacts,
        refreshListContact,
    });
    if (initialContacts !== state.contacts && refreshListContact) {
        setState({
            ...initialState,
            contacts: initialContacts,
            refreshListContact: false,
        });
    }
    const {t} = useTranslation();
    const [schema] = getFormatedForm(ProjectModel);
    const {
        searchContact,
    }: any = ProjectModel;

    const hasMainContact = () => {
        return state.contacts.find((contact) => contact.isMain);
    };

    const setMainHandle = (contactId: string) => {
        let {contacts} = state;
        contacts = contacts.map((contact: IContact) => {
            contact.isMain = (contact.id === contactId);
            return contact;
        });

        setProjectContactsHandle(contacts);
        setState({...state, contacts});
    };

    const renderContacts = () => {
        return state.contacts.map((contact: IContact) =>
            <div key={contact.id} className="row m-0">
                <label className="font-weight-bold col-11 p-0 pt-2">
                    <input type="radio" onChange={() => setMainHandle(contact.id)} checked={contact.isMain}/>
                    <div className="d-inline pl-2"> {contactLabel(contact)}</div>
                </label>
                <div  className="col-1 pt-2 delete-contact" onClick={() => deleteContactHandle(contact.id)}>X</div>
            </div>,
        );
    };

    const deleteContactHandle = (contactId: string) => {
        let {contacts} = state;
        contacts = contacts.filter(( contact: IContact ) => {
            return contact.id !== contactId;
        });

        setProjectContactsHandle(contacts);
        setState({...state, contacts, refreshListContact: false});
    };

    return (<>
        <Formik
            validationSchema={schema}
            onSubmit={() => {
            }}
            initialValues={state}
            validateOnBlur={false}
            validateOnChange={false}
        >

            {({
                  submitForm,
                  handleChange,
                  errors,
                  setFieldError,
                  setErrors,
              }) => {
                const contactAutoCompleteHandle = (e: any) => {
                    const value = e.target.value;
                    setState({...state, searchContact: value});
                    if (e.target.value.length >= NBR_CHAR_START_AUTO_COMPLETE) {
                        setShowSearchSpinner(true);
                        ContactService.searchContact(contractId, value).then((data: any) => {
                            setShowSearchSpinner(false);

                            // Don't show contact already chosen
                            let items = [];
                            if (data) {
                                items = data.filter((item: IContact) => {
                                    return !state.contacts.find((contact) => (contact.id === item.id));
                                });

                            }
                            setContactAutocomplete(items);
                        }).catch((error: any) => {
                            setContactAutocomplete([]);
                        });
                    } else {
                        ContactService.cancelSearchContact();
                        setShowSearchSpinner(false);
                        setContactAutocomplete([]);
                    }
                };

                const contactAutoCompleteClickHandle = (id: string, firstName: string, lastName: string, socialReason: string, isRealPerson: boolean) => {
                    const {contacts} = state;
                    const found = contacts.find((contact) => contact.id === id);
                    if (!found) {
                        contacts.push({id, firstName, lastName, socialReason, isMain: false, isRealPerson});
                    }
                    setContactAutocomplete([]);
                    setProjectContactsHandle(contacts);
                    setState({...state, searchContact: ""});
                };

                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!state.isValid) {
                        submitForm();
                      } else {
                        // save
                      }
                    }}
                  >
                    <SzBox className="row mt-5 mb-4 m-0 box" tag="div">
                      <div className="pb-0 pr-0 input_group col-8">
                        <SzInput
                          className="mb-0"
                          onChange={contactAutoCompleteHandle}
                          label=""
                          name={searchContact.name}
                          required={searchContact.required}
                          placeholder={t(searchContact.placeholder)}
                          type={searchContact.type}
                          value={state.searchContact}
                          icon="search"
                        />
                      </div>
                      {showSearchSpinner &&
                        <SzSpinner className="mx-4" variant={"dark"}/>
                      }
                      {contactAutocomplete && (
                        <ContactAutoCompleteItemsComponent
                          className={"contact-auto-complete"}
                          contactAutoComplete={{items: contactAutocomplete}}
                          contactAutoCompleteClickHandle={contactAutoCompleteClickHandle}
                        />
                      )}
                    </SzBox>
                    <SzBox className="mt-4 mb-4  m-0 box" tag="div">
                      <label className="font-weight-bold pl-3 pb-2 text-primary-dark">{t("forms:project.list_project_contact")}</label>
                      {state.contacts.length > 0 && (
                        <div className="row m-0 list-radio">
                          <div className="col-8 list-radio-contact">{renderContacts()}</div>
                        </div>
                      )}
                      <div className="row pt-2 m-0 no-main-contact">
                        <label className="font-weight-bold text-primary-dark">
                          <input onChange={() => setMainHandle("")} type="radio" checked={!hasMainContact()} />
                          <div className="d-inline pl-2"> {t("forms:project.no_main_contact")}</div>
                        </label>
                      </div>
                    </SzBox>
                  </form>
                );
            }}
        </Formik>
    </>);
};
const mapStateToProps = (state: any) => {
    return {
        contactAutocomplete: state.autocomplete.contact,
    };
};
const mapDispatchToProps = {
    setContactAutocomplete: autocompleteAction.setContactAutocomplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectContacts);
