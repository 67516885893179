import React from "react";
import {useHistory, withRouter} from "react-router";
import EditProjectManager from "../../../projectManagement/manager/EditProjectManager";

const ViewProject = (props: any) => {
    const history = useHistory();
    const { id } = props.match.params;
    return (
        <div className="project-container container-margin-top row">
            <EditProjectManager idProject={id} history={history}></EditProjectManager>
        </div>
    );
};

export default withRouter(ViewProject);
