import {SzBox, SzIcon, SzTypographie} from "@suezenv/react-theme-components";
import React, {useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {contactLabel, formatDateTime, zacContactLabel} from "../../../main/utils";
import NotificationService from "../../services/NotificationService";
import {notificationAction} from "../../store/actions";
import {patternLink} from "../../store/constants";
import NotificationProjectAttachement from "./NotificationProjectAttachement";

interface INotificationDetail {
    id?: any;
    name?: any;
    address?: any;
    hold?: any;
    originalFileName?: any;
    photoFile?: any;
    contract?: any;
    project?: any;
    zac?: any;
    url?: any;
    mainContact?: any;
    contacts?: any;
    type?: any;
}

interface IInitState {
    openMenuId: string;
}

const NotificationDetail = (props: any) => {
    const {t} = useTranslation();
    const {notifications, history, setStats} = props;
    const initState: IInitState = {openMenuId: ""};
    const [state, setState] = useState(initState);

    const goToProjectView = (notificationId: string, data: any, type: string) => {
        NotificationService.readNotifications(data.contract.id, [notificationId]).then(() => {
            NotificationService.getNotificationsStats(data.contract.id).then((response: any) => {
                setStats(response.data);
            });
        });
        if (type !== "deletion") {
            history.push(`/view/project/${data.id}`);
        }
    };

    const renderAttribute = (label: string, value: any, isNotText = false) => {
        return (
            <div className="col row p-0">
                <div className="col-4">
                    <label className="font-weight-bold">{t(`notification_list.${label}`)}</label>
                </div>
                <div className="col-8">
                    {!isNotText ?
                        <SzTypographie variant="text">{value ? value : "-"}</SzTypographie> : value ? value : "-"}
                </div>
            </div>
        );
    };

    const renderContacts = (data: INotificationDetail) => {
        return data.mainContact || (data.contacts && data.contacts.length) ? (
            <div className="pb-2">
                {data.mainContact && (
                    <div className="mb-1">
                        <SzTypographie className="d-inline" variant="text">
                            {contactLabel(data.mainContact)}
                        </SzTypographie>
                        <span title={t("notification_list.contact")}>
            <SzIcon icon="people-man-1" className="h6 pl-2" variant="line"/>
            </span>
                    </div>
                )}
                {data.contacts.map((contact: any) => (
                    <SzTypographie key={contact.id} className="mb-1" variant="text">
                        {contactLabel(contact)}
                    </SzTypographie>
                ))}
            </div>
        ) : (
            ""
        );
    };

    const renderZac = (data: INotificationDetail) => {
        return data.zac ? (
            <div className="pb-2">
                <div className="mb-1">
                    <SzTypographie className="d-inline" variant="text">
                        {zacContactLabel(data.zac)}
                    </SzTypographie>
                </div>
            </div>
        ) : (
            "-"
        );
    };
    const renderUrl = (data: INotificationDetail) => {
        let url = data.url;
        if (!patternLink.test(url)) {
            url = "http://" + url;
        }
        return data.url ? <a className="cursor-pointer" href={url} target="_blank"  rel="noopener noreferrer"> {data.url} </a> : "-";
    };
    const renderNotificationInfo = (notification: { id?: string; notificationDate: any; previousData?: INotificationDetail; status?: any; }) => {
        return (
            <>
                <SzTypographie>{formatDateTime(notification.notificationDate)}</SzTypographie>
            </>
        );
    };

    const renderMenu = (notification: any) => {
        return <div className="row project-notification-menu">
            {state.openMenuId === notification.id && (
                <div className="open bg-light">
                      <span title={t("details:project.view")}>
                        <SzIcon
                            onClick={() => goToProjectView(notification.id, notification.currentData, notification.type)}
                            className="cursor-pointer m-2"
                            variant="line"
                            icon="view-1"
                        />
                      </span>
                </div>
            )}
            <div title={t("contact.header.menu")}
                 className={state.openMenuId === notification.id ? `open ml-1 bg-light` : ``}>
                <SzIcon
                    onClick={() => setState({
                        ...state,
                        openMenuId: (state.openMenuId === notification.id) ? "" : notification.id,
                    })}
                    className="cursor-pointer"
                    variant="line"
                    icon="navigation-menu-vertical"
                />
            </div>
        </div>;
    };
    return (
        <SzBox className="notification-project">
            {notifications && notifications.length > 0
                ? notifications.map(
                    (notification: {
                        id: string;
                        notificationDate: any;
                        previousData: INotificationDetail;
                        currentData: INotificationDetail;
                        type: string;
                    }) => {
                        if (!notification.currentData || ! notification.currentData.name || notification.currentData.name === "") {
                            notification.currentData = notification.previousData;
                        }
                        return (
                            <div key={notification.id}
                                 className="row m-3  align-items-center notification-project_content">
                                {renderMenu(notification)}
                                <NotificationProjectAttachement data={notification.currentData}/>
                                <div className="col-8 project-content">
                                    <div className="row pb-3 pt-2">
                                        <div
                                            className="col-8 pt-2 pb-2 notification-project_title cursor-pointer"
                                            onClick={() => goToProjectView(notification.id, notification.currentData, notification.type)}
                                        >
                                            <h3>{notification.currentData.name}</h3>
                                        </div>
                                        <div className="col-4">{renderNotificationInfo(notification)}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            {renderAttribute("address", notification.currentData.address)}
                                            {renderAttribute("contact", renderContacts(notification.currentData), true)}
                                            {renderAttribute("hold", `${new Intl.NumberFormat().format(notification.currentData.hold)} m²`)}
                                        </div>
                                        <div className="col-6">
                                            {renderAttribute("zac", renderZac(notification.currentData), true)}
                                            {notification.currentData.type &&
                                            renderAttribute("type", t(`notification_list.${notification.currentData.type.code}`))}
                                            {renderAttribute("url", renderUrl(notification.currentData), true)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    },
                )
                : <div className="row m-3 notification-project_content justify-content-center ">
                    <SzTypographie className="p-2">{t("notification_list.noData")}</SzTypographie>
                </div>
            }
        </SzBox>
    );
};

const mapDispatchToProps = {
    setStats: notificationAction.setStats,
};

const mapStateToProps = (state: any) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationDetail));
