import Axios from "axios";
import {store} from "../../main/store";
import {Constants} from "../../main/store/constants";

const MAP_GEO_CODE_API_URL = "https://api-adresse.data.gouv.fr/search/?q=";
const MAP_REVERSE_API_URL = "https://api-adresse.data.gouv.fr/reverse/?lat=";
const CancelToken = Axios.CancelToken;
let second = new Date().getSeconds();
let cancel: any;

export class MapGeoCodeService {
    public static autoCompleteAddress(address: string) {
        const items: any = [];
        const currentSecond = new Date().getSeconds();
        if (currentSecond !== second) {
            second = currentSecond;
            cancel && cancel();
            return Axios.get(`${MAP_GEO_CODE_API_URL}${address}`, {
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
            }).then((response) => {
                response.data.features.map((value: any): any => {
                    const item = {
                        lat: value.geometry.coordinates[1],
                        lng: value.geometry.coordinates[0],
                        address: value.properties.label,
                        houseNumber: value.properties.housenumber,
                        street: value.properties.street ? value.properties.street : value.properties.name,
                        postcode: value.properties.postcode,
                        city: value.properties.city,
                    };
                    items.push(item);

                    return items;
                });

                return items;

            }).catch((error) => {
            });
        } else {
            return new Promise((resolve) => {
                resolve(items);
            });
        }
    }

    public static cordinatesToAddress(lat: any, lng: any) {
        store.dispatch({
            payload: true,
            type: Constants.SET_LOADING,
        });
        return Axios.get(`${MAP_REVERSE_API_URL}&lat=${lat}&lon=${lng}`)
            .then(function (response) {
                store.dispatch({
                    payload: false,
                    type: Constants.SET_LOADING,
                });
                if (0 !== response.data.features.length) {
                    const {properties} = response.data.features[0];
                    const {city, housenumber, label, postcode, street} = properties;
                    return {
                        address: label,
                        city: city,
                        street: street,
                        number: housenumber,
                        postcode: postcode,
                        latitude: lat,
                        longitude: lng,
                    };
                }


                return {
                    address: "",
                    city: "",
                    street: "",
                    number: "",
                    postcode: "",
                    latitude: lat,
                    longitude: lng,
                };


            }).catch(() => {
                store.dispatch({
                    payload: false,
                    type: Constants.SET_LOADING,
                });
            })
    }
}
