import { SzAlert, SzBox, SzButton, SzInput, SzSelect } from "@suezenv/react-theme-components";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {getContractId} from "../../../main/utils";
import robotService from "../../services/RobotService";
import { potentialProjectAction } from "../../store/actions";
import { getFormatedForm } from "../models";
import DataSourceModel from "../models/DataSourceModel";

interface IInitialState {
    label: string;
    url: string;
    level: { value: string, label: string };
    errors: boolean;
    isValid: boolean;
}
interface IDataSourceForm {
    cancel: () => void;
}
const DataSourceForm = (props: any) => {
    const { t } = useTranslation();
    const initialState: IInitialState = {
        label: "",
        url: "",
        level: { value: "1", label: "1" },
        errors: false,
        isValid: false,
    };
    const [state, setState] = useState(initialState);
    const [schema] = getFormatedForm(DataSourceModel);
    const { label, url, level }: any = DataSourceModel;

    const createDataSource = () => {

        const contractId = getContractId(props.user);
        if (contractId) {
            robotService.createDataSource(contractId, state.label, state.url, Number(state.level.value)).then(() => {
                props.cancel();
                robotService.getDataSources(contractId).then((response: any) => {
                    props.setDataSourcesList(response.data);
                });
            });
        }
    };

    return (
        <>
            {state.errors &&
                <SzAlert variant="danger">
                    {t("form∂s:An_error_has_occurred")}
                </SzAlert>
            }
            <Formik
                validationSchema={schema}
                onSubmit={() => { }}
                initialValues={state}
                validateOnBlur={false}
                validateOnChange={false}
            >

                {({
                    submitForm,
                    handleChange,
                    errors,
                    setFieldError,
                    setErrors,
                }) => {

                    schema.isValid(state).then((isValidForm: any) => {
                        if (isValidForm !== state.isValid) {
                            setErrors({});
                            setState({ ...state, isValid: isValidForm });
                        }
                    });
                    const changeHandle = (e: any) => {
                        setState({ ...state, [e.target.name]: e.target.value });
                    };
                    const selectChangeHandle = (option: any, event: any) => {
                        setState({ ...state, [event.name]: option });
                    };

                    const customHandleBlur = (e: any) => {
                        validateTarget(e.target.name, e.target.value);
                    };
                    const validateTarget = (targetName: string, value: string) => {
                        schema
                            .validateAt(targetName, { ...state, [targetName]: value })
                            .then(() => {
                                const newErrors = { ...errors };
                                deleteFromObject(targetName, newErrors);
                                setErrors(newErrors);
                            })
                            .catch((error: any) => {
                                const { path, message } = error;
                                setFieldError(path, message);
                            });
                    };

                    const deleteFromObject = (toDeleteKey: string, object: any) => {
                        for (const index in object) {
                            if (index === toDeleteKey) {
                                delete object[index];
                            }
                        }
                    };
                    return (
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (!state.isValid) {
                                submitForm();
                            } else {
                                createDataSource();
                            }
                        }}>
                            <SzBox className="row box create-data-source" tag="div">
                                <div className="col">
                                    <div className={`pb-0 ${label.required ? "required-div" : ""}`}>
                                        <SzInput
                                            className="mb-0"
                                            onChange={changeHandle}
                                            onBlur={customHandleBlur}
                                            label={t(label.label)}
                                            name={label.name}
                                            required={label.required}
                                            placeholder={t(label.placeholder)}
                                            type={label.type}
                                            value={state.label}
                                        />
                                        {errors.label && (
                                            <SzAlert variant="danger"><>{t(`forms:${errors.label}`)}</>
                                            </SzAlert>
                                        )}
                                    </div>
                                    <div className={`pb-0 ${url.required? "required-div" : ""}`}>
                                        <SzInput
                                            className="mb-0"
                                            onChange={changeHandle}
                                            onBlur={customHandleBlur}
                                            label={t(url.label)}
                                            name={url.name}
                                            required={url.required}
                                            placeholder={t(url.placeholder)}
                                            type={url.type}
                                            value={state.url}
                                        />
                                        {errors.url && (
                                            <SzAlert variant="danger"><>{t(`forms:${errors.url}`)}</>
                                            </SzAlert>
                                        )}
                                    </div>
                                    <div className="pb-0 input_group">
                                        <label
                                            className="sz-label-input position-relative d-block required">{t(level.label)}</label>
                                        <SzSelect
                                            className="mb-1"
                                            onChange={selectChangeHandle}
                                            label={t(level.label)}
                                            name={level.name}
                                            required={true}
                                            placeholder={t(level.placeholder)}
                                            options={[
                                                { value: "1", label: "1" },
                                                { value: "2", label: "2" },
                                            ]}
                                            value={state.level}
                                        />
                                        {errors.level && (
                                            <SzAlert variant="danger"><>{t(`forms:${errors.level}`)}</>
                                            </SzAlert>
                                        )}
                                    </div>
                                </div>
                            </SzBox>
                            <div className="row form-actions">
                                <div className="col-4 offset-2">
                                    <SzButton variant="secondary"
                                        onClick={() => props.cancel()}>{t("forms:dataSource.cancel")}</SzButton>
                                </div>
                                <div className="col-4">
                                    <SzButton type="submit" isDisabled={!state.isValid}>
                                        {t("forms:dataSource.add")}
                                    </SzButton>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </>);
}

const mapDispatchToProps = {
    setDataSourcesList: potentialProjectAction.setDataSourcesList,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        listDataSources: state.potentialProject.listDataSources,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DataSourceForm);
