import { SzIcon } from "@suezenv/react-theme-components";
import React from "react";
import * as Yup from "yup";
import { holdRegex } from "../../store/constants";

export default {
  name: {
    schema: Yup.string()
      .trim()
      .required("required_validation"),
    placeholder: "",
    label: "forms:project.name",
    name: "name",
    type: "text",
    required: true,
  },
  type: {
    schema: Yup.string().required("required_validation"),
    placeholder: "forms:project.select_type",
    label: "forms:project.type",
    name: "type",
    type: "select",
    required: true,
  },
  status: {
    schema: Yup.string().required("required_validation"),
    placeholder: "forms:project.select_status",
    label: "forms:project.status",
    name: "status",
    type: "select",
    required: true,
  },
  address: {
    schema: Yup.string()
      .trim()
      .required("required_validation"),
    placeholder: "",
    label: "forms:project.address",
    name: "address",
    type: "text",
    icon: <SzIcon icon="search" variant="line" />,
    required: true,
  },
  city: {
    schema: Yup.string().required("required_validation"),
    placeholder: "",
    label: "forms:project.city",
    icon: "",
    name: "city",
    type: "text",
    required: true,
  },
  street: {
    schema: Yup.string().required("required_validation"),
    placeholder: "",
    label: "forms:project.street",
    icon: "",
    name: "street",
    type: "text",
    required: true,
  },
  number: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.street_Number",
    icon: "",
    name: "number",
    type: "text",
    required: false,
  },
  latitude: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.lat",
    icon: "",
    name: "latitude",
    type: "text",
    required: false,
  },
  longitude: {
    schema: Yup.string(),
    placeholder: "forms:project.long",
    label: "forms:project.long",
    icon: "",
    name: "longitude",
    type: "text",
    required: false,
  },
  searchContact: {
    placeholder: "",
    label: "forms:project.contact",
    name: "contact",
    type: "text",
    required: false,
    icon: <SzIcon icon="search" variant="line" />,
  },
  mainContact: {
    placeholder: "",
    label: "forms:project.contact",
    name: "contact",
    type: "text",
    required: false,
    icon: <SzIcon icon="pencil-write" variant="line" />,
  },
  hold: {
    schema: Yup.string().matches(holdRegex, "not_valid_hold"),
    placeholder: "",
    label: "forms:project.hold",
    name: "hold",
    type: "text",
    required: false,
  },
  progress: {
    schema: Yup.string(),
    placeholder: "forms:project.select_progress",
    label: "forms:project.progress",
    name: "progress",
    type: "select",
    required: false,
  },
  deliveryDate: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.delivery_date",
    name: "deliveryDate",
    type: "date",
    required: false,
  },
  searchZac: {
    placeholder: "",
    label: "forms:project.zac.name",
    name: "zac",
    type: "text",
    required: false,
    icon: <SzIcon icon="search" variant="line" />,
  },
  zac: {
    schema: Yup.string().when(["isZac"], {
      is: (isZac: boolean) => isZac === true,
      then: Yup.string().required("required_validation"),
    }),
    placeholder: "",
    label: "forms:project.zac.name",
    name: "zac",
    type: "text",
    required: false,
    icon: <SzIcon icon="pencil-write" variant="line" />,
  },
  isZac: {
    schema: Yup.boolean(),
    placeholder: "",
    label: "forms:project.zac.name",
    name: "isZac",
    type: "radio",
    options: [
      { label: "Non", value: false },
      { label: "Oui", value: true },
    ],
    required: true,
  },
  url: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.url",
    name: "url",
    type: "text",
    required: false,
  },
  pcDate: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.pc_date",
    name: "pcDate",
    type: "date",
    required: false,
  },
  pcDeposit: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.pc_deposit",
    name: "pcDeposit",
    type: "date",
    required: false,
  },
  pcNumber: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.pc_number",
    name: "pcNumber",
    type: "text",
    required: false,
  },
  dateSetUpPermitZac: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.zac.dateSetUpPermit",
    name: "dateSetUpPermitZac",
    type: "date",
    required: false,
  },
  comment: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:project.comment",
    name: "comment",
    type: "text",
    required: false,
  },
};
