import { SzTable } from "@suezenv/react-theme-components";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import RobotHeader from "../../components/Robot/RobotHeader";

class SourcesListManager extends React.Component<any> {
    public state = {
        sort: { field: "url", direction: "asc" },
        columns: [],
    };
    public componentDidMount() {
        this.initTable();
    }
    public componentDidUpdate(prevProps: any) {
        if (this.props.user.id !== prevProps.user.id) {
            if (this.props.listPotentialProjects.length === 0) {
                this.props.getPotentialProjectList();
            }
        }
        if (this.props.sortData.field !== prevProps.sortData.field || this.props.sortData.direction !== prevProps.sortData.direction) {
            this.props.getPotentialProjectList();
        }
    }
    public initTable() {
        if (this.props.listPotentialProjects.length === 0) {
            this.props.getPotentialProjectList();
        }
        this.setColumn(this.props.sortData.field, this.props.sortData.direction);
    }

    public cellDetailsFormatter = (value: any, row: any) => {
        return (
            <>
                <div className="cell-clickable"
                    style={{
                        textAlign: "center",
                        cursor: "pointer",
                        lineHeight: "normal",
                        fontSize: "22px",
                        color: "#A3D049",
                    }}>
                    <div className="text-primary-brand col-auto text-right p-2 align-self-center text-center" onClick={() => {
                        this.props.onRowClick(row);
                    }}>
                        <span className=" sz-aside__item--icon material-icons">receipt</span>
                    </div>
                </div>

            </>
        );
    }
    public cellUrlFormatter = (value: any, row: any) => {
        const { t } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-auto">
                        <label>{t("lists:potentialProject.data-source")}</label>
                    </div>
                    <div className="col">{row.dataSourceLabel === "UNKNOWN" ? t("lists:potentialProject.unknown") : row.dataSourceLabel}</div>
                </div>
                <div className="row">
                    <div className="col">
                        <a href={value} target="_blank" rel="noopener noreferrer">
                            {value}
                        </a></div>
                </div>

            </div>
        );
    }
    public columnJson(
      classes: string, dataField: string, text: string, sortDataField: string,
      sortDirection: string, canSort = true, cellFormatter: any = null,
    ) {
        const { t } = this.props;
        const sortClass = dataField === sortDataField ? `order-${sortDirection}` : "order-th";

        return {
            classes,
            dataField,
            text: t(text),
            formatter: cellFormatter,
            sort: false,
            headerClasses: canSort ? `order-row ${sortClass} ${dataField}` : dataField,
            headerEvents: {
                onClick: canSort ? this.sortByColumn.bind(this) : () => {
                },
            },
        };
    }

    public compareObjects(projectA: any, projectB: any, key: string, direction = "asc") {
        const obj1: string = projectA[key].toLowerCase().trim();
        const obj2: string = projectB[key].toLowerCase().trim();

        return obj1.localeCompare(obj2) * (direction === "asc" ? 1 : -1);
    }

    public sortByColumn(e: any, column: any) {
        const { direction } = this.props.sortData;
        const sortDirection = ((direction && direction === "asc") ? "desc" : "asc");
        this.props.setSorData({ direction: sortDirection, field: column.dataField });
        this.setColumn(column.dataField, sortDirection);
        this.props.setSorData({ direction: sortDirection, field: column.dataField });
        return true;
    }
    public setColumn(dataField = "", sort = "") {
        const { t } = this.props;
        this.setState({
            columns: [
                this.columnJson("url", "url", t("lists:potentialProject.link"), dataField, sort, true, this.cellUrlFormatter),
                this.columnJson("probability px-4", "probability", t("lists:potentialProject.probability"), dataField, sort),
                this.columnJson("created-at", "createdAt", t("lists:potentialProject.createdAt"), dataField, sort),
                this.columnJson("action", "action", "", dataField, sort, false, this.cellDetailsFormatter),
            ],
        });
    }
    public render() {
        const { t, listPotentialProjects } = this.props;
        return (
            <>
                <RobotHeader countProjects={listPotentialProjects.length} title={t("potentialProject.title")}></RobotHeader>
                {
                    this.state.columns.length > 0 && <div className="potential-project-table bg-white row m-2 ">
                        <SzTable
                            columns={this.state.columns}
                            data={this.props.listPotentialProjects}
                            keyField="id"
                            className="w-100"
                        />
                    </div>
                }
            </>
        );
    }
}
const mapDispatchToProps = {};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        listPotentialProjects: state.potentialProject.listPotentialProjects,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(SourcesListManager));
