import { SzDropDown, SzHeader, SzIcon } from "@suezenv/react-theme-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {useHistory} from "react-router";
import { bindActionCreators } from "redux";
import {servicesCodes} from "../../../user/store/constants";
import {projectAction, UserActions} from "../../store/actions";
import {hasService} from "../../utils";
import { menuItems } from "../Container";
import NotificationHeader from "../Utils/NotificationHeader";
import Switch from "../Utils/switch";
import "./header.scss";

interface IHeadersProps {
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  connected: boolean;
  email: string;
  logout: any;
  menuIsOpen: boolean;
  currentTab?: keyof typeof menuItems;
  displayMap: boolean;
  toggleDisplayMap: () => {type: string};
  user: any;
  setDisplayMap: (displayMap: boolean) => {type: string};
}

function Header(props: IHeadersProps) {
  const {handleOnClick, connected, logout, menuIsOpen, currentTab, displayMap, toggleDisplayMap, setDisplayMap, user} = props;
  const history = useHistory();
  const { t } = useTranslation();
  const menuOpt = [
    {
      content: "DÉCONNEXION",
      handleClick: () => logout(),
      icon: "logout-2",
    },
  ];

  const logoLink = (
    <a className="argus-header__brand px-3" href="#!">
      <img src={"/argus/img/logo-argus.svg"} alt="Logo Suez" height={30}/>
    </a>
  );

  const headerContent = () => {
    if (currentTab) {
      switch (currentTab) {
        case menuItems.home:
        default:
          return <div className={"argus-header__search-form border-right"}></div>;
      }
    }
    return "";
  };

  const userMenu = (
      <SzDropDown className="col-4" value={<SzIcon
          icon="people-man-1"
          variant="line"
          className="mr-2 align-self-center text-right"
      />}>
        {menuOpt.map(({ handleClick, icon, content }, index) => {
          return (
              <SzDropDown.Item key={index} onClick={handleClick}>
                {icon && (
                    <SzIcon
                        icon={icon}
                        variant="line"
                        className="mr-2 align-self-center text-right"
                    />
                )}
                {content}
              </SzDropDown.Item>
          );
        })}
      </SzDropDown>
  );

  const switchChangeHandle = () => {
    toggleDisplayMap();
  };

  const onIconClickHandle = (displayMap: boolean) => {
    setDisplayMap(displayMap);
  };
  if (!connected) {
    return (
      <>
        <SzHeader className="argus-header d-flex align-items-stretch text-secondary">
          {logoLink}
          <h1 className="argus-header__brand-title px-3">ARGUS</h1>
        </SzHeader>
      </>
    );
  } else {
    return (
      <>
        <SzHeader className="argus-header d-flex text-primary-dark ">
          <div
            className={`argus-header__menu-icon text-secondary
              ${menuIsOpen ? "menuIsOpen" : "menuIsClose"}`}
            onClick={handleOnClick}
          >
            <div className={"sliding-menu"}>
              <span className={"item-1"} />
              <span className={"item-2"} />
              <span className={"item-3"} />
              <span className={"item-4"} />
              <span className={"item-5"} />
              <span className={"item-6"} />
            </div>
            <div className={"argus-header__menu-icon__text"}>
              <p>{t("menu")}</p>
            </div>
          </div>
          {headerContent()}

          <div
            className="argus-header signin d-flex align-items-center ml-auto
                  justify-content-around text-uppercase font-weight-bold px-4 border-left"
          >
            <Switch onChangeHandle={switchChangeHandle}
                    secondIconClass="border-right"
                    firstIcon="maps-pin-1"
                    secondIcon="layout-bullets"
                    onFirstIconClickHandle={() => onIconClickHandle(true)}
                    onSecondIconClickHandle={() => onIconClickHandle(false)}
                    checked={!displayMap}/>
            {hasService(servicesCodes.notification, user) &&
            <NotificationHeader history={history}/>
            }
            {userMenu}
            {logoLink}
          </div>
        </SzHeader>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.logged,
    user: state.user,
    email: state.user.email,
    displayMap: state.project.displayMap,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(UserActions, dispatch),
  ...bindActionCreators(projectAction, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
