import { Constants } from "../constants";
export default {
  setProjectTypes(payload: any) {
    return {
      payload,
      type: Constants.SET_PROJECT_TYPE,
    };
  },
  setProjectStatus(payload: any) {
    return {
      payload,
      type: Constants.SET_PROJECT_STATUS,
    };
  },
  setProjectProgress(payload: any) {
    return {
      payload,
      type: Constants.SET_PROJECT_PROGRESS,
    };
  },
  setContactTypes(payload: any) {
    return {
      payload,
      type: Constants.SET_CONTACT_TYPES,
    };
  },
};
