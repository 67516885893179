
import instanceRequest, { Request } from "../../main/services/Request";

class ReferentialService {

    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getTypes(contractId: string) {
        const url = `/api/${contractId}/referential/type`;

        return this.axiosService.get(url, false).then((response: any) => {
            return response.data;
        });
    }
    public getStatus(contractId: string) {
        const url = `/api/${contractId}/referential/status`;

        return this.axiosService.get(url, false).then((response: any) => {
            return response.data;
        });
    }

    public getContactTypes(contractId: string) {
        const url = `/api/${contractId}/referential/contact-type`;

        return this.axiosService.get(url, false).then((response: any) => {
            return response.data;
        });
    }
}

export default new ReferentialService(instanceRequest);
