import instanceRequest, { Request } from "../../main/services/Request";
import {formatDateWithoutTimezone} from "../../main/utils";
import {PAGINATION_HEADERS} from "../store/constants";

class ProjectService {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public saveProject(contractId: string, values: any, id: any = null) {
    const body = {
      name: values.name,
      type: values.type.value,
      status: values.status.value,
      address: values.address,
      city: values.city,
      street: values.street,
      number: values.number,
      latitude: values.latitude,
      longitude: values.longitude,
      mainContact: values.mainContact ? values.mainContact.id : "",
      contacts: values.contacts.map((contact: any) => contact.id),
      hold: values.hold,
      holdGeoCoordinates: values.holdGeoCoordinates,
      zac: values.zac !== {} ? values.zac.id : null,
      url: values.url,
      deliveryDate: formatDateWithoutTimezone(values.deliveryDate),
      pcDate: formatDateWithoutTimezone(values.pcDate),
      pcDeposit: formatDateWithoutTimezone(values.pcDeposit),
      pcNumber: values.pcNumber,
      dateSetUpPermitZac: formatDateWithoutTimezone(values.dateSetUpPermitZac),
      otherAttachments: values.otherAttachments,
      photoFile: values.photoFile,
      stoppedFile: values.stoppedFile,
      authorizationFile: values.authorizationFile,
      contract: contractId,
      attachmentsToDelete: values.attachmentsToDelete,
      comment: values.comment,
    };
    if (id) {
      const url = `/api/${contractId}/project/${id}`;
      return this.axiosService.put(url, body, false);
    }

    const url = `/api/${contractId}/project`;
    return this.axiosService.post(url, body, false);
  }

  public mergeProject(contractId: string, values: any, idProject1: string, idProject2: string) {
    const body = {
      name: values.name,
      type: values.type.value,
      status: values.status.value,
      address: values.address,
      city: values.city,
      street: values.street,
      number: values.number,
      latitude: values.latitude,
      longitude: values.longitude,
      mainContact: values.mainContact ? values.mainContact.id : "",
      contacts: values.contacts.map((contact: any) => contact.id),
      hold: values.hold,
      holdGeoCoordinates: values.holdGeoCoordinates,
      zac: values.isZac === "true" || values.isZac === true ? values.zac.id : null,
      url: values.url,
      deliveryDate: formatDateWithoutTimezone(values.deliveryDate),
      pcDate: formatDateWithoutTimezone(values.pcDate),
      pcNumber: values.pcNumber,
      dateSetUpPermitZac: formatDateWithoutTimezone(values.dateSetUpPermitZac),
      otherAttachments: values.otherAttachments,
      photoFile: values.photoFile,
      stoppedFile: values.stoppedFile,
      authorizationFile: values.authorizationFile,
      contract: contractId,
      attachmentsToDelete: values.attachmentsToDelete,
      comment: values.comment,
    };
    const url = `/api/${contractId}/merge/project/${idProject1}/${idProject2}`;

    return this.axiosService.post(url, body, false);
  }

  public getProjects(contractId: string, filters = null, search = "", page = 1, sortField = "name", sortDirection = "asc", itemsPerPage = 30) {
    if (this.hasFilterActivated(filters)) {
      let url = `/api/${contractId}/project`;

      let sort = this.formatSort(sortField, sortDirection);
      let filtersQuery = "";
      if (filters) {
        filtersQuery = this.formatFilters(filters);
      }

      // column sort is address use city and street and number
      if (sortField === "address") {
        const citySort = this.formatSort("city", sortDirection);
        const streetSort = this.formatSort("street", sortDirection);
        const numberSort = this.formatSort("number", sortDirection);
        sort = `${citySort}&${streetSort}&${numberSort}`;
      }
       // column sort is address use city and street and number
      if (sortField === "contact") {
        const fullNameSort = this.formatSort("fullName", sortDirection);
        sort = `${fullNameSort}`;
      }
      url = `${url}?currentPage=${page}&itemsPerPage=${itemsPerPage}&sortDirection=${sortDirection}&${sort}&${filtersQuery}&search=${search}`;
      return this.axiosService.get(url, false, false).then((response) => {
        return {
          pagination: this.getPaginationInfo(response.headers),
          data: response.data,
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({
          pagination: [],
          data: [],
        });
      });
    }
  }

  public getPaginationInfo(headers: any): any {
    const pagination: any = [];
    Object.entries(headers).map((header: any) => {
      if (PAGINATION_HEADERS.includes(header[0])) {
        pagination[header[0]] = header[1];
      }
      return pagination;
    });

    return pagination;
  }

  /**
   *
   * @param contractId
   * @param filters
   * @param search
   * @param centerRadiusLat
   * @param centerRadiusLong
   * @param format
   */
  public getAllProjects(contractId: string, filters = null, search = "", centerRadiusLat: number | null = null, centerRadiusLong: number | null = null, format: string = "json") {
    if (this.hasFilterActivated(filters)) {
    let filtersQuery = "";
    if (filters) {
        filtersQuery = this.formatFilters(filters);
      }
    const url = `/api/${contractId}/project?withPagination=0 & ${filtersQuery} &search=${search}&latitude=${centerRadiusLat}&longitude=${centerRadiusLong}&format=${format}`;
    if (format === "json") {
        return this.axiosService.get(url, false, false).then((response) => {
          return response;
        });
      } else {
        return this.axiosService.getBlob(url, false, false).then((response) => {
          return response;
        });
      }
    } else {
      return new Promise((resolve, reject) => {
          resolve({
            data: [],
          });
      });
    }
  }

  public getProject(contractId: string, projectId: string) {
    const url = `/api/${contractId}/project/${projectId}`;
    return this.axiosService.get(url, false, false).then((response) => {
      return response;
    });
  }
  public getSubscription(contractId: string, projectId: string) {
    const url = `/api/${contractId}/project/${projectId}/subscription`;
    return this.axiosService.get(url, false, false).then((response) => {
      return response;
    });
  }
  public getSubscriptionSubscribe(contractId: string, projectId: string) {
    const url = `/api/${contractId}/project/${projectId}/subscription/subscribe`;
    return this.axiosService.post(url, [], false);
  }
  public getSubscriptionUnSubscribe(contractId: string, projectId: string) {
    const url = `/api/${contractId}/project/${projectId}/subscription/unsubscribe`;
    return this.axiosService.post(url, [], false);
  }
  public archivedProject(contractId: string, projectId: string) {
    const url = `/api/${contractId}/project/${projectId}`;
    return this.axiosService.delete(url, false).then((response) => {
      return response;
    });
  }

  private formatFilters(filters: any) {
    let filterQuery = `showSubscriptionProjects=${filters.showSubscriptionProjects ? 1 : 0}`;

    for (const [key, value] of Object.entries(filters.status)) {
      if (value === true) {
        filterQuery = filterQuery ? `${filterQuery}&filterStatus[]=${key}` : `filterStatus[]=${key}`;
      }
    }
    for (const [key, value] of Object.entries(filters.type)) {
      if (value === true) {
        filterQuery = filterQuery ? `${filterQuery}&filterType[]=${key}` : `filterType[]=${key}`;
      }
    }
    return filterQuery;
  }

  private hasFilterActivated(filtersParam: any) {
    if (filtersParam) {
      const oneStatusActivated = Object.values(filtersParam.status).find((element) => element === true);
      const oneTypeActivated = Object.values(filtersParam.type).find((element) => element === true);

      return oneTypeActivated && oneStatusActivated;
    }

    return  true;
  }
  private formatSort(sortField: string, sortDirection: string): string {
    return `sorterFields[]=${encodeURI(`${sortField}=${sortDirection}`)}`;
  }
}

export default new ProjectService(instanceRequest);
