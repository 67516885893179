import { Constants } from "../constants";

const initialState: any = {
  listContacts: {
    pagination: [],
    data: [],
  },
  contactDetail:null
};

const ContactReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.SET_CONTACT_LIST:
      return {
        ...state,
        listContacts: action.payload ,
      };
    case Constants.SET_CONTACT_DETAIL:
      return {
        ...state,
        contactDetail: action.payload ,
      };
    default:
      return state;
  }
};

export default ContactReducer;
