import { Authenticate as AuthenticateService } from "../../services/Authenticate";
import { Constants } from "../constants";
import { AppActions } from "../types";

export default {
  login(logged: boolean): AppActions {
    return {
      payload: logged,
      type: Constants.LOG_IN,
    };
  },
  logout(logged: boolean): any {
    AuthenticateService.logout();
    return {
      payload: logged,
      type: Constants.LOG_OUT,
    };
  },
};
