import * as Yup from "yup";
import { patternLink } from "../../store/constants";

export default {
    label: {
        schema: Yup.string(),
        placeholder: "",
        label: "forms:dataSource.label",
        name: "label",
        type: "text",
        required: true,
    },
    url: {
        schema: Yup.string().matches(patternLink, "dataSource.not_valid_url"),
        placeholder: "",
        label: "forms:dataSource.url",
        name: "url",
        type: "text",
        required: true,
    },
    level: {
        schema: Yup.string(),
        placeholder: "",
        label: "forms:dataSource.level",
        name: "level",
        type: "select",
        required: true,
    },
};
