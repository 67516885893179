
import React from "react";
import {useHistory} from "react-router";
import MergeProjectManager from "../../../projectManagement/manager/MergeProjectManager";

const MergeProject = (props: any) => {
    const history = useHistory();
    const { id } = props.match.params;
    const { id2 } = props.match.params;

    return (
        <div className="project-container container-margin-top row">
            <MergeProjectManager
                history={history}
                idProject1={id}
                idProject2={id2}
            ></MergeProjectManager>
        </div>
    );
};

export default MergeProject;
