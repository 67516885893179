import jwt from "jsonwebtoken";
import qs from "qs";
import Request from "../../main/services/Request";
import { store } from "../../main/store";
import {Constants} from "../store/constants";
import { Authenticate as AuthenticateService } from "./Authenticate";

export const isUndefined = (item: string | null) => !item || item === "undefined";
const getToken = () => {
  const param  = qs.parse(window.location.search.substring(1));
  return param.token as string;
};

export class Token {
  public static createArgusTokens() {
    const tokenArgusFromStorage = sessionStorage.getItem("tokenArgusUrba");
    let token: string = getToken();

    if (isUndefined(token) && isUndefined(tokenArgusFromStorage)) {
      localStorage.setItem("redirect-urba", window.location.href);
      AuthenticateService.login();
    } else {
      const redirectHref = localStorage.getItem("redirect-urba");
      localStorage.removeItem("redirect-urba");
      token = !isUndefined(token) ? token : (tokenArgusFromStorage || "");
      this.setSessionsTokens(token);
      this.updateTokensInStore(token);
      if (redirectHref) {
        window.location = redirectHref as any;
      }
    }
  }

  public static checkJWTValidityFromAPI() {
    return Request.checkJWT().then((response) => {
      store.dispatch({
        payload: { ...response.data, logged: true },
        type: Constants.SET_USER_INFO,
      });
      return response.data;
    });
  }

  public static isJwtNotValid() {
    const token = sessionStorage.getItem("tokenArgusUrba");
    const decodedToken = jwt.decode(token, { complete: true });
    // JS timestamp is expressed in milliseconds therefore we truncate it.
    const timestamp = Math.floor(Date.now() / 1000);
    return decodedToken && typeof decodedToken !== "undefined" && decodedToken.payload.exp < timestamp;
  }

  public static async refreshToken() {
    return await Request.refreshToken().then((resf: any) => {
      this.updateTokens(resf.data);
    });
  }

  private static setSessionsTokens(token: string) {
    if (!isUndefined(token)) {
      sessionStorage.setItem("tokenArgusUrba", token);
    }
  }

  private static getSigTokenFromPayload(token: string) {
    const decodedToken = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken !== "undefined") {
      return decodedToken.payload.sigToken;
    }
    return null;
  }

  private static getEmailFromPayload(token: string) {
    const decodedToken = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken !== "undefined") {
      return decodedToken.payload.username;
    }
    return null;
  }

  private static updateTokens(json: any) {
    if (typeof json.code !== "undefined" && json.code === 401) {
      AuthenticateService.logout();
    }

    if (!isUndefined(json.token)) {
      sessionStorage.setItem("tokenArgusUrba", json.token);
      this.updateTokensInStore(json.token);
    }
    if (!isUndefined(json.refresh_token)) {
      sessionStorage.setItem("refreshToken", json.refresh_token);
    }
  }

  private static updateTokensInStore(token: string) {
    store.dispatch({
      payload: true,
      type: Constants.LOG_IN,
    });
  }
}
