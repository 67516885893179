import { Constants } from "../constants";
export default {
  setMapCoordinate(payload: any) {
    return {
      payload,
      type: Constants.SET_MAP_COORDINATE,
    };
  },
  setMapInfo(payload: any) {
    return {
      payload,
      type: Constants.SET_MAP_INFO,
    };
  },
};
