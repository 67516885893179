import React from "react";
import {getContractId} from "../../main/utils";
import MapProjectsManager from "../../map/manager/MapProjectsManager";
import {servicesCodes} from "../../user/store/constants";
import {MapGeoCodeService} from "../services/MapGeoCodeService";
import ProjectService from "../services/ProjectService";
import ReferentialService from "../services/ReferentialService";
import ListProjectManager from "./ListProjectManager";

abstract class  AbstractProjectManager extends React.Component<any> {
    public state = {
        showAddContact: false,
        contractId: "",
        editProjectContacts: false,
        showPin: false,
        synchroPin: false,
        pinProjectLocation: null,
        mapCenter: null,
        isAutoCompleteAction: false,
    };

    public changeSynchroPin() {
        this.setState({synchroPin: !this.state.synchroPin});
    }

    public changeShowPin() {
        this.setState({showPin: !this.state.showPin});
        if (!this.state.showPin) {
            this.setState({showPin: true});
        } else {
            this.setState({showPin: false, synchroPin: false});
        }
    }

    public componentDidUpdate(prevProps: any): void {
        if (!this.state.contractId) {
            this.setReferential();
        }
    }

    public setMapCenterHandle(lat: any, lng: any, isAutoCompleteAction = false) {
        this.props.setMapInfo({center: [lat, lng], zoom: 19});
        this.setState({isAutoCompleteAction});
    }

    public setIsAutoCompleteAction(value: boolean) {
        this.setState({isAutoCompleteAction: value});
    }

    public componentDidMount(): void {
        if (this.props.user.services[servicesCodes.projectManagement] && this.props.user.services[servicesCodes.projectManagement].length) {
            this.setReferential();
        }
    }

    public setDuplicatedProjects(contractId: string, lat: number, long: number, idProject: string|null = null) {
        this.props.setDuplicatedProjects([]);
        ProjectService.getAllProjects(contractId, null, "", lat, long).then((response: any) => {
            // edition mode
            const projects = {...response.data};
            const duplicateProjects: any = [];
            Object.values(projects).forEach((project: any) => {
                const duplicatedProject = {...project[0], distance: project.distance};
                if (idProject && duplicatedProject.id !== idProject) {
                    duplicateProjects.push(duplicatedProject);
                } else if (!idProject) {
                    duplicateProjects.push(duplicatedProject);
                }
            });
            this.props.setDuplicatedProjects(duplicateProjects);
        });
    }

    public setReferential() {
        const contractId = getContractId(this.props.user);
        this.setState({contractId});
        // if dont have service project management
        if ("" === contractId) {
            return this.props.history.push("/");
        }
        this.initProjectTypes(contractId);
        this.initProjectStatus(contractId);
        this.initProjectContactTypes(contractId);
    }

    public initProjectTypes(contractId: string) {
        if (this.props.projectReferential.types.length === 0) {
            ReferentialService.getTypes(contractId).then((data) => {
                this.props.setProjectTypes(data);
            });
        }
    }

    public initProjectStatus(contractId: string) {
        if (this.props.projectReferential.status.length === 0) {
            ReferentialService.getStatus(contractId).then((data) => {
                this.props.setProjectStatus(data);
            });
        }
    }

    public initProjectContactTypes(contractId: string) {
        if (this.props.projectReferential.contactTypes.length === 0) {
            ReferentialService.getContactTypes(contractId).then((data) => {
                this.props.setContactTypes(data);
            });
        }
    }

    public eventPinHandle(lat: any, lng: any) {
        MapGeoCodeService.cordinatesToAddress(lat, lng).then((response: any) => {
            this.setState({pinProjectLocation: {...response}});
        });

    }

    public mapListRender() {
        const { history, displayMap} = this.props;
        return (<div className="col-8 no-padding map">
            {displayMap ? <MapProjectsManager setIsAutoCompleteAction={this.setIsAutoCompleteAction.bind(this)}
                                             isAutoCompleteAction={this.state.isAutoCompleteAction}
                                             eventPinHandle = {this.eventPinHandle.bind(this)}
                                             showPin={this.state.showPin}
                                              synchroPin={this.state.synchroPin}
                                              isEditEnabled={true}></MapProjectsManager> : <ListProjectManager history={history} />}
        </div>);
    }

    public abstract  render(): any;
}

export default AbstractProjectManager;
