import {SzNotification} from "@suezenv/react-theme-components";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import NotificationService from "../../../../projectManagement/services/NotificationService";
import {notificationAction} from "../../../../projectManagement/store/actions";
import {getContractId} from "../../../utils";

interface INotificationHeader {
    notification: any;
    user: any;
    setStats: (stats: any) => void;
    loading: false;
    history: any;
}

// 1 minute
const NOTIFICATION_TIME_OUT = 60000;

class NotificationHeader extends React.Component <INotificationHeader> {
    public state = {isFirstcall: true, isLoading: false};

    public componentDidMount(): void {
        this.refreshStats();
        setInterval(this.refreshStats.bind(this), NOTIFICATION_TIME_OUT);
    }

    public refreshStats() {
        const {user, loading, setStats} = this.props;
        const contractId = getContractId(user);
        if (contractId) {
            // refresh stats notification without loading
            if (!this.state.isLoading && !loading) {
                this.setState({isLoading: true});
                NotificationService.getNotificationsStats(contractId).then((response: any) => {
                    setStats(response.data);
                    this.setState({isLoading: false, isFirstcall: false});
                });
            }
        }
    }

    public render() {
        const {notification, history} = this.props;
        return (
            <>
                <div className="p-2 pr-3 border-right h-100 cursor-pointer"
                     onClick={() => history.push("/notification")}>
                    <SzNotification className="notification-urba"
                                    notifCount={notification.stats.total > 0 ? notification.stats.total : ""}/>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        notification: state.notification,
        loading: state.loading,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(notificationAction, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationHeader);
