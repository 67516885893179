import {SzBox, SzInput} from "@suezenv/react-theme-components";
import {Formik} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import ZacAutoCompleteItemsComponent from "../../components/zacAutoCompleteItemsComponent";
import ZacService from "../../services/ZacService";
import {autocompleteAction} from "../../store/actions";
import {getFormatedForm} from "../models";
import ProjectModel from "../models/ProjectModel";
import {IEditProjectZac} from "./types";

const EditProjectZac = (props: IEditProjectZac) => {
    const NBR_CHAR_START_AUTO_COMPLETE = 3;
    const initialState = {
        zac: {},
        showAddZac: false,
        isZac: false,
        searchZac: "",
    };

    const {zac, setProjectZacHandle, contractId, zacAutocomplete, setZacAutocomplete} = props;
    const [state, setState] = useState({
        ...initialState,
        zac,
        isZac: zac !== "" || (Object.keys(zac).length !== 0),
    });

    const {t} = useTranslation();
    const [schema] = getFormatedForm(ProjectModel);
    const {searchZac}: any = ProjectModel;

    const zacAutoCompleteClickHandle = (
      id: any,
      name: any,
      area: any,
      contact: any,
    ) => {
        setState({
            ...state,
            zac: {id, name, area, contact},
            searchZac: "",
            isZac: true,
        });
        setZacAutocomplete([]);
        setProjectZacHandle({id, name});
    };



    return (<>
        <Formik
            validationSchema={schema}
            onSubmit={() => {
            }}
            initialValues={state}
            validateOnBlur={false}
            validateOnChange={false}
        >

            {({
                  submitForm,
                  handleChange,
                  errors,
                  setFieldError,
                  setErrors,
              }) => {

                const zacAutoCompleteHandle = (e: any) => {
                    const value = e.target.value;
                    setState({...state, searchZac: value});
                    if (e.target.value.length >= NBR_CHAR_START_AUTO_COMPLETE) {
                        ZacService.searchZac(contractId, value).then((data) => {
                            setZacAutocomplete(data);
                        });
                    } else {
                        setZacAutocomplete([]);
                    }
                };

                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}
                  >
                    <SzBox className="row mt-5 mb-4 m-0 box" tag="div">
                      <div className="pb-0 pr-0 input_group col-8">
                        <SzInput
                          className="mb-0"
                          onChange={zacAutoCompleteHandle}
                          label=""
                          name={searchZac.name}
                          required={searchZac.required}
                          placeholder={t(searchZac.placeholder)}
                          type={searchZac.type}
                          value={state.searchZac}
                          icon="search"
                        />
                          {zacAutocomplete && (
                            <ZacAutoCompleteItemsComponent
                              zacAutoComplete={{items: zacAutocomplete}}
                              zacAutoCompleteClickHandle={zacAutoCompleteClickHandle}
                            />
                          )}
                      </div>
                    </SzBox>
                  </form>
                );
            }}
        </Formik>
    </>);
};
const mapStateToProps = (state: any) => {
    return {
        zacAutocomplete: state.autocomplete.zac,
    };
};
const mapDispatchToProps = {
    setZacAutocomplete: autocompleteAction.setZacAutocomplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectZac);
