import {
    SzBox,
    SzButton,
    SzIcon, SzModal,
    SzPagination,
    SzTable,
    SzTypographie,
} from "@suezenv/react-theme-components";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {routes} from "../../config";
import {getContractId} from "../../main/utils";
import SearchComponent from "../components/searchComponent";
import ZacService from "../services/ZacService";
import zacAction from "../store/actions/zacAction";
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT,
    PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT,
} from "../store/constants";

class ListZacManager extends React.Component<any> {

    public state = {
        sort: {field: "name", direction: "asc"},
        columns: [],
        contractId: "",
        page: 1,
        showDelete: false,
        zacToDelete: null,
        search: "",
    };

    public componentDidUpdate(prevProps: any) {
        if (this.props.user.id !== prevProps.user.id) {
            this.initTable();
        }
    }

    public componentDidMount() {

        this.initTable();
    }

    public initTable() {
        if (Object.values(this.props.listZac.data).length === 0) {
            this.setData();
        }
        this.setColumn(this.state.sort.field, this.state.sort.direction);
    }

    public setData(page = 1, sortFiled = "lastName", sortDirection = "asc", query = "") {
        const contractId: string = getContractId(this.props.user);

        if (contractId) {
            ZacService.getZacs(contractId, page, sortFiled, sortDirection, 30, query).then((response) => {
                this.props.setZacList(response);
            });
        }
    }

    public searchHandle(query: string) {
        this.setData(1, this.state.sort.field, this.state.sort.direction, query);
        this.setState({search: query});
    }

    public deleteHandle(zacId: any) {
        const contractId: string = getContractId(this.props.user);
        this.setState({zacToDelete: null, showDelete: false});
        ZacService.archiveZac(contractId, zacId).then(() => {
            ZacService.getZacs(contractId).then((response) => {
                this.props.setZacList(response);
            });
        });
    }

    public editHandle(row: any) {
        const {history} = this.props;
        history.push(`/edit/zac/${row.id}`);
    }

    public getDeleteFormatter = (value: any, row: any) => {
        return (
            <>
                <div className="cell-clickable"
                     style={{
                         textAlign: "center",
                         cursor: "pointer",
                         lineHeight: "normal",
                         fontSize: "22px",
                     }}>
                    <div className="row">
                        <div
                            className="offset-6 col-3 text-primary-brand col-auto text-right p-2 align-self-center text-center"
                            onClick={() => {
                                this.editHandle(row);
                            }}>
                            <SzIcon icon="pencil-write" variant="bold"></SzIcon>
                        </div>

                        <div className="col-3 text-primary-brand col-auto text-right p-2 align-self-center text-center"
                             onClick={() => {
                                 this.setState({showDelete: true, zacToDelete: row.id});
                             }}>
                            <SzIcon icon="bin-1" variant="bold"></SzIcon>
                        </div>
                    </div>
                </div>

            </>
        );
    }

    public setColumn(dataField = "", sort = "") {
        const {t} = this.props;
        this.setState({
            columns: [
                this.columnJson("zacName", "name", t("lists:zac.name"), dataField, sort, true),
                this.columnJson("zacArea", "area", t("lists:zac.area"), dataField, sort),
                this.columnJson("zacContact", "contact", t("lists:zac.contact"), dataField, sort),
                this.columnJson("action", "action", "", dataField, sort, false, this.getDeleteFormatter),
            ],
        });
    }

    public columnJson(classes: string, dataField: string, text: string, sortDataField: string, sortDirection: string, canSort = true, cellFormatter: any = null) {
        const {t} = this.props;
        const sortClass = dataField === sortDataField ? `order-${sortDirection}` : "order-th";
        return {
            classes,
            headerClasses: canSort ? `order-row ${sortClass} ${classes}` : classes,
            dataField,
            text: t(text),
            sort: false,
            formatter: cellFormatter,
            headerEvents: {
                onClick: canSort ? this.sortByColumn.bind(this) : () => {
                },
            },
        };
    }

    public sortByColumn(e: any, column: any) {
        const {direction} = this.state.sort;
        const sortDirection = ((direction && direction === "desc") ? "asc" : "desc");
        this.setState({sort: {direction: sortDirection, field: column.dataField}});
        this.setColumn(column.dataField, sortDirection);
        this.setData(this.state.page, column.dataField, sortDirection, this.state.search);

        return true;
    }

    public getListData(): any {
        const {listZac} = this.props;
        return Object.values(listZac.data).map((item: any) => {
            const contact: any = item.contact ? item.contact.socialReason || (`${item.contact.firstName} ${item.contact.lastName}`) : "-";
            return {
                name: item.name || "-",
                area: item.area || "-",
                id: item.id,
                contact,
            };
        });
    }

    public render() {
        const {listZac, t} = this.props;
        return (
            <>
                {this.state.columns.length && <>

                    { <SzModal
                        size="lg"
                        className="confirmation"
                        title={t("zac_confirmation.title")}
                        show={this.state.showDelete}
                        handleClose={() => this.setState({showDelete: false})}
                    >
                        <SzTypographie className="mb-0 text-center" variant="text">
                            {t("zac_confirmation.text")}
                        </SzTypographie>
                        <SzTypographie className="mb-0 text-center" variant="text">
                            {t("zac_confirmation.text_2")}
                        </SzTypographie>
                        <div className="row justify-content-center confirmation-buttons">
                            <SzButton onClick={() => this.setState({showDelete: false})} className="col-3 mr-3">
                                {t("zac_confirmation.no")}
                            </SzButton>
                            <SzButton onClick={() => this.deleteHandle(this.state.zacToDelete)}
                                      className="col-3 mr-3">
                                {t("zac_confirmation.yes")}
                            </SzButton>
                        </div>
                    </SzModal>
                    }
                    <div className="m-2 bg-white w-100 zac-title">
                        <div
                            className="sz-card-collapse__header sz-left-border position-relative align-items-center m-0 row">
                            <div className=" col-md-3 col-sm-12">
                                <SzBox className="justify-content-around pb-0">
                                    <label className="mb-0 font-weight-bold">{t("zac.title")}</label>
                                </SzBox>
                            </div>
                            <div className=" col-md-4 col-sm-12">
                                <SearchComponent searchHandle={this.searchHandle.bind(this)}/>
                            </div>
                            <div className="col-md-4 col-sm-12 p-0 row align-items-center m-0">
                                <div className="offset-2 col-10">
                                    <div className="row project-menu">
                                        <div className="open pt-2">
                                          <span onClick={() => this.props.history.push(routes.newZac)}
                                                className="cursor-pointer pt-2">
                                            <SzIcon icon="add-circle" className="m-2" variant="line"/>
                                            <span>{t("zac.create_zac")}</span>
                                          </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="zac-table row m-2  pr-3">
                        <SzTable
                            columns={this.state.columns}
                            data={this.getListData()}
                            keyField="id"
                        />
                        <div className="row w-100">
                            <div className="col-6 w-100 offset-5">
                                {listZac.pagination[PAGINATION_PAGE_COUNT] > 1 &&
                                <SzPagination
                                    totalItemsCount={parseInt(listZac.pagination[PAGINATION_TOTAL_COUNT], 10)}
                                    activePage={parseInt(listZac.pagination[PAGINATION_CURRENT_PAGE], 10)}
                                    onChange={(pageNumber: any) => {
                                        this.setState({page: pageNumber});
                                        this.setData(pageNumber, this.state.sort.field, this.state.sort.direction, this.state.search);
                                    }}
                                    itemsCountPerPage={parseInt(listZac.pagination[PAGINATION_PER_PAGE], 10)}
                                    pageRangeDisplayed={parseInt(listZac.pagination[PAGINATION_PAGE_COUNT], 10)}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </>
                }
            </>
        );
    }
}

const mapDispatchToProps = {
    setZacList: zacAction.setZacList,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        listZac: state.zac.listZac,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(ListZacManager));
