import { Constants } from "../constants";
export default {
  toggleDisplayMap() {
    return {
      type: Constants.TOGGLE_DISPLAY_MAP,
    };
  },
  setDisplayMap(displayMap: boolean) {
    return {
      payload: displayMap,
      type: Constants.SET_DISPLAY_MAP,
    };
  },
  setListSubscriptionProject(isSubscription: boolean) {
    return {
      payload: isSubscription,
      type: Constants.SET_LIST_PROJECT_SUBSCRIPTION,
    };
  },
  setProjectsList(payload: any) {
    return {
      payload,
      type: Constants.SET_LIST_PROJECTS,
    };
  },
  duplicatedProjects(payload: any) {
    return {
      payload,
      type: Constants.SET_LIST_DUPLICATE_PROJECTS,
    };
  },
  setProjectsMap(payload: any) {
    return {
      payload,
      type: Constants.SET_MAP_PROJECTS,
    };
  },
  setProject(payload: any) {
    return {
      payload,
      type: Constants.SET_PROJECT,
    };
  },
  filterStatus(payload: string) {
    return {
      payload,
      type: Constants.FILER_STATUS,
    };
  },
  filterSubscription(payload: boolean) {
    return {
      payload,
      type: Constants.FILER_SUBSCRIPTION,
    };
  },
  filterType(payload: string) {
    return {
      payload,
      type: Constants.FILER_TYPE,
    };
  },
  setSubscription(payload: any) {
    return {
      payload,
      type: Constants.SET_PROJECT_SUBSCRIPTION,
    };
  },
  setSearchText(payload: any) {
    return {
      payload,
      type: Constants.SET_SEARCH_TEXT,
    };
  },
};
