import React from "react";
import { useHistory, withRouter } from "react-router";
import ListContactManager from "../../../projectManagement/manager/ListContactManager";
import "./contact.scss";

const Contact = () => {
  const history = useHistory();
  return (
    <div className="contact-container row p-3">
      <ListContactManager history={history} />
    </div>
  );
};

export default withRouter(Contact);
