import React from "react";
import { useTranslation } from "react-i18next";
import { SzButton, SzIcon } from "@suezenv/react-theme-components";
import "../styles/sideLayer.scss";

interface IPotentialProjectSideLayer {
    isSetting: Boolean;
    goSetting: () => void;
    createDataSource: () => void;
}
const PotentialProjectSideLayer = (props: IPotentialProjectSideLayer) => {
    const { t } = useTranslation();
    return (
        <div className="potential-project-side-layer row">
            <div className="h-100 col-12  side-layer-form">
                <h6 className="list-state-title">{t("potentialProject.side-layer-title")}</h6>
                {
                    !props.isSetting && <div className="row mt-5 mx-2 form-actions">
                        <SzButton className="w-100" onClick={() => {
                            if (!props.isSetting) {
                                props.goSetting();
                            }
                        }}>
                            <span>{t("potentialProject.side-layer-action-button")}</span>
                        </SzButton>
                    </div>
                }
                {props.isSetting &&
                    <div className="create-source-side row ">

                        <SzButton className="add-source-btn border-0 w-100" variant="secondary" onClick={() => props.createDataSource()}>
                            <SzIcon icon="add-circle" variant="line"></SzIcon>
                            <span>{t("setting.side-layer.add-data-source")}</span>
                        </SzButton>

                    </div>}
            </div>
        </div>
    );
};

export default PotentialProjectSideLayer;
