import { config } from "../../config";

export class FreshDesk {
    public static setup() {
        // freshdesk integration
        if (config.freshdeskId) {
            window.fwSettings = {
                widget_id: config.freshdeskId,
            };
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://widget.freshworks.com/widgets/" + config.freshdeskId + ".js";
            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }
}
