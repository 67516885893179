import { Constants } from "../constants";
export default {
  setContactsList(payload: any) {
    return {
      payload,
      type: Constants.SET_CONTACT_LIST,
    };
  },
  setContactDetail(payload: any) {
    return {
      payload,
      type: Constants.SET_CONTACT_DETAIL,
    };
  },
};
