import { Constants } from "../constants";
export default {
  setNotificationsListByStatus(payload: any, statusCode: string | null ) {
    return {
      payload,
      statusCode,
      type: Constants.SET_LIST_NOTIFICATIONS,
    };
  },
  setStats(payload: any) {
    return {
      payload,
      type: Constants.SET_NOTIFICATIONS_STATS,
    };
  },
};
