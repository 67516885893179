import {SzBox, SzButton, SzIcon, SzModal, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router";

interface IProjectHeader {
    title: string;
    subTitle?: string;
    withMenu?: boolean;
    editHandle?: () => void;
    archiveHandle?: () => void;
    cancelUrl?: string;
    history: any;
    subscriptionProject?: any;
    unSubscribeHandle?: () => void;
    subscribed?: boolean;

}
const ProjectHeader = (props: any) => {
    const history = useHistory();
    const {t} = useTranslation();
    const [state, setState] = useState({isOpen: false});
    const [show, setShow] = useState(false);
    const [showMerge, setShowMerge] = useState(false);
    const [projectToMerge, setProjectToMerge] = useState("");

    const getProjectsOption = () => {
        const projects: any = [];
        if (props.listProjects) {

            Object.values(props.listProjects).map((item: any) => {
                if (item.id !== props.projectId) {
                    projects.push({value: item.id, label: item.name, text: item.address});
                }

                return false;
            });
        }

        return projects;
    };

    return (
      <>
        <SzModal
          size="xl"
          className="confirmation"
          title={t("confirmation.title")}
          show={show}
          handleClose={() => setShow(false)}
        >
          <SzTypographie className="mb-0" variant="text">
            {t("confirmation.text")}
          </SzTypographie>
          <div className="row justify-content-center confirmation-buttons">
            <SzButton onClick={() => setShow(false)} className="col-4 mr-3">
              {t("confirmation.no")}
            </SzButton>
            <SzButton onClick={props.archiveHandle} className="col-4 mr-3">
              {t("confirmation.yes")}
            </SzButton>
          </div>
        </SzModal>
          <SzModal
              size="lg"
              className="merge"
              title={t("merge.title")}
              show={showMerge}
              handleClose={() => setShowMerge(false)}
          >
              <div className="pb-0 input_group col-12">
                  <label className="text-inactive sz-label-input position-relative d-block required">{t("merge.selectLabel")}</label>
                  <SzSelect
                      className="mb-1"
                      onChange={(option: any) => setProjectToMerge(option)}
                      name={"mergeProject"}
                      required={true}
                      placeholder={t("merge.placeholder")}
                      options={getProjectsOption()}
                      value={projectToMerge}
                  />
              </div>
              <div className="row justify-content-center confirmation-buttons">
                  <SzButton variant="secondary" onClick={() => setShowMerge(false)} className="col-4 mr-3">
                      {t("merge.cancel")}
                  </SzButton>
                  <SzButton isDisabled={!projectToMerge} onClick={() => props.mergeHandle(projectToMerge)} className="col-4 mr-3">
                      {t("merge.validate")}
                  </SzButton>
              </div>
          </SzModal>
        <div className="project-header mb-2">
          <div className="sz-card-collapse__header sz-left-border position-relative align-items-center m-0 row">
            <div className="w-100 col-md-5 col-sm-12">
              <SzBox className="justify-content-around pb-0">
                <label className="mb-0 font-weight-bold">{props.title}</label>
                <SzTypographie className="mb-0" variant="text">
                  {props.subTitle}
                </SzTypographie>
              </SzBox>
            </div>
            <div className="col-md-7 col-sm-12 p-0 row align-items-center m-0">
              <div className="col-10">
                <div className="row project-menu">
                  {state.isOpen && (
                      <div className="open bg-light">
                      <span title={t("project.header.merge")}>
                          <SzIcon
                              onClick={() => setShowMerge(true)}
                              className="cursor-pointer m-2"
                              variant="line"
                              icon="common-file-double-2"
                          />
                      </span>
                          <span title={t("project.header.edit")}>

                        <SzIcon
                            onClick={props.editHandle}
                            className="cursor-pointer m-2"
                            variant="line"
                            icon="pencil-write"
                        />
                      </span>
                      {props.subscribed ? (
                        <span className="alarm-slash" title={t("project.header.unsubscribe")}>
                          <SzIcon
                            onClick={props.unSubscribeHandle}
                            className="cursor-pointer m-2"
                            variant="line"
                            icon="alarm-bell"
                          />
                        </span>
                      ) : (
                        <span title={t("project.header.subscribe")}>
                          <SzIcon
                            onClick={props.subscribeHandle}
                            className="cursor-pointer m-2"
                            variant="line"
                            icon="alarm-bell"
                          />
                        </span>
                      )}
                      <span title={t("project.header.archived")}>
                        <SzIcon
                          onClick={() => setShow(true)}
                          className="cursor-pointer m-2"
                          variant="line"
                          icon="folder-file-1"
                        />
                      </span>
                    </div>
                  )}
                  <div className={state.isOpen ? `open ml-1 bg-light` : ``}>
                    {props.withMenu && (
                        <div title={t("project.header.menu")}>
                            <SzIcon
                                onClick={() => setState({...state, isOpen: !state.isOpen})}
                                className="cursor-pointer"
                                variant="line"
                                icon="navigation-menu-vertical"
                            />
                        </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="go-home  border-left cursor-pointer col-2 p-0 pt-2 pr-2" title={t("project.header.close")}>
                <SzIcon
                  variant="line"
                  onClick={() => history.push(props.cancelUrl || "/")}
                  icon="close"

                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default ProjectHeader;
