import {SzBox, SzIcon, SzPagination, SzTable} from "@suezenv/react-theme-components";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {contactLabel, getContractId, zacLabel} from "../../main/utils";
import ProjectService from "../services/ProjectService";
import {projectAction} from "../store/actions";
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT,
    PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT, statusIcons,
} from "../store/constants";

class ListProjectManager extends React.Component<any> {

    public state = {
        sort: {field: "name", direction: "asc"},
        columns: [],
        contractId: "",
        page: 1,
    };

    public componentDidMount() {
        this.initTable();
    }

    public initTable() {
        if (Object.values(this.props.listProjects.data).length === 0) {
            this.setData();
        }
        this.setColumn(this.state.sort.field, this.state.sort.direction);
    }

    public setData(page = 1, sortFiled = "name", sortDirection = "asc") {
        const contractId: string = getContractId(this.props.user);

        if (contractId) {
            ProjectService.getProjects(contractId, this.props.filters, this.props.search, page, sortFiled, sortDirection).then((response) => {
                this.props.setProjectsList(response);
            });
        }
    }

    public downloadProjects() {
        const windowUrl = window.URL;
        const link = document.createElement("a");
        link.setAttribute("download", `projets.csv`);
        const contractId: string = getContractId(this.props.user);
        ProjectService.getAllProjects(contractId, this.props.filters, this.props.search, null, null, "csv").then((response: any) => {
            const url = windowUrl.createObjectURL(new Blob([response.data]));
            link.href = url;
            document.body.appendChild(link);
            link.click();
        });
    }

    public setColumn(dataField = "", sort = "") {
        const {t} = this.props;
        this.setState({
            columns: [
                this.columnJson("type", "type", t("lists:project.type"), dataField, sort, false),
                this.columnJson("status", "status", t("lists:project.status"), dataField, sort, false),
                this.columnJson("name", "name", t("lists:project.name"), dataField, sort),
                this.columnJson("address", "address", t("lists:project.address"), dataField, sort),
                this.columnJson("contact", "contact", t("lists:project.contact"), dataField, sort),
                this.columnJson("hold", "hold", t("lists:project.hold"), dataField, sort),
                this.columnJson("zac", "zac", t("lists:project.zac"), dataField, sort, false),
                /* Fixme hide export :{
                    classes: "download",
                    dataField: "download",
                    text: <SzIcon icon="download-bottom" variant="bold"/>,
                    headerClasses: "",
                },*/
            ],
        });
    }

    public columnJson(classes: string, dataField: string, text: string, sortDataField: string, sortDirection: string, canSort = true) {
        const {t} = this.props;
        const sortClass = dataField === sortDataField ? `order-${sortDirection}` : "order-th";
        return {
            classes,
            headerClasses: canSort ? `order-row ${sortClass} ${dataField}` : dataField,
            dataField,
            text: t(text),
            sort: false,
            headerEvents: {
                onClick: canSort ? this.sortByColumn.bind(this) : () => {
                },
            },
        };
    }

    public sortByColumn(e: any, column: any) {
        const {direction} = this.state.sort;
        const sortDirection = ((direction && direction === "desc") ? "asc" : "desc");
        this.setState({sort: {direction: sortDirection, field: column.dataField}});
        this.setColumn(column.dataField, sortDirection);
        this.setData(this.state.page, column.dataField, sortDirection);

        return true;
    }

    public getRowEvent(): any {
        const {history} = this.props;
        const rowEvent = {
            onClick: (e: any, row: any, rowIndex: any) => {
                history.push(`/view/project/${row.id}`);
            },
        };
        return rowEvent;
    }

    public getListData(): any {
        const {t, listProjects} = this.props;
        return Object.values(listProjects.data).map((item: any) => {
            return {
                name: item.name,
                address: item.address,
                contact: item.mainContact ? contactLabel(item.mainContact) : "-",
                hold: new Intl.NumberFormat().format(item.hold),
                zac: item.zac ? zacLabel(item.zac) : "-",
                type: <span className={`${item.type.code} state-span`} title={t(`project.${item.type.code}`)}></span>,
                status: (
                  <img
                    className="status-img"
                    src={statusIcons[item.status.code]}
                    alt={t(`project.${item.status.code}`)}
                    title={t(`project.${item.status.code}`)}
                  />
                ),
                id: item.id,
                /* Fixme hide  download: (
                  <SzCheckbox
                    className={"pl-2"}
                    name={`download[${item.id}]`}
                    label=""
                    type="checkbox"
                    checked={false}
                    onChange={() => {}}
                  />
                ),*/
            };
        });
    }

    public render() {
        const {listProjects, t} = this.props;
        return (
          <>
              {this.state.columns.length && <>
                  <div className="m-2 mb-3 bg-white w-100 project-title">
                      <div
                          className="sz-card-collapse__header sz-left-border position-relative align-items-center m-0 row">
                          <div className=" col-md-3 col-sm-12">

                              <SzBox className="justify-content-around pb-0">
                                  <label className="mb-0 font-weight-bold">{t("project.list_title")}</label>
                              </SzBox>
                          </div>
                          <div className="col-md-9 col-sm-12 p-0 row align-items-center m-0">
                              <div className="offset-2 col-8">
                                  <div className="row project-menu">
                                      <div className="open pt-2">
                                          <span onClick={this.downloadProjects.bind(this)}
                                                className="cursor-pointer pt-2">
                                            <SzIcon icon="download-bottom" className="m-2" variant="line"/>
                                            <span>{t("project.download_title")}</span>
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="list-table row m-2">
                      <SzTable
                          rowClasses={(row: any) => {
                              return row.id === this.props.currentProjectId ? "selected-project" : "";
                          }}
                          columns={this.state.columns}
                          rowEvents={this.getRowEvent()}
                          data={this.getListData()}
                          keyField="id"
                      />
                      <div className="row w-100">
                          <div className="col-6 w-100 offset-5">
                              {listProjects.pagination[PAGINATION_PAGE_COUNT] > 1 &&
                                  <SzPagination
                                      totalItemsCount={parseInt(listProjects.pagination[PAGINATION_TOTAL_COUNT], 10)}
                                      activePage={parseInt(listProjects.pagination[PAGINATION_CURRENT_PAGE], 10)}
                                      onChange={(pageNumber: any) => {
                                          this.setState({page: pageNumber});
                                          this.setData(pageNumber, this.state.sort.field, this.state.sort.direction);
                                      }}
                                      itemsCountPerPage={parseInt(listProjects.pagination[PAGINATION_PER_PAGE], 10)}
                                      pageRangeDisplayed={parseInt(listProjects.pagination[PAGINATION_PAGE_COUNT], 10)}
                                  />
                              }
                          </div>
                      </div>
                  </div>
              </>
              }
          </>
        );
    }
}

const mapDispatchToProps = {
    setProjectsList: projectAction.setProjectsList,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        listProjects: state.project.listProjects,
        filters: state.project.filters,
        search: state.project.searchText,
    };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ListProjectManager));
