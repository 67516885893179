import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { mapReducer } from "../../../map/store/reducers";
import {
    autocompleteReducer,
    potentialProjectReducer,
    projectReducer,
    projectReferentialReducer, zacReducer,
} from "../../../projectManagement/store/reducers";
import notificationReducer from "../../../projectManagement/store/reducers/notificationReducer";
import {contractConfigurationReducer, errorUserReducers, userReducer} from "../../../user/store/reducers";
import errorReducer from "./errors";
import loadingReducers from "./loading";
import ContactReducer from "../../../projectManagement/store/reducers/contactReducer";

const rootReducer = (history: History) =>
  combineReducers({
    error: errorReducer,
    errorUser: errorUserReducers,
    router: connectRouter(history),
    user: userReducer,
    projectReferential: projectReferentialReducer,
    loading: loadingReducers,
    project: projectReducer,
    potentialProject: potentialProjectReducer,
    notification: notificationReducer,
    autocomplete: autocompleteReducer,
    mapDraw: mapReducer,
    contractConfiguration: contractConfigurationReducer,
    contact: ContactReducer,
    zac: zacReducer
  });

export default rootReducer;
