// action strings
export enum Constants {
  LOG_IN = "APP_LOGIN",
  LOG_OUT = "APP_LOGOUT",
  SET_USER_INFO = "SET_USER_INFO",
  SET_CONTRACT_CONFIGURATION = "SET_CONTRACT_CONFIGURATION",
}

export enum ERROR {
  SET = "ERROR.SET",
  RESET = "ERROR.RESET",
}

export enum servicesCodes {
  projectManagement = "URBA_PRJ_MGMT",
  notification = "URBA_NOTIFICATION",
  robot = "URBA_ROBOT",
}
