import React from "react";
import { connect } from "react-redux";
import { projectAction } from "../../main/store/actions";
import ProjectService from "../../projectManagement/services/ProjectService";
import { servicesCodes } from "../../user/store/constants";
import Map from "../components/Map";
import { mapAction } from "../store/actions";
import ContractConfigurationService from "../../user/services/ContractConfigurationService";
import contractConfigurationAction from "../../user/store/actions/contractConfigurationAction";
import {getContractId} from "../../main/utils";

interface IServiceType {
  code: string;
  label: string;
  description: string;
  contractId: string;
}
interface IMapProjectsManager {
  user: any;
  mapProjects: any;
  filters: any;
  search: any;
  setContractConfiguration: (data: any) => void;
  setProjectsMap: (data: any) => void;
  setMapCoordinate: (data: any) => void;
  eventPinHandle?: (lat: any, lng:any) => void;
  isEditEnabled?: boolean;
  isViewProject?: boolean;
  contractConfiguration: any,
  showPin?:boolean,
  synchroPin?:boolean,
  setIsAutoCompleteAction? : (data: any) => void;
  isAutoCompleteAction?:boolean
}


class MapProjectsManager extends React.Component<IMapProjectsManager> {
  public componentDidUpdate(prevProps: any): void {
    if (this.props.user.services !== prevProps.user.services && this.props.user.services[servicesCodes.projectManagement] && this.props.user.services[servicesCodes.projectManagement].length) {
      this.setData();
    }
  }

  public componentDidMount(): void {


    if (this.props.user.services[servicesCodes.projectManagement] && this.props.user.services[servicesCodes.projectManagement].length && Object.values(this.props.mapProjects).length === 0 ) {
      this.setData();
    }
    this.fixEditMap();
  }

  protected  fixEditMap()
  {
    //Fixme: search another solution
    setTimeout(function() {
      const editBtn:any = document.querySelector('.leaflet-draw-edit-edit');
      editBtn && editBtn.click();
      const cancelBtn:any = document.querySelector('.leaflet-draw-actions li:nth-child(2) a');
      cancelBtn && cancelBtn.click();
    },2000);
  }



  public setData() {
    const contractId: string = getContractId(this.props.user);
    if (contractId) {
      ContractConfigurationService.getContractConfiguration(contractId).then((response:any)=>{
        this.props.setContractConfiguration(response);
      });
      ProjectService.getAllProjects(contractId, this.props.filters, this.props.search).then((response) => {
        this.props.setProjectsMap(response);
      });
    }
  }
  public onChange(geojson: any) {
    this.props.setMapCoordinate({ data: geojson.features });
  }
  public render() {
    const {isEditEnabled, isViewProject, user, contractConfiguration} = this.props;
    return <Map eventPinHandle = {this.props.eventPinHandle}
                isEditEnabled={isEditEnabled ? isEditEnabled : false}
                isViewProject={isViewProject ? isViewProject : false}
                onChangeMap={this.onChange.bind(this)}
                sigToken={user.sigToken}
                contractConfiguration = {Object.values(contractConfiguration)}
                showPin={this.props.showPin}
                synchroPin={this.props.synchroPin}
                setIsAutoCompleteAction={this.props.setIsAutoCompleteAction}
                isAutoCompleteAction={this.props.isAutoCompleteAction}
                isZac={false}
    />;
  }
}

const mapDispatchToProps = {
  setContractConfiguration: contractConfigurationAction.setContractConfiguration,
  setProjectsMap: projectAction.setProjectsMap,
  setMapCoordinate: mapAction.setMapCoordinate,
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    mapProjects: state.project.mapProjects,
    filters: state.project.filters,
    search: state.project.searchText,
    contractConfiguration:state.contractConfiguration
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MapProjectsManager);
