import { SzAccordion, SzIcon, SzModal, SzTypographie} from "@suezenv/react-theme-components";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {contactLabel, formatDate, zacContactLabel} from "../../../main/utils";
import AttachmentService from "../../services/AttachmentService";
import {patternLink, statusIcons} from "../../store/constants";
import ViewContact from "../contact/ViewContact";

const DetailProject = (props: any) => {
    const {t} = useTranslation();
    const {detailProject} = props;
    const {photoFile, contract, stoppedFile} = detailProject;
    const [state, setState] = useState({
        showDetailContact: false,
        contact: null,
        showEditContact: false,
        pcAccordion: "-1",
        pcAttachment: "-1",
        commentAccordion: "-1",
    });
    const  replaceWithBr= (html: any) =>{
      return html.replace(/\n/g, "<br />");
    }
    const renderAttribute = (label: string, value: any, isNotText = false) => {
        return (
            <div className="text-center pb-2 col row">
                <div className="col-6 text-right ">
                    <label className="font-weight-bold">
                        {t(`details:project.${label}`)}
                    </label>
                </div>
                <div className="col-6 text-left">
                    {!isNotText ?
                        <SzTypographie variant="text">
                            {value ? value : "-"}
                        </SzTypographie>
                        : value ? value : "-"
                    }
                </div>
            </div>);
    };

    const renderFile = (file: any) => {
        return (
            <div
                className="pl-2 attachment-icon cursor-pointer"
                title={file.originalFileName}
                onClick={() => downloadFile(file)}
            >
                <SzIcon icon="common-file-text" variant="line" />
                <span className="ml-1">{file.originalFileName}</span>
            </div>
        );
    };

    const renderDetailPC = () => {
        const header = <div className="details-pc-title p-2 text-center">{t("details:project.pc_title")}</div>;
        const body = (
            <div className="w-100 pt-4">
                {renderAttribute("pc_number", detailProject.pcNumber)}
                {renderAttribute("pc_deposit", formatDate(detailProject.pcDeposit))}
                {renderAttribute("pc_date", formatDate(detailProject.pcDate))}
                {renderAttribute("pc_stopped", stoppedFile ? renderFile(stoppedFile) : "", true)}
                {renderAttribute("date_set_up_permit_zac", formatDate(detailProject.dateSetUpPermitZac))}
            </div>
        );
        return <div className="details-collapse">
            <SzAccordion activeKey={state.pcAccordion}>
            <SzAccordion.SzCollapse header={header} body={body} eventKey={"0"} onClick={(key: any) => setState({...state, pcAccordion: key !== state.pcAccordion ? key : -1}) }/>

            </SzAccordion>
        </div>;
    };
    const renderComment = () => {
        const header = <div className="details-comment p-2 text-center">{t("details:project.comment_title")}</div>;
        const body = (
          <div className="w-100 pt-4">
            <div className="text-center pb-2 col row">
              <div className="col-12 text-left">
                  <p dangerouslySetInnerHTML={{ __html: replaceWithBr( detailProject.comment ? detailProject.comment : "-") }} />
              </div>
            </div>
          </div>
        );
        return <div className="details-collapse">
            <SzAccordion activeKey={state.commentAccordion}>
                <SzAccordion.SzCollapse
                    header={header} body={body} eventKey={"0"}
                    onClick={
                        (key: any) => setState({
                            ...state,
                            commentAccordion: key !== state.commentAccordion ? key : -1,
                        })
                    }/>
            </SzAccordion>
        </div>;
    };
    const renderAttachments = () => {
        const header = <div
            className="details-attachments-title p-2 text-center">{t("details:project.attachments_title")}</div>;
        const body = (
            <div className="w-100 details-attachments pt-4 ">
                <div className="row pl-4 pr-4 justify-content-center">
                    {Object.values(detailProject.otherAttachments).length === 0 && (
                        <SzTypographie variant="text">{t("details:project.no_attachment_uploaded")}</SzTypographie>
                    )}

                    <ul className="list-style-none pl-0 ml-0">
                        {Object.values(detailProject.otherAttachments).map((file: any) => {
                            return <li className="ml-1" key={file.id}>{renderFile(file)}</li>;
                        })}
                    </ul>
                </div>
            </div>
        );
        return <div className="details-collapse">
            <SzAccordion activeKey={state.pcAttachment}>
            <SzAccordion.SzCollapse  header={header} body={body} eventKey={"0"} onClick={(key: any) => setState({...state, pcAttachment: key !== state.pcAttachment ? key : -1}) }/>
            </SzAccordion>
        </div>;
    };

    const downloadFile = (file: { id: string; originalFileName: string; }) => {
        AttachmentService.getAttachment(contract.id, detailProject.id, file.id).then((response: any) => {
          const windowUrl = window.URL;
          const link = document.createElement("a");
          link.setAttribute("download", file.originalFileName);
          link.href = windowUrl.createObjectURL(new Blob([response.data]));
          document.body.appendChild(link);
          link.click();
        });
    };

    const renderPhoto = () => {
        return <>
            {photoFile && detailProject.photoUrl && (
                <div className="text-center pt-4">
                    <img onClick={() => downloadFile(photoFile)}
                         src={detailProject.photoUrl ? detailProject.photoUrl : ""}
                         className="detail-photo cursor-pointer" alt={photoFile.originalFileName}
                         title={photoFile.originalFileName}/>
                </div>
            )
            }
        </>;
    };
    const renderContacts = () => {
        return (
            (detailProject.mainContact || detailProject.contacts.length) ?
                (<div className="pb-2">
                        {detailProject.mainContact &&
                        <div className="mb-1 cursor-pointer" onClick={() => {
                            setState({...state, contact: detailProject.mainContact, showDetailContact: true},
                            );
                        }}>
                            <SzTypographie className="d-inline"
                                           variant="text">{contactLabel(detailProject.mainContact)}</SzTypographie>
                            <span title={t("lists:project.contact")}>
                                <SzIcon icon="people-man-1" className="h6 pl-2" variant="line"/></span>
                        </div>
                        }
                        {detailProject.contacts.map((contact: any) =>
                            <div className="cursor-pointer" key={contact.id}  onClick={() => {
                                setState({...state, contact, showDetailContact: true},
                                );
                            }}>
                                <SzTypographie className="mb-1"
                                               variant="text">{contactLabel(contact)}</SzTypographie>
                            </div>,
                        )}
                    </div>
                ) : ""
        );
    };

    const renderZac = () => {
        return detailProject.zac ? (
            <div className="pb-2">
                <div className="mb-1">
                    <SzTypographie className="d-inline" variant="text">
                        {zacContactLabel(detailProject.zac)}
                    </SzTypographie>
                </div>
            </div>
        ) : (
            "-"
        );
    };

    const renderLink = () => {
         let url = detailProject.url;
         if (!patternLink.test(url)) {
           url = "http://" + url;
         }
         return detailProject.url ? (
           <a href={url} target="_blank"  rel="noopener noreferrer">
             {detailProject.url}
           </a>
         ) : (
           "-"
         );
    };

    const closeDetailContactHandle = () => {
        setState({...state, showDetailContact: false, contact: null});
    };

    const detailContactRender = () => {
        return (state.contact && <SzModal
            size="xl"
            title={contactLabel(state.contact)}
            show={state.showDetailContact}
            handleClose={closeDetailContactHandle}
        >
            <ViewContact editContactHandle={editContactHandle} closeHandle={closeDetailContactHandle}
                         contact={state.contact}/>
        </SzModal>);
    };

    const editContactHandle = () => {
        setState({...state, showDetailContact: false, showEditContact: true});
    };

    return (
        <>
            <div className="view-project">
                {renderPhoto()}
                <div className="pt-4">
                    {renderAttribute(
                        "type",
                        <span
                            className={`${detailProject.type.code} state-span`}
                            title={t(`forms:project.${detailProject.type.code}`)}
                        ></span>,
                        true,
                    )}
                    {renderAttribute(
                        "status",
                        <img
                            className="status-img"
                            src={statusIcons[detailProject.status.code]}
                            alt={t(`forms:project.${detailProject.status.code}`)}
                            title={t(`forms:project.${detailProject.status.code}`)}
                        />,
                        true,
                    )}
                    {renderAttribute("address", detailProject.address)}
                    {renderAttribute("contact", renderContacts(), true)}
                    {renderAttribute("hold", new Intl.NumberFormat().format(detailProject.hold), true)}
                    {renderAttribute("delivery_date", formatDate(detailProject.deliveryDate))}
                    {renderAttribute("zac", renderZac(), true)}
                    {renderAttribute("url", renderLink(), true)}
                    {renderDetailPC()}
                    {renderAttachments()}
                    {renderComment()}
                </div>
            </div>
            {detailContactRender()}
        </>
    );

};

const mapDispatchToProps = {};
const mapStateToProps = (state: any) => {
    return {
        projectReferential: state.projectReferential,

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailProject);
