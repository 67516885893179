import "leaflet-draw/dist/leaflet.draw.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "@suezenv/react-theme-components/assets/css/main.css";
import AppRouter from "./App";
import "./i18n";
import "./index.scss";
import { history } from "./main/store";

const loadingGif = <div style={{display: "flex", placeContent: "center", paddingTop: "10%"}} >
<img style={{display: "flex"}} alt={""} src={"assets/loader.gif"}/>
</div>;

ReactDOM.render(<Suspense fallback={loadingGif}>
  <AppRouter history={history} />
  </Suspense>, document.getElementById("argus-urba-app"));
