import { SzTab } from "@suezenv/react-theme-components";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getContractId } from "../../../main/utils";
import {servicesCodes} from "../../../user/store/constants";
import NotificationDetail from "../../components/Notification/NotificationDetail";
import NotificationHeader from "../../components/Notification/NotificationHeader";
import NotificationService from "../../services/NotificationService";
import ReferentialService from "../../services/ReferentialService";
import { notificationAction, projectReferentialAction } from "../../store/actions";
import { statusCode } from "../../store/constants";

class ListNotificationtManager extends React.Component<any> {
  public state = {
    sort: { field: "name", direction: "asc" },
    defaultTab: "construction",
    contractId: "",
    page: 1,
    listNotification: [],
    filters: null,
  };

  public componentDidUpdate(prevProps: any): void {
    // if notification changed or not loaded
    const { loading } = this.props;
    if (!loading) {
      this.initNotifications();
    }
  }

  public componentDidMount() {
    if (this.props.user.services.length) {
      this.initNotifications();
    }
  }

  public initNotifications() {
    if (this.props.statusReferential.length === 0) {
      this.initProjectStatus(getContractId(this.props.user));
    } else {
      this.getNotifications(this.state.defaultTab);
    }
  }

  public getNotifications(code: string) {
    const { statusStats, list } = this.props;
    if (parseInt(statusStats[code], 0) !== list[code].length) {
      const contractId = getContractId(this.props.user, servicesCodes.notification);
      NotificationService.getNotificationsStats(contractId).then((response: any) => {
        this.props.setStats(response.data);
      });
      this.props.statusReferential.map((status: { id: any; code: string }) => {
        if (code === status.code) {
          NotificationService.getNotifications(contractId, { status: [status.id] }).then((response: { data: any }) => {
            this.props.setNotificationsList(response.data, status.code);
            this.setState({ ...this.state, isLoadNotification: true });
          });
        }
      });
    }
  }

  public initProjectStatus(contractId: string) {
    if (contractId) {
      ReferentialService.getStatus(contractId).then((data) => {
        this.props.setProjectStatus(data);
      });
    }
  }

  public selectTabHandle(tab: any) {
    this.setState({ ...this.state, defaultTab: tab });
    this.getNotifications(tab);
  }

  public getDataByStatus(statusCode: string) {
    return this.props.list[statusCode];
  }

  public readNotificationHandle() {
    let notificationIds = [];
    const contractId = getContractId(this.props.user, servicesCodes.notification);
    const found = this.getTabs().find((element) => `${element.id}` === this.state.defaultTab);
    if (found) {
      notificationIds = found.data.map((data: { id: null }) => {
        if (data.id !== null) {
          return data.id;
        }
      });
    }
    if (notificationIds.length > 0) {
      NotificationService.readNotifications(contractId, notificationIds).then(() => {
        NotificationService.getNotificationsStats(contractId).then((response: any) => {
          this.props.setStats(response.data);
        });
      });
    }
  }

  public creatProjectHandle() {
    this.props.history.push("new/project");
  }

  public getTabs = () => {
    return [
      {
        id: "construction",
        name: "notification_list.tab.construction",
        data: this.getDataByStatus(statusCode.construction),
      },
      {
        id: "possible",
        name: "notification_list.tab.possible",
        data: this.getDataByStatus(statusCode.possible),
      },
      {
        id: "confirmed",
        name: "notification_list.tab.confirmed",
        data: this.getDataByStatus(statusCode.confirmed),
      },
    ];
  }

  public render() {
    const { t, history, statusStats } = this.props;
    return (
      <>
        <div className="notification-list col-12">
          <NotificationHeader
            readNotificationHandle={this.readNotificationHandle.bind(this)}
            creatProjectHandle={this.creatProjectHandle.bind(this)}
          />
          <div className="notification-list_tab col-12">
            <SzTab
              className="notification-list_tab__nav justify-content-center"
              activeKey={this.state.defaultTab}
              id="story-tab"
              selectTabHandle={this.selectTabHandle.bind(this)}
            >
              {/* <span className={`mr-5 notification-bull notification-bull_tab-${i}`}>{20}</span> */}
              {this.getTabs().map((tab, i) => {
                return (
                  <SzTab.SzTabContent
                    key={i}
                    eventKey={`${tab.id}`}
                    title={
                      <span className="notification-urba pt-2 pr-2 position-relative">
                        {t(tab.name)}
                        <span className="sz-notification-number text-center position-absolute text-white bg-danger rounded-circle p-0">
                          {statusStats[tab.id]}
                        </span>
                      </span>
                    }
                    className="mr-5"
                  >
                    <NotificationDetail notifications={tab.data} history={history} />
                  </SzTab.SzTabContent>
                );
              })}
            </SzTab>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setNotificationsList: notificationAction.setNotificationsListByStatus,
  setProjectStatus: projectReferentialAction.setProjectStatus,
  setStats: notificationAction.setStats,
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    list: state.notification.list,
    statusReferential: state.projectReferential.status,
    statusStats: state.notification.stats.status,
    loading: state.loading,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListNotificationtManager));
