import React from "react";
import { useTranslation } from "react-i18next";
import { SzIcon } from "@suezenv/react-theme-components";
import DataSourceForm from "../../projectManagement/forms/DataSourceForm";
import "../styles/sideLayer.scss";

interface ICreateDataSourceSideLayer {
    cancel: () => void;
}
const CreateDataSourceSideLayer = (props: ICreateDataSourceSideLayer) => {
    const { t } = useTranslation();
    return (
        <div className="potential-project-side-layer data-source-form-container row">
            <div className="h-100 col  side-layer-form">
                <div className="row">
                    <div className="col">
                        <h6 className="list-state-title">{t("setting.create-data-source.title")}</h6>
                    </div>
                    <div className="col-auto pr-0 ">
                        <div className="mt-2 p-2 pr-3  h-100 cursor-pointer"
                            onClick={() => props.cancel()}>
                            <SzIcon variant="line" icon="close" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <DataSourceForm cancel={props.cancel}></DataSourceForm>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CreateDataSourceSideLayer;
