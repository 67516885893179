import moment from "moment";
import { servicesCodes } from "../../user/store/constants";
export const stringToSlug = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const getContractId = (user: { services: any; }, moduleCode: string = servicesCodes.projectManagement) => {
  let contractId = "";

  const module = user.services[moduleCode];
  if (module) {
    const service = module[0];
    contractId = service.id;
  }
  return contractId;
};

export const checkIsDateValid = (date: string, format: string = "DD/MM/YYYY") => {
  if (!date) {
    return {isValid: true, date: ""};
  }

  return { isValid: moment(date, format, true).isValid(), date: moment(date, format).toDate() };
};
export const formatDate = (date: string) => {
  return date ? moment(date, "YYYY/MM/DD").format("DD-MM-YYYY") : "-";
};

export const formatDateTime = (date: string) => {
  return date ? moment(date, "YYYY/MM/DD hh:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "-";
};

export const formatDateWithDay = (date: string) => {
  return date ? moment(date, "YYYY/MM/DD").format("DD/MM/YYYY") : "-";
};

export const stringToDate = (date: string, format: string = "DD/MM/YYYY") => {
  return moment(date, format).toDate();
};

export const formatDateWithFormat = (date: string, format: string = "DD/MM/YYYY") => {
  return date ? moment(date, "YYYY/MM/DD").format(format) : null;
};

export const formatDateWithoutTimezone = (date: string, format: string = "YYYY-MM-DDTHH:mm:ss") => {
  return date ? moment(date).format(format) : null;
};

export const contactLabel = (contact: any) => {
  let label = "";
  if (contact && contact.id !== "") {
    const { firstName, lastName, socialReason, isRealPerson } = contact;
    if (!isRealPerson) {
      label = socialReason;
      if (firstName || lastName) {
        label += " | ";
      }
    }
    if (firstName) {
      label += firstName + " ";
    }
    if (lastName) {
      label += lastName.toUpperCase();
    }
  }

  return label;
};

export const zacLabel = (zac: any) => {
  let label = "";
  if (zac) {
    const { name } = zac;
    label = name ? name.toUpperCase() : "";
  }
  return label;
};

export const zacContactLabel = (zac: any) => {
  let label = "";
  if (zac) {
    const { name, contact } = zac;
    label = name ? (contact ? `${name.toUpperCase()}(${contactLabel(contact)})` : `${name.toUpperCase()}`) : zac;
  }
  return label;
};

export const hasService = (code: string, user: { services: any; }) => {
  const service = user.services[code];

  return service !== undefined;
};

export const capitalizeString = (value: string) => {
  return value[0].toUpperCase() + value.slice(1);
};
