import {
  SzAlert,
  SzBox,
  SzButton,
  SzChooseButton,
  SzInput,
  SzSelect,
  SzTypographie,
} from "@suezenv/react-theme-components";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { routes } from "../../../config";
import AddressAutoCompleteItemsComponent from "../../../main/components/Utils/addressAutoCompleteItemsComponent";
import ContactService from "../../services/ContactService";
import { MapGeoCodeService } from "../../services/MapGeoCodeService";
import { autocompleteAction } from "../../store/actions";
import contactAction from "../../store/actions/contactAction";
import { contactModel, getFormatedForm } from "../models";

interface IContactForm {
  contactTypes: any;
  contractId: string;
  addressAutocomplete: any;
  setAddressAutocomplete: (payload: any) => void;
  setContactsList: (payload: any) => void;
  saveAction: (payload: any) => void;
  contactData?: any;
  redirect: boolean;
  cancel?: () => void;
}

interface IInitialState {
  type: { value: string; label: string } | string;
  address: string;
  city: string;
  street: string;
  number: string;
  isValid: boolean;
  showAddContact: boolean;
  civility: { value: string; label: string } | string;
  firstName: string;
  lastName: string;
  socialReason: string;
  siret: string;
  homePhone: string;
  mobilePhone: string;
  postCode: string;
  errors: boolean;
  email: string;
  isRealPerson: string;
}

const ContactForm = (props: IContactForm) => {
  const NBR_CHAR_START_AUTO_COMPLETE = 3;
  const history = useHistory();

  const initialState: IInitialState = {
    type: "",
    address: "",
    city: "",
    street: "",
    number: "",
    isValid: false,
    showAddContact: false,
    civility: "",
    firstName: "",
    lastName: "",
    socialReason: "",
    siret: "",
    homePhone: "",
    mobilePhone: "",
    postCode: "",
    errors: false,
    email: "",
    isRealPerson: "1",
  };
  const { t } = useTranslation();
  const { contactTypes, contractId, setAddressAutocomplete, addressAutocomplete, contactData, saveAction } = props;

  const getInitialState = () => {
    return {
      ...initialState,
      type: contactData.type
        ? {
            value: contactData.type.id,
            label: t(`forms:contact.${contactData.type.code}`),
          }
        : "",
      address: contactData.address || "",
      city: contactData.city || "",
      street: contactData.street || "",
      number: contactData.number || "",
      civility: contactData.civility
        ? {
            value: contactData.civility,
            label: contactData.civility === "0" ? t("forms:contact.civility_mme") : t("forms:contact.civility_m"),
          }
        : "",
      firstName: contactData.firstName || "",
      lastName: contactData.lastName || "",
      socialReason: contactData.socialReason || "",
      siret: contactData.siret || "",
      homePhone: contactData.homePhone || "",
      mobilePhone: contactData.mobilePhone || "",
      postCode: contactData.postCode || "",
      email: contactData.email || "",
      isRealPerson: contactData.isRealPerson ? "1" : "0",
    };
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {
    if (contactData) {
      const initialState = getInitialState();
      setState({ ...initialState });
    }
  }, [contactData]);

  const [schema] = getFormatedForm(contactModel);

  const { type, address, civility, lastName, firstName, socialReason, siret, homePhone, mobilePhone, email }: any =
    contactModel;

  const getContactTypesOption = () => {
    return Object.values(contactTypes).map((item: any) => {
      return { value: item.id, label: t(`forms:contact.${item.code}`) };
    });
  };

  const saveNewContact = () => {
    const id = contactData ? contactData.id : null;
    const { redirect } = props;
    ContactService.saveContact(contractId, state, id).then((response) => {
      const data = response.data;
      if (data.errors) {
        setState({ ...state, errors: true });
      } else if (!redirect) {
        ContactService.getContact(contractId, data).then((response) => {
          saveAction(response.data);
        });
      }

      ContactService.getContacts(contractId).then((response) => {
        props.setContactsList(response);
      });
      if (redirect) {
        history.push(routes.contact);
      }
    });
  };
  return (
    <>
      {state.errors && <SzAlert variant="danger">{t("forms:An_error_has_occurred")}</SzAlert>}
      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        initialValues={state}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, handleChange, errors, setFieldError, setErrors }) => {
          schema.isValid(state).then((isValidForm: any) => {
            if (isValidForm !== state.isValid) {
              setErrors({});
              setState({ ...state, isValid: isValidForm });
            }
          });
          const changeHandle = (e: any) => {
            setState({ ...state, [e.target.name]: e.target.value });
          };
          const selectChangeHandle = (option: any, event: any) => {
            setState({ ...state, [event.name]: option });
          };
          const addressAutoCompleteHandle = (e: any) => {
            const value = e.target.value;
            setState({
              ...state,
              address: value,
              city: "",
              street: "",
              number: "",
              postCode: "",
            });
            if (e.target.value.length >= NBR_CHAR_START_AUTO_COMPLETE) {
              MapGeoCodeService.autoCompleteAddress(value).then((data) => {
                setAddressAutocomplete(data);
              });
            } else {
              setAddressAutocomplete([]);
            }
          };
          const addressAutoCompleteClickHandle = (
            latitude: any,
            longitude: any,
            street: any,
            numberStreet: any,
            postCode: any,
            city: any,
            address: any,
          ) => {
            setState({ ...state, address, city, street, number: numberStreet, postCode });
            setAddressAutocomplete([]);
          };

          const customHandleBlur = (e: any) => {
            validateTarget(e.target.name, e.target.value);
          };

          const validateTarget = (targetName: string, value: string) => {
            schema
              .validateAt(targetName, { ...state, [targetName]: value })
              .then(() => {
                const newErrors = { ...errors };
                deleteFromObject(targetName, newErrors);
                setErrors(newErrors);
              })
              .catch((error: any) => {
                const { path, message } = error;
                setFieldError(path, message);
              });
          };

          const deleteFromObject = (toDeleteKey: string, object: any) => {
            for (const index in object) {
              if (index === toDeleteKey) {
                delete object[index];
              }
            }
          };

          const optionsContactType = [
            {
              label: t("forms:contact.real_person"),
              value: "1",
              icon: "people-man-1",
            },
            {
              label: t("forms:contact.legal_person"),
              value: "0",
              icon: "network-users",
            },
          ];
          const onChangeTypePerson = (event: any) => {
            setState({ ...state, isRealPerson: event });
          };

          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!state.isValid) {
                  submitForm();
                } else {
                  saveNewContact();
                }
              }}
            >
              <SzBox className="box create-contact " tag="div">
                <div className="col">
                  <div className="pb-0 input_group">
                    <SzChooseButton
                      value={state.isRealPerson}
                      onChange={onChangeTypePerson}
                      name={"iconOpt"}
                      choices={optionsContactType}
                    />
                  </div>

                  <div className="pb-0 input_group">
                    <label className="sz-label-input position-relative d-block required">{t(type.label)}</label>
                    <SzSelect
                      className="mb-1"
                      onChange={selectChangeHandle}
                      label={t(type.label)}
                      name={type.name}
                      required={type.required}
                      placeholder={t(type.placeholder)}
                      options={getContactTypesOption()}
                      value={state.type}
                    />
                    {errors.type && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.type}`)}</>
                      </SzAlert>
                    )}
                  </div>
                  {state.isRealPerson === "0" && (
                    <div className={`pb-0 ${socialReason.required ? "required-div" : ""}`}>
                      <SzInput
                        className="mb-0"
                        onChange={changeHandle}
                        onBlur={customHandleBlur}
                        label={t(socialReason.label)}
                        name={socialReason.name}
                        required={socialReason.required}
                        placeholder={t(socialReason.placeholder)}
                        type={socialReason.type}
                        value={state.socialReason}
                      />
                      {errors.socialReason && <SzAlert variant="danger">{t(`forms:${errors.socialReason}`)}</SzAlert>}
                    </div>
                  )}
                  {state.isRealPerson === "1" && (
                    <div className="pb-0 input_group">
                      <label className="sz-label-input position-relative d-block">{t(civility.label)}</label>
                      <SzSelect
                        className="mb-1"
                        onChange={selectChangeHandle}
                        label={t(civility.label)}
                        name={civility.name}
                        required={civility.required}
                        placeholder={t(civility.placeholder)}
                        options={[
                          { label: t("forms:contact.civility_mme"), value: "0" },
                          { label: t("forms:contact.civility_m"), value: "1" },
                        ]}
                        value={state.civility}
                      />
                      {errors.type && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.civility}`)}</>
                        </SzAlert>
                      )}
                    </div>
                  )}
                  <div className={`pb-0 ${state.isRealPerson === "1" ? "required-div" : ""}`}>
                    <SzInput
                      className="mb-0"
                      onChange={changeHandle}
                      onBlur={customHandleBlur}
                      label={t(lastName.label)}
                      name={lastName.name}
                      required={state.isRealPerson === "1"}
                      placeholder={t(lastName.placeholder)}
                      type={lastName.type}
                      value={state.lastName}
                    />
                    {errors.lastName && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.lastName}`)}</>
                      </SzAlert>
                    )}
                  </div>
                  <div className={`pb-0`}>
                    <SzInput
                      className="mb-0"
                      onChange={changeHandle}
                      onBlur={customHandleBlur}
                      label={t(firstName.label)}
                      name={firstName.name}
                      required={firstName.required}
                      placeholder={t(firstName.placeholder)}
                      type={firstName.type}
                      value={state.firstName}
                    />
                    {errors.firstName && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.firstName}`)}</>
                      </SzAlert>
                    )}
                  </div>

                  {state.isRealPerson === "0" && (
                    <div className="pb-0">
                      <SzInput
                        className="mb-0"
                        onChange={changeHandle}
                        onBlur={customHandleBlur}
                        label={t(siret.label)}
                        name={siret.name}
                        required={siret.required}
                        placeholder={t(siret.placeholder)}
                        type={siret.type}
                        value={state.siret}
                      />
                      <SzTypographie variant="caption" className="font-italic mb-3">
                        {t("forms:not_valid_siret")}
                      </SzTypographie>
                      {errors.siret && (
                        <SzAlert variant="danger">
                          <>{t(`forms:${errors.siret}`)}</>
                        </SzAlert>
                      )}
                    </div>
                  )}

                  <div className="pb-0 position-relative">
                    <SzInput
                      className="mb-0"
                      onChange={addressAutoCompleteHandle}
                      onBlur={customHandleBlur}
                      label={t(address.label)}
                      name={address.name}
                      required={address.required}
                      placeholder={t(address.placeholder)}
                      type={address.type}
                      value={state.address}
                      icon="search"
                    />
                    {addressAutocomplete && (
                      <AddressAutoCompleteItemsComponent
                        addressAutoComplete={{ items: addressAutocomplete }}
                        addressAutoCompleteClickHandle={addressAutoCompleteClickHandle}
                      />
                    )}
                    {errors.address && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.address}`)}</>
                      </SzAlert>
                    )}
                    {!errors.address && (errors.city || errors.street) && (
                      <SzAlert variant="danger">
                        <>{t(`forms:invalid_field`)}</>
                      </SzAlert>
                    )}
                  </div>
                  <div className="pb-0 ">
                    <SzInput
                      className="mb-0"
                      onChange={changeHandle}
                      onBlur={customHandleBlur}
                      label={t(homePhone.label)}
                      name={homePhone.name}
                      required={homePhone.required}
                      placeholder={t(homePhone.placeholder)}
                      type={homePhone.type}
                      value={state.homePhone}
                    />
                    {errors.homePhone && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.homePhone}`)}</>
                      </SzAlert>
                    )}
                  </div>
                  <div className="pb-0">
                    <SzInput
                      className="mb-0"
                      onChange={changeHandle}
                      onBlur={customHandleBlur}
                      label={t(mobilePhone.label)}
                      name={mobilePhone.name}
                      required={mobilePhone.required}
                      placeholder={t(mobilePhone.placeholder)}
                      type={mobilePhone.type}
                      value={state.mobilePhone}
                    />
                    {errors.mobilePhone && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.mobilePhone}`)}</>
                      </SzAlert>
                    )}
                  </div>
                  <div className="pb-0">
                    <SzInput
                      className="mb-0"
                      onChange={changeHandle}
                      onBlur={customHandleBlur}
                      label={t(email.label)}
                      name={email.name}
                      required={email.required}
                      placeholder={t(email.placeholder)}
                      type={email.type}
                      value={state.email}
                    />
                    {errors.email && (
                      <SzAlert variant="danger">
                        <>{t(`forms:${errors.email}`)}</>
                      </SzAlert>
                    )}
                  </div>
                </div>
              </SzBox>

              <div className=" form-actions text-center">
                <SzButton
                  variant="secondary"
                  onClick={() => {
                    if (typeof props.cancel !== "undefined") {
                      props.cancel();
                    } else {
                      history.push("/actor");
                    }
                  }}
                  className="cancel-contact mr-2"
                >
                  {t("forms:contact.cancel")}
                </SzButton>
                <SzButton type="submit" className="add-contact" isDisabled={!state.isValid}>
                  {t(contactData ? "forms:contact.edit" : "forms:contact.add")}
                </SzButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    addressAutocomplete: state.autocomplete.addressContact,
  };
};

const mapDispatchToProps = {
  setAddressAutocomplete: autocompleteAction.setAddressContactAutocomplete,
  setContactsList: contactAction.setContactsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
