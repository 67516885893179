import React from "react";
import { SzIcon } from "@suezenv/react-theme-components";
import * as Yup from "yup";

export default {
  type: {
    schema: Yup.string().required("required_validation"),
    placeholder: "forms:contact.select_type",
    label: "forms:contact.type",
    name: "type",
    type: "select",
    required: true,
  },
  civility: {
    schema: Yup.string(),
    placeholder: "forms:contact.select_civility",
    label: "forms:contact.civility",
    name: "civility",
    type: "select",
    required: false,
  },
  firstName: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:contact.first_name",
    name: "firstName",
    type: "text",
    required: false,
  },
  lastName: {
    schema: Yup.string().when(["isRealPerson"], {
      is: (isRealPerson: string) => isRealPerson === "1",
      then: Yup.string().required("required_validation"),
    }),
    placeholder: "",
    label: "forms:contact.last_name",
    name: "lastName",
    type: "text",
    required: true,
  },
  socialReason: {
    schema: Yup.string().when(["isRealPerson"], {
      is: (isRealPerson: string) => "0" === isRealPerson,
      then: Yup.string().required("required_validation"),
    }),
    placeholder: "",
    label: "forms:contact.social_reason",
    name: "socialReason",
    type: "text",
    required: true,
  },
  siret: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:contact.siret",
    name: "siret",
    type: "text",
    required: false,
  },
  address: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:contact.address",
    name: "address",
    type: "text",
    icon: <SzIcon icon="search" variant="line" />,
    required: false,
  },
  city: {
    schema: Yup.string(),
    placeholder: "forms:contact.city",
    label: "forms:contact.city",
    icon: "",
    name: "city",
    type: "text",
    required: false,
  },
  street: {
    schema: Yup.string(),
    placeholder: "forms:contact.street",
    label: "forms:contact.street",
    icon: "",
    name: "street",
    type: "text",
    required: false,
  },
  number: {
    schema: Yup.string(),
    placeholder: "forms:contact.street_Number",
    label: "forms:contact.street_Number",
    icon: "",
    name: "number",
    type: "text",
    required: false,
  },
  postCode: {
    schema: Yup.string(),
    placeholder: "forms:contact.post_code",
    label: "forms:contact.post_code",
    icon: "",
    name: "number",
    type: "text",
    required: false,
  },
  homePhone: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:contact.home_phone",
    icon: "",
    name: "homePhone",
    type: "text",
    required: false,
  },
  mobilePhone: {
    schema: Yup.string(),
    placeholder: "",
    label: "forms:contact.mobile_phone",
    icon: "",
    name: "mobilePhone",
    type: "text",
    required: false,
  },
  email: {
    schema: Yup.string().email("invalid_field"),
    placeholder: "",
    label: "forms:contact.email",
    icon: "",
    name: "email",
    type: "text",
    required: false,
  },
  isRealPerson: {
    name: "isRealPerson",
    type: "radio",
    required: false,
  },
};
