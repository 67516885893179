import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {mapAction} from "../../map/store/actions";
import ProjectHeader from "../components/project/ProjectHeader";
import ProjectForm from "../forms/ProjectForm";
import {projectAction, projectReferentialAction} from "../store/actions";
import AbstractProjectManager from "./AbstractProjectManager";

class CreateProjectManager extends AbstractProjectManager {
    public render(): any {
        const {t, projectReferential, setProjectsMap, setProjectsList} = this.props;
        return (
            <>
                {this.state.contractId && (
                    <>
                        <div className="project-form p-0 m-0  col-4">
                            <ProjectHeader title={t("forms:project.create_project_title")}/>
                            <ProjectForm
                                pinProjectLocation={this.state.pinProjectLocation}
                                setDuplicatedProjects = {this.setDuplicatedProjects.bind(this)}
                                duplicatedProjects = {this.props.duplicatedProjects}
                                contractId={this.state.contractId}
                                projectReferential={projectReferential}
                                setProjectsMap={setProjectsMap}
                                setProjectsList={setProjectsList}
                                synchroPin={this.state.synchroPin}
                                changeSynchroPin={this.changeSynchroPin.bind(this)}
                                showPin={this.state.showPin}
                                changeShowPin={this.changeShowPin.bind(this)}
                                setMapCenterHandle={this.setMapCenterHandle.bind(this)}
                            ></ProjectForm>
                        </div>
                        {this.mapListRender()}
                    </>
                )}
            </>
        );
    }
}

const mapDispatchToProps = {
    setProjectTypes: projectReferentialAction.setProjectTypes,
    setProjectStatus: projectReferentialAction.setProjectStatus,
    setProjectProgress: projectReferentialAction.setProjectProgress,
    setContactTypes: projectReferentialAction.setContactTypes,
    setProjectsMap: projectAction.setProjectsMap,
    setProjectsList: projectAction.setProjectsList,
    setDuplicatedProjects: projectAction.duplicatedProjects,
    setMapInfo: mapAction.setMapInfo,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        projectReferential: state.projectReferential,
        displayMap: state.project.displayMap,
        duplicatedProjects: state.project.duplicatedProjects,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(CreateProjectManager));
