import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getContractId} from "../../main/utils";
import MapProjectsManager from "../../map/manager/MapProjectsManager";
import {mapAction} from "../../map/store/actions";
import DetailProject from "../components/project/DetailProject";
import ProjectHeader from "../components/project/ProjectHeader";
import AttachmentService from "../services/AttachmentService";
import ProjectService from "../services/ProjectService";
import ReferentialService from "../services/ReferentialService";
import {projectAction, projectReferentialAction} from "../store/actions/index";
import {defaultCenter} from "../store/constants";
import "../style/index.scss";
import ListProjectManager from "./ListProjectManager";

class ViewProjectManager extends React.Component<any> {
    public state = {contractId: "", subscribed: false, refresh: false, center: defaultCenter};

    public componentDidMount(): void {
        this.init();
    }

    public componentDidUpdate(prevProps: any): void {
        if (!this.state.contractId || prevProps.idProject !== this.props.idProject || this.state.refresh) {
            this.init();
        }
    }

    public refreshProjectDetails() {
        this.setState({refresh: true});
    }

    public editHandle() {
        const {history, idProject} = this.props;
        history.push(`/edit/project/${idProject}`);
    }

    public mergeHandle(projectToMerge: any) {
        const {history, idProject} = this.props;
        history.push(`/merge/project/${idProject}/${projectToMerge.value}`);
    }

    public subscribeHandle() {
        const {idProject} = this.props;
        ProjectService.getSubscriptionSubscribe(this.state.contractId, idProject).then(() => {
            this.setState({subscribed: true});

        });
    }

    public unSubscribeHandle() {
        const {idProject} = this.props;
        ProjectService.getSubscriptionUnSubscribe(this.state.contractId, idProject).then(() => {
            this.setState({subscribed: false});

        });
    }

    public archiveHandle() {
        const {contractId} = this.state;
        const {history, idProject, setProjectsList, setProjectsMap, search} = this.props;
        ProjectService.archivedProject(contractId, idProject).then(
          () => {
              ProjectService.getAllProjects(contractId, this.props.filters, search).then((response) => {
                  setProjectsMap(response);
              });
              ProjectService.getProjects(contractId, this.props.filters, search).then((response) => {
                  setProjectsList(response);
                  history.push("/");
              });
          });
    }

    public render() {
        const {history, detailProject, subscriptionProject, listProjects, idProject} = this.props;
        const {center} = this.state;
        this.props.setMapInfo({center, zoom: 19, isAutoZoomActivated: false});

        return (
          <>
              {detailProject && (
                <>
                    <div className="pt-0 m-0 p-0 detail-project  col-4">
                        <ProjectHeader
                          subscription={subscriptionProject}
                          subscribeHandle={this.subscribeHandle.bind(this)}
                          unSubscribeHandle={this.unSubscribeHandle.bind(this)}
                          subscribed={this.state.subscribed}
                          archiveHandle={this.archiveHandle.bind(this)}
                          editHandle={this.editHandle.bind(this)}
                          mergeHandle={this.mergeHandle.bind(this)}
                          title={detailProject.name}
                          subTitle={detailProject.city}
                          withMenu={true}
                          listProjects={listProjects}
                          projectId={idProject}
                        />
                        <DetailProject refreshProjectDetails={this.refreshProjectDetails.bind(this)}
                                       detailProject={detailProject}/>
                    </div>
                    <div className="col-8 no-padding map">
                        {this.props.displayMap ? (
                          <MapProjectsManager isViewProject={true}></MapProjectsManager>
                        ) : (
                          <ListProjectManager currentProjectId={detailProject.id} history={history}/>
                        )}
                    </div>
                </>
              )}
          </>
        );
    }

    private init() {
        const {idProject, user, setProject, setSubscription} = this.props;
        const contractId = getContractId(user);

        if (contractId) {
            ProjectService.getProject(contractId, idProject).then((response) => {
                this.setState({contractId, refresh: false});
                const project = response.data;
                setProject({...project, photoUrl: ""});
                ProjectService.getSubscription(contractId, idProject).then((response) => {
                    setSubscription(response.data);
                    this.setState({subscribed: response.data ? true : false});
                });
                if (project.photoFile) {
                    AttachmentService.getAttachment(contractId, project.id, project.photoFile.id).then((response: any) => {
                        const windowUrl = window.URL;
                        const url = windowUrl.createObjectURL(new Blob([response.data]));
                        setProject({...project, photoUrl: url});
                    });
                }
                if (this.props.projectReferential.contactTypes.length === 0) {
                    ReferentialService.getContactTypes(contractId).then((data) => {
                        this.props.setContactTypes(data);
                    });
                }
                const center = (project && project.latitude && project.longitude) ? [project.latitude, project.longitude] : null;
                this.setState({...this.state, center});
                if (center) {
                    this.props.setMapInfo({center, zoom: 19, isAutoZoomActivated: false});
                }
            });
        }
    }
}

const mapDispatchToProps = {
    setProject: projectAction.setProject,
    setProjectsMap: projectAction.setProjectsMap,
    setProjectsList: projectAction.setProjectsList,
    setSubscription: projectAction.setSubscription,
    setContactTypes: projectReferentialAction.setContactTypes,
    setMapInfo: mapAction.setMapInfo,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        displayMap: state.project.displayMap,
        detailProject: state.project.detailProject,
        filters: state.project.filters,
        subscriptionProject: state.project.subscriptionProject,
        search: state.project.searchText,
        projectReferential: state.projectReferential,
        listProjects: state.project.mapProjects,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ViewProjectManager));
