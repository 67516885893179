import L, { LatLng, LatLngBounds } from "leaflet";
// tslint:disable-next-line: ordered-imports
import { EditControl } from "@suezenv/react-leaflet-draw-fix";
import { SzAlert } from "@suezenv/react-theme-components";
import "leaflet-draw";
import React, { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureGroup,
  GeoJSON,
  LayersControl,
  Map as LeafletMap,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  WMSTileLayer,
} from "react-leaflet";
import Control from "react-leaflet-control";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { capitalizeString } from "../../main/utils";
import { projectAction } from "../../projectManagement/store/actions";
import { defaultCenter, defaultZoom, statusIcons, typeColor } from "../../projectManagement/store/constants";
import { mapAction } from "../store/actions";
import "../style/map.scss";

interface IState {
  lat: string;
  lng: string;
  zoom: number;
}

const layerComponents: any = {
  WMSTileLayer: WMSTileLayer,
  TileLayer: TileLayer,
};

interface IMapComponent {
  allowCreatProject?: any;
  projects?: any;
  selectedProject?: any;
  zoom: number;
  center: { lat: string; lng: string };
  onChangeMap: any;
  location: any;
  setMapCoordinate: ({ data, type }: any) => {};
  isEditEnabled?: boolean;
  isViewProject?: boolean;
  setProject: (payload: any) => void;
  sigToken: string;
  contractConfiguration: any;
}

const Map = (props: any) => {
  const mapRef: any = createRef();
  const refMarker = createRef();
  const { t } = useTranslation();
  const intPinPosition: any = null;
  const [pinPosition, setPinPosition] = useState(intPinPosition);
  const [showBtn, setShowBtn] = useState(false);
  const [editableFG, setEditableFG] = useState<{ leafletElement: any } | null>(null);
  const { projects, selectedProject, mapInfo, zacGeoJson } = props;
  const history = useHistory();
     /****************************************************************/
    /** Attention: Ne surtout pas supprimer la variable ci-dessous **/
   /**  Elle est utilisée a la ligne 363                          **/
  /**/  const [showNetwork, setShowNetwork] = useState(false);  /**/
 /****************************************************************/
  useEffect(() => {
    const bounds = projects.map((project: any) => {
      return new LatLng(project.latitude, project.longitude);
    });
    const projectBounds = new LatLngBounds(bounds);
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
      if (projectBounds.isValid() && !isViewProject() && !isEnabled()) {
        map.fitBounds(projectBounds);
      }
    }
  }, [projects]);

  useEffect(() => {
    if (
      props.showPin &&
      pinPosition &&
      props.mapInfo &&
      (!props.mapInfo.center ||
        (pinPosition[0] !== props.mapInfo.center[0] && pinPosition[1] !== props.mapInfo.center[1]))
    ) {
      props.eventPinHandle(pinPosition[0], pinPosition[1]);
    }
    if (props.mapInfo && props.mapInfo.center && !pinPosition) {
      setPinPosition([props.mapInfo.center[0], props.mapInfo.center[1]]);
    }
  }, [pinPosition, props.showPin]);

  useEffect(() => {
    if (props.isAutoCompleteAction) {
      setPinPosition(props.mapInfo.center);
      props.setIsAutoCompleteAction(false);
    }
  }, [props.isAutoCompleteAction]);

  const getIcon = (type: string, status: string, isCenter = false) => {
    const markerHtmlStyles = `
      background-color: ${typeColor[type]};
      width: 2.5rem;
      height: 2.5rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: ${isCenter ? "3px solid #17a2b8" : "1px solid #FFFFFF"}
      `;
    const iconStatus = type === "to-not-follow" ? `${status}White` : status;

    const icon = L.divIcon({
      className: "my-custom-pinn",
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" ><img style="transform: rotate(-45deg);" src="${statusIcons[iconStatus]}"></span>`,
    });

    return icon;
  };

  const isCenterMarker = (center: { lat: string; lng: string }) => {
    return (
      props.zoom !== defaultZoom &&
      props.mapInfo.center &&
      center.lat === props.mapInfo.center[0] &&
      center.lng === props.mapInfo.center[1]
    );
  };

  const isEnabled = () => {
    return props.isEditEnabled ? props.isEditEnabled : false;
  };

  const isViewProject = () => {
    return props.isViewProject ? props.isViewProject : false;
  };

  const displayFeaturesGeoJSON = () => {
    if (selectedProject && selectedProject.holdGeoCoordinates.length > 0) {
      return selectedProject.holdGeoCoordinates.map((coordinate: any, index: string | number | undefined) => {
        return <GeoJSON key={index} data={coordinate.feature} />;
      });
    }
  };

  const drawFeatures = () => {
    if (selectedProject && selectedProject.holdGeoCoordinates.length > 0) {
      return selectedProject.holdGeoCoordinates.map((coordinate: any, index: string | number | undefined) => {
        return (
          coordinate.feature && (
            <Polygon
              key={index}
              positions={convertGeoJsonCoordinateToLatLong(coordinate.feature.geometry.coordinates)}
              attribution="puprle"
            />
          )
        );
      });
    }

    if (zacGeoJson && zacGeoJson.length > 0) {
      return zacGeoJson.map((coordinate: any, index: string | number | undefined) => {
        return (
          coordinate.geometry && (
            <Polygon
              key={index}
              positions={convertGeoJsonCoordinateToLatLong(coordinate.geometry.coordinates)}
              attribution="puprle"
            />
          )
        );
      });
    }
  };
  const convertGeoJsonCoordinateToLatLong = (coordinates: any[]) => {
    const convertCoords: any[] = [];
    let convertCoor: any[] = [];
    coordinates.forEach((coordinate: any) => {
      convertCoor = [];
      coordinate.forEach((point: any) => convertCoor.push([point[1], point[0]]));
      convertCoords.push(convertCoor);
    });
    return convertCoords;
  };

  const onFeatureGroupReady = (reactFGref: { leafletElement: any }) => {
    // populate the leaflet FeatureGroup with the geoJson layers
    const leafletGeoJSON = new L.GeoJSON(selectedProject?.holdGeoCoordinates);
    if (reactFGref !== null) {
      const leafletFG = reactFGref.leafletElement;

      leafletGeoJSON.eachLayer((layer: any) => {
        leafletFG.addLayer(layer);
      });

      // store the ref for future access to content
      setEditableFG(reactFGref);
    }
  };

  const onChange = () => {
    const { onChangeMap } = props;

    if (!editableFG || !onChangeMap) {
      return;
    }
    const geoJsonData = editableFG.leafletElement.toGeoJSON();
    onChangeMap(geoJsonData);
  };

  const onCreated = (e: { layerType: any; layer: any }) => {
    onChange();
  };

  const onEdited = (e: { target: any; layers: any }) => {
    onChange();
  };

  const renderSimpleLayers = () => {
    return props.contractConfiguration.map((contractConfig: any, index: number) =>
      layerComponents[contractConfig.data.tileType] && !contractConfig.data.toggle ? (
        <LayersControl.BaseLayer name={t(`_map.${contractConfig.data.name}`)} key={index}>
          {React.createElement(layerComponents[contractConfig.data.tileType], {
            key: contractConfig.id,
            ...contractConfig.data.params,
            maxNativeZoom: 19,
            maxZoom: 19,
          })}
        </LayersControl.BaseLayer>
      ) : (
        <></>
      ),
    );
  };

  const renderToggleLayers = () => {
    return props.contractConfiguration.map((contractConfig: any) => {
      try {
        if (
          contractConfig.data.toggle &&
          contractConfig.data.toggle.variable &&
          eval(contractConfig.data.toggle.variable)
        ) {
          return React.createElement(layerComponents[contractConfig.data.tileType], {
            key: contractConfig.id,
            ...contractConfig.data.params,
            token: contractConfig.data.token ? props[contractConfig.data.token] : null,
          });
        }
      } catch (e) {}
    });
  };

  const onClickHandle = (event: any) => {
    const { lat, lng } = event.latlng;
    if (props.showPin) {
      setPinPosition([lat, lng]);
    }
  };

  const onDragendHandle = (event: any) => {
    const currentMarker: any = refMarker.current;
    if (currentMarker != null) {
      const { lat, lng }: any = currentMarker.leafletElement.getLatLng();
      setPinPosition([lat, lng]);
    }
  };

  const onDeleted = (e: any) => {
    editableFG?.leafletElement.removeLayer(e.layer);
    onChange();
  };

  const zoomHandle = (e: any) => {
    const target = e.target;
    if (target && target._zoom && target._lastCenter) {
      props.setMapInfo({ zoom: target._zoom, center: [target._lastCenter.lat, target._lastCenter.lng] });
    }
  };

  return (
    <>
      <div className="row">
        {props.showPin && !pinPosition && (
          <SzAlert className="col-3 offset-4 show-marker-info" variant="info">
            {t("click_set_marker_position")}
          </SzAlert>
        )}
      </div>
      <LeafletMap
        center={mapInfo.center || [defaultCenter.lat, defaultCenter.lng]}
        zoom={mapInfo.zoom || defaultZoom}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        ref={mapRef}
        onClick={onClickHandle}
        onZoomEnd={zoomHandle}
      >
        <Control position="topleft">
          <div className="sig-btn-menu p-1" title={t("reset_auto_zoom")}>
            <i
              className="sz-icon-line diagram-round mt-0 cursor-pointer"
              onClick={() => props.setMapInfo({ center: null, zoom: null, isAutoZoomActivated: false })}
            />
          </div>
        </Control>
        <LayersControl position="topleft">
          <LayersControl.BaseLayer checked name={t("_map.default")}>
            <TileLayer
              animate={false}
              maxNativeZoom={18}
              maxZoom={18}
              style={{ zIndex: 100 }}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          {renderSimpleLayers()}
        </LayersControl>
        {!props.isZac && (
          <>
            {props.contractConfiguration.filter((contractConfig: any) => contractConfig.data.toggle).length > 0 && (
              <Control position="topleft">
                <div className="sig-btn-menu p-1">
                  <i
                    className="sz-icon-line navigation-menu mt-0 cursor-pointer"
                    onClick={() => setShowBtn(!showBtn)}
                  />
                </div>

                {showBtn && (
                  <div className="map-sig-menu">
                    <div className="box-shadow">
                      <ul className="list-group">
                        {props.contractConfiguration.map(
                          (contractConfig: any, index: number) =>
                            contractConfig.data.toggle && (
                              <li className="list-group-item " key={index}>
                                <div className="row">
                                  <div className="col-4 mt-2">
                                    <span>{t(`_map.${contractConfig.data.toggle.name}`)}</span>
                                  </div>
                                  <div className="col-6">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        onChange={() => {
                                          eval(
                                            `set${capitalizeString(contractConfig.data.toggle.variable)}(${!eval(
                                              contractConfig.data.toggle.variable,
                                            )})`,
                                          );
                                        }}
                                        checked={eval(contractConfig.data.toggle.variable)}
                                      />
                                      <span className="slider m-2"></span>
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ),
                        )}
                      </ul>
                    </div>
                  </div>
                )}
                {renderToggleLayers()}
              </Control>
            )}
            <FeatureGroup>
              <>
                {!props.showPin && (
                  <>
                    {Object.values(projects).map((project: any, index: number) => {
                      const isCenter =
                        isCenterMarker({
                          lat: project.latitude,
                          lng: project.longitude,
                        }) && isViewProject();
                      return (
                        <Marker
                          onClick={(e: any) => {
                            e.target.closePopup();
                            props.setProject(null);
                            history.push(`/view/project/${project.id}`);
                          }}
                          onMouseOver={(e: any) => {
                            e.target.openPopup();
                          }}
                          onMouseOut={(e: any) => {
                            e.target.closePopup();
                          }}
                          key={index}
                          position={[project.latitude, project.longitude]}
                          icon={getIcon(project.type.code, project.status.code, isCenter)}
                        >
                          <Popup>
                            <span>{project.name}</span>
                          </Popup>
                        </Marker>
                      );
                    })}
                  </>
                )}
                {props.showPin && pinPosition && (
                  <Marker ref={refMarker} draggable={true} ondragend={onDragendHandle} position={pinPosition}></Marker>
                )}
              </>
            </FeatureGroup>
            {isViewProject() && displayFeaturesGeoJSON()}
          </>
        )}
        {isEnabled() && (
          <FeatureGroup
            ref={(reactFGref: { leafletElement: any }) => {
              onFeatureGroupReady(reactFGref);
            }}
          >
            {
              <EditControl
                position="topright"
                onEdited={onEdited}
                onCreated={onCreated}
                onDeleted={onDeleted}
                draw={{
                  marker: false,
                  circlemarker: false,
                  polyline: false,
                  circle: false,
                  rectangle: false,
                }}
              />
            }
            {drawFeatures()}
          </FeatureGroup>
        )}
      </LeafletMap>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.router.location,
    projects: state.project.mapProjects,
    selectedProject: state.project.detailProject,
    mapInfo: state.mapDraw,
  };
};

const mapDispatchToProps = {
  setMapCoordinate: mapAction.setMapCoordinate,
  setProject: projectAction.setProject,
  setMapInfo: mapAction.setMapInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
