import instanceRequest, { Request } from "../../main/services/Request";

class NotificationService {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }
  public getNotifications(contractId: string, filters: any = null) {
    let url = `/api/${contractId}/notification`;
    let filtersQuery = "";
    if (filters) {
      filtersQuery = this.formatFilters(filters);
    }
    url = `${url}?${filtersQuery}`;
    return this.axiosService.get(url, false, false).then((response) => {
      return response;
    });
  }

  public getNotificationsStats(contractId: string) {
    const url = `/api/${contractId}/notification/stats`;
    return this.axiosService.get(url, false, false, false).then((response) => {
      return response;
    });
  }

  public readNotifications(contractId: any, notificationList: string[]) {
    const url = `/api/${contractId}/notification/read`;
    return this.axiosService.post(url, notificationList, false);
  }
  private formatFilters(filters: { status: any }) {
    let filterQuery = "";
    if (filters && filters.status) {
      filters.status.map((status: any) => {
        filterQuery = filterQuery ? `${filterQuery}&filterStatus[]=${status}` : `filterStatus[]=${status}`;
      });
    }

    return filterQuery;
  }
}

export default new NotificationService(instanceRequest);
