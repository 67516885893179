import {SzIcon} from "@suezenv/react-theme-components";
import React from "react";
import {contactLabel} from "../../../main/utils";

interface IMapComponent {
    contactAutoComplete: any;
    contactAutoCompleteClickHandle: (
        id: string,
        firstName: string,
        lastName: string,
        socialReaso: string,
        isRealPerson: boolean,
    ) => void;
    className?: string;
}
const ContactAutoCompleteItemsComponent = (props: IMapComponent) => {
    const { contactAutoComplete, contactAutoCompleteClickHandle , className} = props;
    return (
        <>
            <div className={`position-absolute list-group overflow-auto ${className} mt-4 w-100`}>
                {contactAutoComplete.items.map((item: any, index: any) => {
                    const { id, firstName, lastName, socialReason, isRealPerson } = item;
                    return id ? (
                        <button
                            key={index}
                            type="button"
                            className="list-group-item list-group-item-action"
                            onClick={() => { contactAutoCompleteClickHandle(id, firstName, lastName, socialReason, isRealPerson); }}
                        >
                            <SzIcon icon={isRealPerson ? "people-man-1" : "network-users"} variant="bold" className="mr-2"></SzIcon>
                            {contactLabel(item)}
                        </button>
                    ) : null;
                })}
            </div>
        </>
    );
};

export default ContactAutoCompleteItemsComponent;
