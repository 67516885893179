import instanceRequest, { Request } from "../../main/services/Request";
import {PAGINATION_HEADERS} from "../store/constants";

let second = new Date().getSeconds();

class ZacService {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public saveNewZac(contractId: string, values: any) {
    const url = `/api/${contractId}/zac`;
    const body = {
      name: values.name,
      area: values.area,
      contact: values.contact,
      contract: contractId,
      geoJson: values.geoJson,
    };

    return this.axiosService.post(url, body, false);
  }

  public editZac(contractId: string, zacId: string, values: any) {
    const url = `/api/${contractId}/zac/${zacId}`;
    const body = {
      name: values.name,
      area: values.area,
      contact: values.contact,
      contract: contractId,
      geoJson: values.geoJson,
    };

    return this.axiosService.put(url, body, false);
  }

  public searchZac(contractId: string, query: string) {
    const url = `/api/${contractId}/zac?search=${query}`;
    const currentSecond = new Date().getSeconds();
    let items: any = [];
    if (currentSecond !== second) {
      second = currentSecond;
      this.axiosService.cancelRequest();
      return this.axiosService.get(url, true, true, false).then((response: any) => {
        items = response.data;

        return items;
      });
    } else {
      return new Promise((resolve) => {
        resolve(items);
      });
    }
  }

  public getZacs(contractId: string, page = 1, sortField = "firstName", sortDirection = "asc", itemsPerPage = 30, query= "") {
    const sort = this.formatSort(sortField, sortDirection);

    const url = `/api/${contractId}/zac?currentPage=${page}&itemsPerPage=${itemsPerPage}&${sort}${query ? `&search=${query}` : ""}`;
    return this.axiosService.get(url, true, true, false).then((response: any) => {
      return {
        pagination: this.getPaginationInfo(response.headers),
        data: response.data,
      };
    });
  }

  public getPaginationInfo(headers: any): any {
    const pagination: any = [];
    Object.entries(headers).map((header: any) => {
      if (PAGINATION_HEADERS.includes(header[0])) {
        pagination[header[0]] = header[1];
      }
      return pagination;
    });

    return pagination;
  }

  private formatSort(sortField: string, sortDirection: string): string {
    return `sorterFields[]=${encodeURI(`${sortField}=${sortDirection}`)}`;
  }

  public getZac(contractId: string, zacId: string) {
    const url = `/api/${contractId}/zac/${zacId}`;
    return this.axiosService.get(url, false, false).then((response) => {
      return response;
    });
  }

  public archiveZac(contractId: string, zacId: string) {
    const url = `/api/${contractId}/zac/${zacId}`;
    return this.axiosService.delete(url, false).then((response) => {
      return response;
    });
  }
}

export default new ZacService(instanceRequest);
