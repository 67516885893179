
import instanceRequest, { Request } from "../../main/services/Request";
import {PAGINATION_HEADERS} from "../store/constants";
let second = new Date().getSeconds();
class ContactService {

    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public saveContact(contractId: string, values: any, id: any = null) {
        const url = `/api/${contractId}/contact`;
        const body = {
            type: values.type.value,
            address: values.address,
            city: values.city,
            street: values.street,
            number: values.number,
            postCode: values.postCode,
            civility: values.civility ? values.civility.value : "",
            firstName: values.firstName,
            lastName: values.lastName,
            siret: (values.isRealPerson === "0" ? values.siret : ""),
            socialReason: (values.isRealPerson === "0" ? values.socialReason : ""),
            homePhone: values.homePhone,
            mobilePhone: values.mobilePhone,
            email: values.email,
            isRealPerson: values.isRealPerson,
        };
        if (id) {
            const url = `/api/${contractId}/contact/${id}`;
            return this.axiosService.put(url, body, false);
        }
        return this.axiosService.post(url, body, false);
    }

    public searchContact(contractId: string, query: string) {
        const url = `/api/${contractId}/contact?search=${query}`;
        const currentSecond = new Date().getSeconds();
        let items: any = [];
        if (currentSecond !== second) {
            second = currentSecond;
            this.axiosService.cancelRequest();
            return this.axiosService.get(url, true, true, false).then((response: any) => {
                items = response.data;
                return items;
            });
        } else {
            return new Promise((resolve) => {
                resolve(items);
            });
        }
    }

    public cancelSearchContact() {
        this.axiosService.cancelRequest();
    }

    public getContacts(contractId: string, page = 1, sortField = "firstName", sortDirection = "asc", itemsPerPage = 30, query= "") {
        const sort = this.formatSort(sortField, sortDirection);

        const url = `/api/${contractId}/contact?currentPage=${page}&itemsPerPage=${itemsPerPage}&${sort}${query ? `&search=${query}` : ""}`;
        return this.axiosService.get(url, true, true, false).then((response: any) => {
            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data,
            };
        });
    }

    public getPaginationInfo(headers: any): any {
        const pagination: any = [];
        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }

    private formatSort(sortField: string, sortDirection: string): string {
        return `sorterFields[]=${encodeURI(`${sortField}=${sortDirection}`)}`;
    }

    public getContact(contractId: string, contactId: string) {
        const url = `/api/${contractId}/contact/${contactId}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return response;
        });
    }

    public archivedContact(contractId: string, contactId: string) {
        const url = `/api/${contractId}/contact/${contactId}`;
        return this.axiosService.delete(url, false).then((response) => {
            return response;
        });
    }
}

export default new ContactService(instanceRequest);
