import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import MapProjectsManager from "../../../map/manager/MapProjectsManager";
import ListProjectManager from "../../../projectManagement/manager/ListProjectManager";
import { servicesCodes } from "../../../user/store/constants";
import { IUser } from "../../../user/store/types";
import SideLayer from "../../components/SideLayer";
import { projectAction } from "../../store/actions";
import Loading from "../Loading";
import "./home.scss";

interface IHomeProps {
  user: IUser;
  setProjectsMap: any;
  showResultDetail: boolean;
  showSearchResult: boolean;
  services: IServiceType[] | [];
  displayMap: boolean;
}
interface IServiceType {
  code: string;
  label: string;
  description: string;
  contractId: string;
}
const Home = (props: IHomeProps) => {
  const history = useHistory();
  const [isHidden, setIsHidden] = useState(false);
  const { user } = props;
  const services: any = user.services;
  const hasService = () => {
    return services[servicesCodes.projectManagement] !== undefined;
  };
  const projectContainerClassName = useMemo(() => {
    let className = "";
    if (hasService()) {
      className = `${className} no-padding overflow-auto ${isHidden ?
        "w-100" : "flex-container"
        } ${props.displayMap
          ? "h-100" : "h-90 mb-3"
        }`;
    } else {
      className = "w-100 no-padding";
    }
    return className;
  }, [isHidden, props.displayMap]);
  if (!props.user.logged) {
    return (
      <div className="map">
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <div className={projectContainerClassName}>
          {props.displayMap ? <MapProjectsManager  /> : <ListProjectManager history={history} />}
        </div>
        {isHidden && (
          <div className="cursor-pointer open-pane no-padding" onClick={() => setIsHidden(false)}>
            <div>{"<"}</div>
          </div>
        )}
        <div className={isHidden ? "no-padding hidden" : "no-padding flex-side-layer"}>
          <span onClick={() => setIsHidden(true)} className="close-pane cursor-pointer">
            &gt;
          </span>
          <SideLayer allowCreatProject={hasService} />
        </div>
      </>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    showResultDetail: false,
    showSearchResult: false,
    user: state.user,
    displayMap: state.project.displayMap,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(projectAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
