// action strings
import confirmedIcon from "../../assets/img/icons/legende_confirmed.svg";
import confirmedIconWhite from "../../assets/img/icons/legende_confirmed_white.svg";
import constructionIcon from "../../assets/img/icons/legende_construction.svg";
import constructionIconWhite from "../../assets/img/icons/legende_construction_white.svg";
import possibleIcon from "../../assets/img/icons/legende_possible.svg";
import possibleIconWhite from "../../assets/img/icons/legende_possible_white.svg";
import {mapConfig} from "../../config";

export enum Constants {
  SET_PROJECT_TYPE = "SET_PROJECT_TYPE",
  SET_PROJECT_STATUS = "SET_PROJECT_STATUS",
  SET_PROJECT_PROGRESS = "SET_PROJECT_PROGRESS",
  SET_CONTACT_TYPES = "SET_CONTACT_TYPES",
  SET_LIST_PROJECTS = "SET_LIST_PROJECTS",
  SET_LIST_DUPLICATE_PROJECTS = "SET_LIST_DUPLICATE_PROJECTS",
  SET_LIST_POTENTIAL_PROJECTS = "SET_LIST_POTENTIAL_PROJECTS",
  SET_LIST_DATA_SOURCES = "SET_LIST_DATA_SOURCES",
  TOGGLE_DISPLAY_MAP = "TOGGLE_DISPLAY_MAP",
  SET_DISPLAY_MAP = "SET_DISPLAY_MAP",
  SET_LIST_PROJECT_SUBSCRIPTION = "SET_LIST_PROJECT_SUBSCRIPTION",
  SET_MAP_PROJECTS = "SET_MAP_PROJECTS",
  SET_PROJECT = "SET_PROJECT",
  FILER_STATUS = "FILER_STATUS",
  FILER_SUBSCRIPTION = "FILER_SUBSCRIPTION",
  FILER_TYPE = "FILER_TYPE",
  SET_LIST_NOTIFICATIONS = "SET_LIST_NOTIFICATIONS",
  SET_NOTIFICATIONS_STATS = "SET_NOTIFICATIONS_STATS",
  SET_PROJECT_SUBSCRIPTION = "SET_PROJECT_SUBSCRIPTION",
  SET_AUTO_COMPLETE_CONTACT = "SET_AUTO_COMPLETE_CONTACT",
  SET_AUTO_COMPLETE_ZAC = "SET_AUTO_COMPLETE_ZAC",
  SET_AUTO_COMPLETE_ADDRESS = "SET_AUTO_COMPLETE_ADDRESS",
  SET_AUTO_COMPLETE_ADDRESS_CONTACT = "SET_AUTO_COMPLETE_ADDRESS_CONTACT",
  SET_SEARCH_TEXT = "SET_SEARCH_TEXT",
  SET_CONTACT_LIST = "SET_CONTACT_LIST",
  SET_CONTACT_DETAIL = "SET_CONTACT_DETAIL",
  SET_ZAC_LIST = "SET_ZAC_LIST",
  SET_ZAC_DETAIL = "SET_ZAC_DETAIL",
}

export const siretRegExp = /^([0-9]{14}){0,1}$/;
export const holdRegex = /^[0-9]*$/;
export const areaRegex = /^[0-9]*$/;
export const patternLink = /^((http|https|ftp):\/\/)/;
export const acceptExtPhoto = "image/jpeg, image/png, image/jpg";
export const acceptExtDoc = "application/pdf,.doc,.docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const acceptExtDropZone = `${acceptExtDoc},${acceptExtPhoto}`;
export const PAGINATION_TOTAL_COUNT = "x-pagination-total-count";
export const PAGINATION_PAGE_COUNT = "x-pagination-page-count";
export const PAGINATION_CURRENT_PAGE = "x-pagination-current-page";
export const PAGINATION_PER_PAGE = "x-pagination-per-page";
export const PAGINATION_HEADERS = [PAGINATION_TOTAL_COUNT, PAGINATION_PAGE_COUNT, PAGINATION_CURRENT_PAGE, PAGINATION_PER_PAGE];

export const statusIcons: any = {
  confirmed: confirmedIcon,
  construction: constructionIcon,
  possible: possibleIcon,
  confirmedWhite: confirmedIconWhite,
  constructionWhite: constructionIconWhite,
  possibleWhite: possibleIconWhite,
};

export const typeColor: any = {
  "to-follow": "#30D158",
  "to-not-follow": "#800E21",
  "to-follow-not-connectable": "#FF8363",
  "toDefine": "#919191",
};

export const statusCode: any = {
  confirmed: "confirmed",
  possible: "possible",
  construction: "construction",
  toDefine: "toDefine",
};

export const potentialProjectStatus: any = {
  validated: "VALIDATED",
  rejected: "REJECTED",
  unknown: "UNKNOWN",
  alreadyExist: "ALREADY_EXIST",
};

export const defaultCenter = { lat: mapConfig.CENTER_LAT.toString(), lng: mapConfig.CENTER_LNG.toString() };
export const defaultZoom = 11;
export const PROJECT_OUTPUT_CLASS = "PROJECT";
export const LABELS = [
  "PROJECT_NAME",
  "LOCATION",
  "SURFACE",
  "DATE_PREV",
  "DATE_PERMIS",
];
