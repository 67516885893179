import instanceRequest, { Request } from "../../main/services/Request";
import { potentialProjectStatus, PROJECT_OUTPUT_CLASS } from "../store/constants";

class RobotService {
    private axiosService: Request;
    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }
    public getPotentailProjects(contractId: string, sortField: string | null = null, sortDirection: string = "asc") {
        const sortQueryParam = this.formatSort(sortField, sortDirection)
        const url = `/api/${contractId}/potential-projects?status=${potentialProjectStatus.unknown}&${sortQueryParam}`;
        return this.axiosService.get(url, false, false, true).then((response) => {
            return response.data.map((item: any) => {
                const element = item;
                const projectInfo = item.classes.filter((docClass: any) => {
                    return docClass.name === PROJECT_OUTPUT_CLASS;
                });
                element.probability = projectInfo === null || projectInfo === undefined || projectInfo.length === 0 ? "?" : Math.round(projectInfo[0].probabilty * 100) + "%";
                return element;
            });
        });
    }
    private formatSort(sortField: string | null, sortDirection: string = "asc"): string {
        if (sortField === null) {
            return ""
        }
        return `sortFields[]=${encodeURI(`${sortField}:${sortDirection}`)}`;
    }
    public updateProjectStatus(contractId: string, projectId: string, status: string) {
        const url = `/api/${contractId}/potential-project/${projectId}`;
        return this.axiosService.patch(url, { status }, false).then((response) => {
            return response;
        });
    }
    public getDataSources(contractId: string) {
        const url = `/api/${contractId}/data-sources`;
        return this.axiosService.get(url, false, false, true);
    }
    public createDataSource(contractId: string, label: string, link: string, level: number) {
        const url = `/api/${contractId}/data-source`;
        const body = {
            label,
            url: link,
            crawlLevel: level,
        };
        return this.axiosService.post(url, body, false);
    }

    public deleteDataSource(contractId: string, urlHash: string) {
        const url = `/api/${contractId}/data-source/${urlHash}`;

        return this.axiosService.delete(url);
    }
}
export default new RobotService(instanceRequest);
