import { SzIcon } from "@suezenv/react-theme-components";
import React from "react";
import * as Yup from "yup";
import {areaRegex} from "../../store/constants";

export default {
  name: {
    schema: Yup.string().required("required_validation"),
    placeholder: "forms:zac.name",
    label: "forms:zac.name",
    name: "name",
    type: "text",
    required: true,
  },
  area: {
    schema: Yup.string().matches(areaRegex, "zac.area_error"),
    placeholder: "forms:zac.area",
    label: "forms:zac.area",
    name: "area",
    type: "text",
    required: false,
  },
  contact: {
    schema: Yup.string(),
    placeholder: "forms:zac.contact",
    label: "forms:zac.contact",
    name: "contact",
    type: "text",
    required: false,
    icon: <SzIcon icon="search" variant="line" />,
  },
  autocompleteContact: {
    schema: Yup.string(),
    placeholder: "forms:zac.contact",
    label: "forms:zac.contact",
    name: "autocompleteContact",
    type: "text",
    required: false,
    icon: <SzIcon icon="search" variant="line" />,
  },
};
