
import React from "react";
import {useHistory} from "react-router";
import "./index.scss";
import CreateZacManager from "../../../projectManagement/manager/CreateZacManager";

const CreateZac = () => {
    const history = useHistory();
    return (
        <div className="zac-container row p-0 m-0">
            <CreateZacManager history={history}></CreateZacManager>
        </div>
    );
};

export default CreateZac;
