import {SzButton, SzTypographie} from "@suezenv/react-theme-components";
import React from "react";
import {useTranslation} from "react-i18next";

const ViewContact = (props: any) => {
    const {t} = useTranslation();
    const {contact, closeHandle} = props;
    const {
        type,
        civility,
        firstName,
        lastName,
        socialReason,
        siret,
        address,
        homePhone,
        mobilePhone,
        email,
        isRealPerson,
    } = contact;
    const renderAttribute = (label: string, value: any, isNotText = false) => {
        return (
          <div className="text-center pb-2 col row">
              <div className="col-6 text-right ">
                  <label className="font-weight-bold">
                      {t(`details:contact.${label}`)}
                  </label>
              </div>
              <div className="col-6 text-left">
                  {!isNotText ?
                    <SzTypographie variant="text">
                        {value ? value : "-"}
                    </SzTypographie>
                    : value ? value : "-"
                  }
              </div>
          </div>);
    };

    return (
      <>
          <div className="view-contact">
              <div className="pt-4">
                  {renderAttribute("type", t(`details:contact.${type.code}`))}
                  {!isRealPerson && renderAttribute("social_reason", socialReason)}
                  {isRealPerson && renderAttribute("civility", civility === "0" ? t("forms:contact.civility_mme") : t("forms:contact.civility_m"))}
                  {renderAttribute("last_name", lastName)}
                  {renderAttribute("first_name", firstName)}
                  {!isRealPerson && renderAttribute("siret", siret)}
                  {renderAttribute("address", address)}
                  {renderAttribute("home_phone", homePhone)}
                  {renderAttribute("mobile_phone", mobilePhone)}
                  {renderAttribute("email", email)}
              </div>
              <div className="row m-0 form-actions">
                  <div className="offset-4 col-4">
                      <SzButton variant="secondary" onClick={() => closeHandle()} className="contact">
                          {t("forms:contact.cancel")}
                      </SzButton>
                  </div>
              </div>
          </div>
      </>
    );

};

export default ViewContact;
