
import React from "react";

interface IMapComponent {
    addressAutoComplete: any;
    addressAutoCompleteClickHandle: (
        lat: string,
        lng: string,
        street: string,
        houseNumber: string,
        postcode: string,
        city: string,
        address: string,
    ) => void;
}
const AddressAutoCompleteItemsComponent = (props: IMapComponent) => {
    const { addressAutoComplete, addressAutoCompleteClickHandle } = props;
    return (
        <>
            <div className="position-absolute list-group overflow-auto address-auto-complete">
                {addressAutoComplete.items.map((item: any, index: any) => {
                    const { address, lat, lng, street, houseNumber, postcode, city } = item;
                    return address ? (
                        <button
                            key={index}
                            type="button"
                            className="list-group-item list-group-item-action"
                            onClick={() => { addressAutoCompleteClickHandle(lat, lng, street, houseNumber, postcode, city, address); }}
                        >
                            {address}
                        </button>
                    ) : null;
                })}
            </div>

        </>
    );

};

export default AddressAutoCompleteItemsComponent;
