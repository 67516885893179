import instanceRequest, {Request} from "../../main/services/Request";

class ThemeService {

    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getTheme() {
        //Fixme: call api get theme name
      return "EDTM";
    }
}

export default new ThemeService(instanceRequest);
