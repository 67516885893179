import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { SzButton, SzIcon } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import confirmedIcon from "../../assets/img/icons/legende_confirmed.svg";
import constructionIcon from "../../assets/img/icons/legende_construction.svg";
import possibleIcon from "../../assets/img/icons/legende_possible.svg";
import ProjectService from "../../projectManagement/services/ProjectService";
import { projectAction } from "../../projectManagement/store/actions";
import "../styles/sideLayer.scss";
import { getContractId } from "../utils";
import Switch from "./Utils/switch";

interface ISideLayer {
  allowCreatProject: () => boolean;
  filters: any;
  filterStatus: (code: string) => void;
  filterType: (code: string) => void;
  setProjectsMap: any;
  setProjectsList: any;
  user: any;
  search: string;
  displaySubscriptionProjects:boolean
  setListSubscriptionProject:(value:boolean)=>void
  filterSubscription:(value:boolean)=>void
}

const SideLayer = (props: ISideLayer) => {
  const { t } = useTranslation();
  const { allowCreatProject, filters, filterStatus, filterType, setProjectsList, setProjectsMap, user, search, displaySubscriptionProjects, setListSubscriptionProject, filterSubscription } = props;
  const contractId = getContractId(user);

  const filterStatusHandle = (code: string) => {
    const filtersParam = { ...filters, status: { ...filters.status, [code]: !filters.status[code] } };
    ProjectService.getAllProjects(contractId, filtersParam, search).then((response) => {
      setProjectsMap(response);
    });
    ProjectService.getProjects(contractId, filtersParam, search).then((response) => {
      setProjectsList(response);
      filterStatus(code);
    });
  };
  const filterTypeHandle = (code: string) => {
    const filtersParam = { ...filters, type: { ...filters.type, [code]: !filters.type[code] } };
    ProjectService.getAllProjects(contractId, filtersParam, search).then((response) => {
      setProjectsMap(response);
    });
    ProjectService.getProjects(contractId, filtersParam, search).then((response) => {
      setProjectsList(response);
      filterType(code);
    });
  };

  const filterSubscriptionHandle = (value:boolean) => {
    const filtersParam = { ...filters, showSubscriptionProjects:value };
    ProjectService.getAllProjects(contractId, filtersParam, search).then((response) => {
      setProjectsMap(response);
    });
    ProjectService.getProjects(contractId, filtersParam, search).then((response) => {
      setProjectsList(response);
      filterSubscription(value);
    });
  };
  const history = useHistory();
  const createProjectHandle = () => {
    history.push("new/project");
  };

  const switchChangeHandle = () => {
    setListSubscriptionProject(!displaySubscriptionProjects);
    filterSubscriptionHandle(!displaySubscriptionProjects)
  };

  const onIconClickHandle = (value: boolean) => {
    setListSubscriptionProject(value);
    filterSubscriptionHandle(value);
  };
  return (
    <div className="side-layer row">
      <div className="col-12 side-layer-form p-0 px-lg-2">
        <h6 className="list-state-title px-1">{t("project.state-project-title")}</h6>
        <ul className="list-state first-list">
          <li title={t("project.possible")} className={(!filters.status.possible) ? "disabled mx-0" : "mx-0"}
              onClick={() => filterStatusHandle("possible")}>
            <div className="row m-auto">
              <div className="col-2 px-0">
                <img className="img-fluid" alt={t("project.possible")} src={possibleIcon} />
              </div>
              <div className="col-10 title p-0 my-auto">
                {t("project.possible")}</div>
            </div>
          </li>
          <li title={t("project.confirmed")} className={(!filters.status.confirmed) ? "disabled mx-0" : "mx-0"}
            onClick={() => filterStatusHandle("confirmed")}>
            <div className="row m-auto ">
              <div className="col-2 px-0">
                <img className="img-fluid" alt={t("project.confirmed")} src={confirmedIcon} />
              </div>
              <div className="col-10 title p-0 my-auto">
                {t("project.confirmed")}
              </div>
            </div>
          </li>
          <li title={t("project.construction")} className={(!filters.status.construction) ? "disabled mx-0" : "mx-0"}
            onClick={() => filterStatusHandle("construction")}>
            <div className="row m-auto">
              <div className="col-2 px-0">
                <img className="img-fluid" alt={t("project.construction")} src={constructionIcon} />
              </div>
              <div className="col-10 title p-0 my-auto">

                {t("project.construction")}</div>
            </div>
          </li>
        </ul>
        <div>
          <h6 className="list-state-title px-1">{t("project.tracking-project-title")}</h6>
          <ul className="list-state">
            <li title={t("project.to-follow")} className={(!filters.type["to-follow"]) ? "disabled mx-0" : "mx-0"}
              onClick={() => filterTypeHandle("to-follow")}>
              <div className="row m-auto">
                <div className="col-auto px-2">
                  <span className="to-follow state-span" />
                </div>
                <div className="col-9 title p-0 my-auto">
                  {t("project.to-follow")}</div>
              </div>
            </li>
            <li title={t("project.to-not-follow")} className={(!filters.type["to-not-follow"]) ? "disabled mx-0" : "mx-0"}
              onClick={() => filterTypeHandle("to-not-follow")}>
              <div className="row m-auto">
                <div className="col-auto px-2">
                  <span className="to-not-follow state-span" />
                </div>
                <div className="col-9 title p-0 my-auto">
                  {t("project.to-not-follow")}</div>
              </div>
            </li>
            <li title={t("project.to-follow-not-connectable")}
              className={(!filters.type["to-follow-not-connectable"]) ? "disabled mx-0" : "mx-0"}
              onClick={() => filterTypeHandle("to-follow-not-connectable")}>
              <div className="row m-auto">
                <div className="col-auto px-2">
                  <span className="to-follow-not-connectable state-span" />
                </div>
                <div className="col-9 title p-0 my-auto">
                  {t("project.to-follow-not-connectable")}</div>
              </div>
            </li>
            <li title={t("project.toDefine")}
                className={(!filters.type["toDefine"]) ? "disabled mx-0" : "mx-0"}
                onClick={() => filterTypeHandle("toDefine")}>
              <div className="row m-auto">
                <div className="col-auto px-2">
                  <span className="toDefine state-span" />
                </div>
                <div className="col-9 title p-0 my-auto">
                  {t("project.toDefine")}</div>
              </div>
            </li>
          </ul>
        </div>
        <h6 className="list-state-title px-1">{t("project.subscription-title")}</h6>
        <div className="d-flex ml-5">
          <Switch onChangeHandle={switchChangeHandle}
                  firstIconClass="alarm-slash"
                  firstIcon="alarm-bell"
                  secondIcon="alarm-bell"
                  firstIconTitle={t('project.all_project_filter_title')}
                  secondIconTitle={t('project.subscription_project_filter_title')}
                  onFirstIconClickHandle={()=>onIconClickHandle(false)}
                  onSecondIconClickHandle={()=>onIconClickHandle(true)}
                  checked={displaySubscriptionProjects}/>
        </div>
        <div className="create-project-side row mx-0  mt-3">
          {allowCreatProject && (
              <SzButton className="add-project-btn w-100" variant="secondary" onClick={createProjectHandle}>
                <SzIcon icon="add-circle" variant="line"></SzIcon>
                <span>{t("project.create-project-title")}</span>
              </SzButton>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    filters: state.project.filters,
    search: state.project.searchText,
    user: state.user,
    displaySubscriptionProjects: state.project.displaySubscriptionProjects,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(projectAction, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(SideLayer);
