import { Constants } from "../constants";

const initialState: any = {
  listZac: {
    pagination: [],
    data: [],
  },
  zacDetail:null
};

const zacReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.SET_ZAC_LIST:
      return {
        ...state,
        listZac: action.payload ,
      };
    case Constants.SET_ZAC_DETAIL:
      return {
        ...state,
        zacDetail: action.payload ,
      };
    default:
      return state;
  }
};

export default zacReducer;
