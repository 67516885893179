import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {formatDateWithFormat, getContractId} from "../../main/utils";
import {mapAction} from "../../map/store/actions";
import ProjectHeader from "../components/project/ProjectHeader";
import ProjectForm from "../forms/ProjectForm";
import ProjectService from "../services/ProjectService";
import {projectAction, projectReferentialAction} from "../store/actions";
import AbstractProjectManager from "./AbstractProjectManager";

class MergeProjectManager extends AbstractProjectManager {
    public state: any = {...this.state, project1: null, project2: null};

    public componentDidMount(): void {
        this.init();
    }

    public componentDidUpdate(prevProps: any): void {
        super.componentDidUpdate(prevProps);
        if ((!this.state.contractId)) {
            this.init();
        }
    }

    private init() {
        const {idProject1, idProject2, user} = this.props;
        const contractId = getContractId(user);
        if (contractId) {

            ProjectService.getProject(contractId, idProject1).then((response) => {
                const project = response.data;
                this.setState({project1: {...project}});
            });

            ProjectService.getProject(contractId, idProject2).then((response) => {
                const project = response.data;
                this.setState({project2: {...project}});
            });
        }
    }

    private getMergePreposition() {
        const prepositions: any = [];
        const {project1, project2} = this.state;

        prepositions.push(this.formatProposition("name", [project1.name, project2.name]));
        prepositions.push(this.formatProposition("pcNumber", [project1.pcNumber, project2.pcNumber]));
        prepositions.push(this.formatProposition("url", [project1.url, project2.url]));
        prepositions.push(this.formatProposition("address", [project1.address, project2.address]));
        prepositions.push(this.formatProposition("hold", [project1.hold, project2.hold]));
        prepositions.push(this.formatProposition("deliveryDate", [formatDateWithFormat(project1.deliveryDate), formatDateWithFormat(project2.deliveryDate)], false, true));
        prepositions.push(this.formatProposition("pcDate", [formatDateWithFormat(project1.pcDate), formatDateWithFormat(project2.pcDate)], false, true));
        prepositions.push(this.formatProposition("dateSetUpPermitZac", [formatDateWithFormat(project1.dateSetUpPermitZac), formatDateWithFormat(project2.dateSetUpPermitZac)], false, true));
        prepositions.push(this.formatProposition("status", [project1.status, project2.status], true));
        prepositions.push(this.formatProposition("type", [project1.type, project2.type], true));
        prepositions.push(this.formatProposition("isZac", [project1.zac ? "true" : "false", project2.zac ? "true" : "false"], true));
        prepositions.push(this.formatProposition("zac", [project1.zac, project2.zac]));
        prepositions.push(this.formatProposition("comment", [project1.comment, project2.comment]));
        prepositions.push(this.formatProposition("mainContact", [project1.mainContact, project2.mainContact]));
        prepositions.push(this.formatProposition("progress", [project1.progress ? project1.progress : null, project2.progress ? project2.progress : null], true));
        prepositions.push(this.formatAttachmentProposition("stoppedFile", [project1, project2]));
        prepositions.push(this.formatAttachmentProposition("authorizationFile", [project1, project2]));
        prepositions.push(this.formatAttachmentProposition("photoFile", [project1, project2]));
        prepositions.push(this.formatMultiAttachmentProposition([project1, project2]));
        return prepositions;
    }

    private formatProposition(tag: string, keywords: any, toTranslate = false, isDate = false) {
        const preposition: any = {tag, keyword: []};

        keywords.forEach((keyword: any) => {
            if (keyword) {
                preposition.keyword.push({
                    text: keyword.id && toTranslate ? keyword.id : keyword,
                    translate: toTranslate ? this.props.t(`forms:project.${keyword.code || keyword}`) : null,
                    isDate,
                });
            }

        });
        return preposition;
    }

    private formatAttachmentProposition(tag: string, projects: any) {
        const preposition: any = {tag, files: []};

        projects.forEach((project: any) => {
            if (project[tag]) {
                preposition.files.push({
                    file: project[tag], projectName: project.name,
                });
            }

        });
        return preposition;

    }

    private formatMultiAttachmentProposition(projects: any) {
        const preposition: any = {tag: "otherAttachments", files: []};

        projects.forEach((project: any) => {
            if (project.otherAttachments) {
                project.otherAttachments.forEach((file: any) => {
                    preposition.files.push({
                        file, projectName: project.name,
                    });
                });
            }

        });
        return preposition;
    }

    public render(): any {
        const {t, projectReferential, setProjectsMap, setProjectsList} = this.props;
        const {project1, project2} = this.state;
        return (
          <>
              {this.state.contractId && (
                <>
                    <div className="project-form p-0 m-0  col-4">
                        <ProjectHeader title={t("forms:project.merge_project_title")}/>
                        {project1 && project2 && (
                          <ProjectForm
                            setDuplicatedProjects={this.setDuplicatedProjects.bind(this)}
                            duplicatedProjects={this.props.duplicatedProjects}
                            contractId={this.state.contractId}
                            projectReferential={projectReferential}
                            setProjectsMap={setProjectsMap}
                            setProjectsList={setProjectsList}
                            projectToMerge1={project1}
                            projectToMerge2={project2}
                            extractedLabels={this.getMergePreposition()}
                            synchroPin={this.state.synchroPin}
                            changeSynchroPin={this.changeSynchroPin.bind(this)}
                            showPin={this.state.showPin}
                            changeShowPin={this.changeShowPin.bind(this)}
                            pinProjectLocation={this.state.pinProjectLocation}
                            setMapCenterHandle={this.setMapCenterHandle.bind(this)}
                          ></ProjectForm>)
                        }
                    </div>
                    {this.mapListRender()}
                </>
              )}
          </>
        );
    }
}

const mapDispatchToProps = {
    setProjectTypes: projectReferentialAction.setProjectTypes,
    setProjectStatus: projectReferentialAction.setProjectStatus,
    setProjectProgress: projectReferentialAction.setProjectProgress,
    setContactTypes: projectReferentialAction.setContactTypes,
    setProjectsMap: projectAction.setProjectsMap,
    setProjectsList: projectAction.setProjectsList,
    setDuplicatedProjects: projectAction.duplicatedProjects,
    setMapInfo: mapAction.setMapInfo,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        projectReferential: state.projectReferential,
        displayMap: state.project.displayMap,
        duplicatedProjects: state.project.duplicatedProjects,
    };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(MergeProjectManager));
