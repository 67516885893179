import { SzButton, SzIcon, SzModal, SzTable } from "@suezenv/react-theme-components";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {getContractId} from "../../../main/utils";
import RobotHeader from "../../components/Robot/RobotHeader";
import robotService from "../../services/RobotService";
import { potentialProjectAction } from "../../store/actions";

class DataSourcesListManager extends React.Component<any> {
    public state = {
        sort: { field: "title", direction: "asc" },
        columns: [],
        modalData: { showModal: false, urlHash: "" },
    };
    public componentDidMount() {
        this.initTable();
    }

    public componentDidUpdate(prevProps: any) {
        if (this.props.user.id !== prevProps.user.id) {
            if (this.props.setDataSourcesList.length === 0) {
                this.getData();
            }
        }
    }

    public initTable() {
        if (this.props.setDataSourcesList.length === 0) {
            this.getData();
        }
        this.setColumn(this.state.sort.field, this.state.sort.direction);
    }

    public getData() {
        const contractId: string = getContractId(this.props.user);
        if (contractId) {
            robotService.getDataSources(contractId).then((response: any) => {
                this.props.setDataSourcesList(response.data);
            });
        }
    }

    public cellFormatter = (value: any, row: any) => {
        return (
            <>
                <div className="cell-clickable"
                    style={{
                        textAlign: "center",
                        cursor: "pointer",
                        lineHeight: "normal",
                        fontSize: "22px",
                        color: "#A3D049",
                    }}
                    onClick={() => this.setState({ modalData: { showModal: true, urlHash: row.urlHash } })}>
                    <SzIcon className="text-primary" icon="bin-1" variant="bold"></SzIcon>
                </div>

            </>
        );
    }

    public deleteDataSource = (urlHash: string) => {
        const contractId: string = getContractId(this.props.user);
        if (urlHash) {
            this.setState({ modalData: { showModal: false, urlHash: "" } });
            robotService.deleteDataSource(contractId, urlHash).then(() => {
                this.getData();
            });
        }
    }

    public deleteModal = () => {
        const { t } = this.props;
        return <SzModal
            title={t("setting.delete-data-source.title")}
            show={this.state.modalData.showModal}
            handleClose={() => this.setState({ modalData: { showModal: false, urlHash: "" } })}
            size={"xl"}
        >
            <h6>{t("setting.delete-data-source.message")}</h6>
            <div className="row form-actions">
                <div className="col-4 offset-2">
                    <SzButton variant="secondary"
                        onClick={() => this.setState({ modalData: { showModal: false, urlHash: "" } })}>{t("setting.delete-data-source.cancel")}</SzButton>
                </div>
                <div className="col-4">
                    <SzButton onClick={() => {
                        this.deleteDataSource(this.state.modalData.urlHash);
                    }}>
                        {t("setting.delete-data-source.delete")}
                    </SzButton>
                </div>
            </div>
        </SzModal>;
    }
    public columnJson(classes: string, dataField: string, text: string, sortDataField: string,
                      sortDirection: string, canSort = true, cellFormatter: any = null) {
        const { t } = this.props;
        return {
            classes,
            dataField,
            text: t(text),
            formatter: cellFormatter,
            sort: canSort,
            headerEvents: {
                onClick: canSort ? this.sortByColumn.bind(this) : () => {
                },
            },
        };
    }

    public sortByColumn(e: any, column: any) {
        const { direction } = this.state.sort;
        const sortDirection = ((direction && direction === "desc") ? "asc" : "desc");
        this.setState({ sort: { direction: sortDirection, field: column.dataField } });
        this.setColumn(column.dataField, sortDirection);

        return true;
    }

    public setColumn(dataField = "", sort = "") {
        const { t } = this.props;
        const sortDirection = sort === "desc" ? "sz-icon-line arrow-down-1" : "sz-icon-line arrow-up-1";
        this.setState({
            columns: [
                this.columnJson("label", "label", t("lists:setting.title"), dataField, sortDirection, false),
                this.columnJson("url", "url", t("lists:setting.link"), dataField, sortDirection, false),
                this.columnJson("crawlLevel", "crawlLevel", t("lists:setting.level"), dataField, sortDirection),
                this.columnJson("action", "action", "", dataField, sortDirection, false, this.cellFormatter),
            ],
        });
    }

    public render() {
        const { t } = this.props;
        return (
            <>
                {this.deleteModal()}
                <RobotHeader title={t("setting.title")}></RobotHeader>
                {
                    this.state.columns.length > 0 && <div className="potential-project-table bg-white row m-2">
                        <SzTable
                            columns={this.state.columns}
                            data={this.props.listDataSources}
                            keyField="id"
                        />
                    </div>
                }
            </>
        );
    }
}
const mapDispatchToProps = {
    setDataSourcesList: potentialProjectAction.setDataSourcesList,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        listDataSources: state.potentialProject.listDataSources,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(DataSourcesListManager));
