import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";
import {
    SzButton, SzIcon,
    SzInput,
} from "@suezenv/react-theme-components";
import { getContractId } from "../../../main/utils";
import ProjectService from "../../services/ProjectService";
import { projectAction } from "../../store/actions";
import "./searchProjectComponent.scss";

const SearchProjectComponent = (props: any) => {

    const ENTER_KEY = 13;
    const {search, setSearchText, filters, user, setProjectsMap, setProjectsList} = props;
    const [state, setState] = useState({search});
    const { t } = useTranslation();
    const changeHandle = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    /**
     * Lancement de la recherche en appuyant sur entrée.
     *
     * @param event
     */
    const onKeyUp = (event: any) => {
        switch (event.keyCode) {
            case ENTER_KEY: {
                searchHandle();
                break;
            }
            default: {
                break;
            }
        }
    };

    const searchHandle = () => {
        const contractId = getContractId(user);
        ProjectService.getAllProjects(contractId, filters, state.search).then((response) => {
            setProjectsMap(response);
        });
        ProjectService.getProjects(contractId, filters, state.search).then((response) => {
            setProjectsList(response);
            setSearchText(state.search);
        });
    };
    const resetSearchHandle = () => {
        const contractId = getContractId(user);
        setState({...state, search: ""});
        ProjectService.getAllProjects(contractId, filters, "").then((response) => {
            setProjectsMap(response);
        });
        ProjectService.getProjects(contractId, filters, "").then((response) => {
            setProjectsList(response);
            setSearchText("");
        });
    };
    return (
        <>
            <div className="row m-0 p-0 search-box form-actions w-100">
                <div className="col-4 p-2">
                    <SzInput
                        className="mb-0"
                        onChange={changeHandle}
                        onKeyUp={onKeyUp}
                        icon="search"
                        name="search"
                        type="text"
                        value={state.search}
                        placeholder=""
                    />
                    {state.search &&
                    <span
                        className="reset-search sz-form-group__input-group-icon position-absolute d-flex align-items-center justify-content-center cursor-pointer"
                        title={t("reset_search")}>
                        <SzIcon icon="remove" onClick={resetSearchHandle} variant="line"/>
                        </span>
                    }
                </div>
                <div className="pt-2 p-2">
                <SzButton
                    className="mb-0"
                    onClick={searchHandle}
                ><SzIcon icon="search" variant="line" /></SzButton>
                </div>
            </div>
        </>
    );

};

const mapStateToProps = (state: any) => {
    return {
        search: state.project.searchText,
        filters: state.project.filters,
        user: state.user,

    };
};

const mapDispatchToProps = {
    setSearchText: projectAction.setSearchText,
    setProjectsMap: projectAction.setProjectsMap,
    setProjectsList: projectAction.setProjectsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchProjectComponent);
