import {Constants} from "../constants";

export default {
    setAddressAutocomplete(payload: any) {
        return {
            type: Constants.SET_AUTO_COMPLETE_ADDRESS,
            payload,
        };
    },
    setAddressContactAutocomplete(payload: any) {
        return {
            type: Constants.SET_AUTO_COMPLETE_ADDRESS_CONTACT,
            payload,
        };
    },
    setContactAutocomplete(payload: any) {
        return {
            type: Constants.SET_AUTO_COMPLETE_CONTACT,
            payload,
        };
    },
    setZacAutocomplete(payload: any) {
        return {
            type: Constants.SET_AUTO_COMPLETE_ZAC,
            payload,
        };

    },
};
