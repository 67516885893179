import { Constants } from "../constants";

const initialState: any = {
  list: {
    confirmed: [],
    possible: [],
    construction: [],
    toDefine:[]
  },
  stats: {
    total: 0,
    status: {
      confirmed: 0,
      possible: 0,
      construction: 0,
      toDefine:0
    },
  },
};

const notificationReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.SET_LIST_NOTIFICATIONS:
      return { ...state, list: {...state.list , [action.statusCode]: action.payload}};
    case Constants.SET_NOTIFICATIONS_STATS:
      return { ...state, stats: action.payload };
    default:
      return state;
  }
};

export default notificationReducer;
