import instanceRequest, {Request} from "../../main/services/Request";

class ContractConfigurationService {

    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }


    public getContractConfiguration(contractId: any) {
        const url = `/api/contract/${contractId}/configuration`;
        return this.axiosService.get(url).then((response: any) => {
            return response.data;
        });
    }
}

export default new ContractConfigurationService(instanceRequest);
