import {SzBox} from "@suezenv/react-theme-components";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getContractId} from "../../main/utils";
import Map from "../../map/components/Map";
import {servicesCodes} from "../../user/store/constants";
import ZacForm from "../forms/ZacForm";
import ReferentialService from "../services/ReferentialService";
import ZacService from "../services/ZacService";
import {projectReferentialAction} from "../store/actions";
import zacAction from "../store/actions/zacAction";

class EditZacManager extends React.Component<any> {

    public state = {
        geoJson: null,
    };

    public setReferential() {
        const contractId = getContractId(this.props.user);
        this.setState({contractId});
        // if dont have service project management
        if ("" === contractId) {
            return this.props.history.push("/");
        }

        this.initProjectContactTypes(contractId);
    }

    public componentDidUpdate(prevProps: any): void {
        const {user, zac} = this.props;

        const contractId = getContractId(user);
        if (!contractId) {
            this.setReferential();
        }

        if (!zac) {
            this.init();
        }
    }

    public componentDidMount(): void {
        if (this.props.user.services[servicesCodes.projectManagement] && this.props.user.services[servicesCodes.projectManagement].length) {
            this.setReferential();
            this.init();
        }
    }

    public initProjectContactTypes(contractId: string) {
        if (this.props.projectReferential.contactTypes.length === 0) {
            ReferentialService.getContactTypes(contractId).then((data) => {
                this.props.setContactTypes(data);
            });
        }
    }

    private init() {
        const {idZac, user, setZacDetail} = this.props;
        const contractId = getContractId(user);
        if (contractId) {
            ZacService.getZac(contractId, idZac).then((response) => {
                setZacDetail(response.data);
                if (response.data) {
                    this.setState({geoJson: response.data.geoJson});
                }
            });
        }
    }

    public onChange(geojson: any) {
        if (geojson) {
            this.setState({geoJson: geojson.features});
        }
    }

    public render(): any {
        const {t, user, zac} = this.props;
        const contractId = getContractId(user);

        return (
          <>
              {contractId && (
                <>
                    <div className="zac-form  col-4">

                        <div className="m-2 w-100 new-zac-title">
                            <div
                              className="sz-card-collapse__header sz-left-border position-relative align-items-center m-0 row">
                                <div className="w-100 col-md-6 col-sm-12">
                                    <SzBox className="justify-content-around pb-0">
                                        <label className="mb-0 font-weight-bold">{t("zac.edit_zac_title")}</label>
                                    </SzBox>
                                </div>
                            </div>
                        </div>
                        <ZacForm zacDetail={zac} contractId={contractId} geoJson={this.state.geoJson} redirect={true} saveAction={() => {}}/>
                    </div>
                    <div className="col-8 no-padding map">
                        <Map eventPinHandle={() => {
                        }}
                             isEditEnabled={true}
                             isViewProject={false}
                             onChangeMap={this.onChange.bind(this)}
                             contractConfiguration={[]}
                             showPin={false}
                             synchroPin={false}
                             setIsAutoCompleteAction={() => {
                             }}
                             isAutoCompleteAction={false}
                             isZac={true}
                             zacGeoJson={this.state.geoJson}
                        />
                    </div>

                </>
              )}
          </>
        );
    }
}

const mapDispatchToProps = {
    setContactTypes: projectReferentialAction.setContactTypes,
    setZacDetail: zacAction.setZacDetail,
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        projectReferential: state.projectReferential,
        zac: state.zac.zacDetail,
    };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(EditZacManager));
