import { Constants } from "../constants";

const initialState: any = {
  features: [],
  center: null,
  zoom: null,
  isAutoZoomActivated: true,
};

const mapReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case Constants.SET_MAP_COORDINATE:
      return {
        ...state,
        features: action.payload.data || [],
      };
    case Constants.SET_MAP_INFO:
      return {
        ...state, ...action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
