import { SzTypographie} from "@suezenv/react-theme-components";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorConnection = () => {
    const {t} = useTranslation();
    return (
        <div className="h-75 row  align-items-center justify-content-center">
            <div className="col-auto text-center">
            <SzTypographie variant="huge" weight="bold"  tag="div">
                {t("error_connection_title")}
            </SzTypographie>
            <SzTypographie variant="large" weight="bold"   tag="div">
                {t("error_connection_description")}
            </SzTypographie>
            </div>
        </div >
    );
};

export default ErrorConnection;
