import { Constants } from "../constants";

const initialState: any = {
  contact: [],
  zac: [],
  address: [],
  addressContact: [],
};

const autocompleteReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.SET_AUTO_COMPLETE_ADDRESS:
      return { ...state, address: action.payload};
    case Constants.SET_AUTO_COMPLETE_ADDRESS_CONTACT:
      return { ...state, addressContact: action.payload};
    case Constants.SET_AUTO_COMPLETE_CONTACT:
      return { ...state, contact: action.payload};
    case Constants.SET_AUTO_COMPLETE_ZAC:
      return { ...state, zac: action.payload};
    default:
      return state;
  }
};

export default autocompleteReducer;
